<script setup lang="ts">
import { colorPalette as colors } from '@/utils/enums'
import { ref } from 'vue'
import { _ElMessage } from '@/utils/element-plus-wrapper'

// emits
const emit = defineEmits<{
  (e: 'send', text: string, files: File[], notifyCaseItemAuthor: boolean): void
}>()

// props
const props = defineProps<{
  enableFilesOption: boolean
  enableNotifyOption: boolean
}>()

const isSendingMessage = ref(false)
function makeLoading(b: boolean) {
  isSendingMessage.value = b
}
defineExpose({ clearInput, appendText, clearFiles, makeLoading })

const chatInputValue = ref('')
const notifyCaseItemAuthor = ref(false)
const messageInputEl = ref<HTMLInputElement | null>(null)

// File attachment handling
const attachedFiles = ref<File[]>([])
const fileInput = ref<HTMLInputElement | null>(null)
// pptx, pdf, docx, xlsxのみ受け入れる
const acceptedFileExtensions =
  'application/vnd.openxmlformats-officedocument.presentationml.presentation,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
function handleFileDrop(event: DragEvent) {
  event.preventDefault()
  if (!props.enableFilesOption) return
  if (event.dataTransfer?.files) {
    attachedFiles.value.push(...Array.from(event.dataTransfer.files))
  }
}

function handleFileChange(event: Event) {
  if (!props.enableFilesOption) return
  const input = event.target as HTMLInputElement
  if (input.files) {
    attachedFiles.value.push(...Array.from(input.files))
    input.value = ''
  }
}

function triggerFileInput() {
  fileInput.value?.click()
}

function removeFile(index: number) {
  attachedFiles.value.splice(index, 1)
  if (fileInput.value) {
    fileInput.value.value = ''
  }
}

async function handleSend() {
  if (!chatInputValue.value) {
    _ElMessage({
      message: 'メッセージを入力してください',
      type: 'error'
    })
    return
  }
  emit('send', chatInputValue.value, attachedFiles.value, notifyCaseItemAuthor.value)
}

function clearInput() {
  chatInputValue.value = ''
  resizeWithDebounce()
}

function clearFiles() {
  attachedFiles.value = []
}

function appendText(text: string) {
  chatInputValue.value += chatInputValue.value ? '\n\n' + text : text
  resizeWithDebounce()
}

// textarea resizing
const resizeTimeoutId = ref<number | null>(null)
const TEXTAREA_DEFAULT_HEIGHT = '24px'
function resizeWithDebounce() {
  if (resizeTimeoutId.value) {
    clearTimeout(resizeTimeoutId.value)
    resizeTimeoutId.value = null
  }
  resizeTimeoutId.value = window.setTimeout(() => {
    adjustTextareaHeight()
  }, 300) // debounce time (ms)
}
function adjustTextareaHeight() {
  const textarea = messageInputEl.value
  if (textarea) {
    textarea.style.height = TEXTAREA_DEFAULT_HEIGHT
    const maxHeight = Math.round(window.innerHeight * 0.3) // 30vh
    const scrollHeight = textarea.scrollHeight
    textarea.style.height = scrollHeight > maxHeight ? `${maxHeight}px` : `${scrollHeight}px`
  }
}
</script>

<template>
  <div class="chat-input" ref="chatInput" @drop.prevent="handleFileDrop" @dragover.prevent>
    <div class="input-form">
      <div v-if="attachedFiles.length" class="file-display-section">
        <el-tag
          v-for="(file, index) in attachedFiles"
          :key="index"
          closable
          type="info"
          @close="removeFile(index)"
        >
          {{ file.name }}
        </el-tag>
      </div>
      <div class="input-section">
        <div v-if="enableFilesOption" class="attachment-icon" @click="triggerFileInput">
          <svg
            fill="currentColor"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.1,12.4l-6.2,6.2c-1.7,1.7-4.4,1.7-6,0c-1.7-1.7-1.7-4.4,0-6l8-8c1-0.9,2.5-0.9,3.5,0c1,1,1,2.6,0,3.5l-6.9,6.9c-0.3,0.3-0.8,0.3-1.1,0c0,0,0,0,0,0c-0.3-0.3-0.3-0.8,0-1.1l5.1-5.1c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L8,12.6c-1.1,1.1-1.1,2.8,0,3.9c1.1,1,2.8,1,3.9,0l6.9-6.9c1.8-1.8,1.8-4.6,0-6.4c-1.8-1.8-4.6-1.8-6.4,0l-8,8c-1.2,1.2-1.8,2.8-1.8,4.4c0,3.5,2.8,6.2,6.3,6.2c1.7,0,3.2-0.7,4.4-1.8l6.2-6.2c0.4-0.4,0.4-1,0-1.4S18.5,12,18.1,12.4z"
            />
          </svg>
          <input
            type="file"
            ref="fileInput"
            @change="handleFileChange"
            :accept="acceptedFileExtensions"
            :multiple="true"
            hidden
          />
        </div>
        <textarea
          v-model="chatInputValue"
          class="user-input-textarea"
          ref="messageInputEl"
          placeholder="メッセージを入力する"
          @input="resizeWithDebounce"
        />
        <div class="container">
          <div v-show="enableNotifyOption" class="notify-button">
            <el-checkbox v-model="notifyCaseItemAuthor">メールで通知</el-checkbox>
          </div>
          <el-button link :loading="isSendingMessage" class="send-btn" @click="handleSend()">
            <svg
              v-if="!isSendingMessage"
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="currentColor"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M3.4 20.4l17.45-7.48c.81-.35.81-1.49 0-1.84L3.4 3.6c-.66-.29-1.39.2-1.39.91L2 9.12c0 .5.37.93.87.99L17 12 2.87 13.88c-.5.07-.87.5-.87 1l.01 4.61c0 .71.73 1.2 1.39.91z"
              />
            </svg>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.chat-input {
  box-sizing: border-box;
  min-height: 48px;
}
.input-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: transparent;
  border: 1px solid v-bind('colors.border.base');
  border-radius: 4px;
  box-sizing: border-box;
  gap: 12px;
  padding: 8px 12px;
}
.file-display-section {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 4px;
}
.input-section {
  display: flex;
  align-items: end;
}
.attachment-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: v-bind('colors.text.black');
  margin-right: 8px;
}
.send-btn {
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: v-bind('colors.text.black');
}
.user-input-textarea {
  width: 100%;
  height: 24px;
  resize: none;
  max-height: 30vh;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}
.user-input-textarea:focus-visible {
  outline: none; /* reset user-agent */
}
.container {
  display: flex;
  align-items: center;
}
.notify-button {
  margin-right: 8px;
  height: 24px;
  line-height: 24px;
  .el-checkbox {
    height: 24px;
  }
}
</style>
