<script setup lang="ts">
import CommonHeader from '../components/CommonHeader.vue'
import { colorPalette as colors, CatalogsMenuItems, PageTypes } from '@/utils/enums'
import CategoryList from '@/components/catalogs/CategoryList.vue'
import CategoryDetail from '@/components/catalogs/CategoryDetail.vue'
import SolutionList from '@/components/catalogs/SolutionList.vue'
import SolutionDetail from '@/components/catalogs/SolutionDetail.vue'
import ProductList from '@/components/catalogs/ProductList.vue'
import ProductDetail from '@/components/catalogs/ProductDetail.vue'
import LabelList from '@/components/catalogs/LabelList.vue'
import UsefulMaterialList from '@/components/catalogs/UsefulMaterialList.vue'
import Worksheet from '@/components/catalogs/WorksheetFull.vue'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import { House, ArrowRightBold } from '@element-plus/icons-vue'

interface Props {
  menu?: CatalogsMenuItems
  resourceId?: string
  pageType?: PageTypes
}
const props = withDefaults(defineProps<Props>(), {
  menu: 'categoryList'
})
const router = useRouter()

function getMenuItemClass(name: CatalogsMenuItems): string {
  let str = 'menu-item'
  if (name == props.menu) {
    str += ' current'
  }
  return str
}
const targetComponentName = computed(() => {
  if (props.resourceId) {
    switch (props.menu) {
      case 'categoryList':
        return 'categoryDetail'
      case 'solutionList':
        switch (props.pageType) {
          case 'worksheet':
            return 'worksheet'
          default:
            return 'solutionDetail'
        }
      case 'productList':
        return 'productDetail'
      default:
        return props.menu
    }
  } else {
    return props.menu
  }
})
const breadcrumbData = computed(() => {
  const data: { label: string; name: string; url: string }[] = []
  switch (props.menu) {
    case 'categoryList':
      if (props.resourceId) {
        data.push({
          label: '業種・部門リスト',
          name: 'categoryList',
          url: '/catalogs?menu=categoryList'
        })
        data.push({
          label: '業種・部門詳細',
          name: 'categoryDetail',
          url: '/catalogs?menu=categoryList&resourceId=' + props.resourceId
        })
      } else {
        data.push({
          label: '業種・部門リスト',
          name: 'categoryList',
          url: '/catalogs?menu=categoryList'
        })
      }
      break
    case 'solutionList':
      if (props.resourceId) {
        data.push({
          label: 'ソリューション・オプションリスト',
          name: 'solutionList',
          url: '/catalogs?menu=solutionList'
        })
        data.push({
          label: 'ソリューション詳細',
          name: 'solutionDetail',
          url: '/catalogs?menu=solutionList&resourceId=' + props.resourceId
        })
        if (props.pageType === 'worksheet') {
          data.push({
            label: '比較表管理',
            name: 'worksheet',
            url: '/catalogs?menu=solutionList&pageType=worksheet&resourceId=' + props.resourceId
          })
        }
      } else {
        data.push({
          label: 'ソリューション・オプションリスト',
          name: 'solutionList',
          url: '/catalogs?menu=solutionList'
        })
      }
      break
    case 'productList':
      if (props.resourceId) {
        data.push({
          label: '商材リスト',
          name: 'productList',
          url: '/catalogs?menu=productList'
        })
        data.push({
          label: '商材詳細',
          name: 'productDetail',
          url: '/catalogs?menu=productList&resourceId=' + props.resourceId
        })
      } else {
        data.push({
          label: '商材リスト',
          name: 'productList',
          url: '/catalogs?menu=productList'
        })
      }
      break
    case 'usefulMaterialList':
      data.push({
        label: 'お役立ち資料リスト',
        name: 'usefulMaterialList',
        url: '/catalogs?menu=usefulMaterialList'
      })
      break
    case 'labelList':
      data.push({ label: '比較表項目リスト', name: 'labelList', url: '/catalogs?menu=labelList' })
      break
  }
  return data
})
function transitMenu(name: string) {
  router.push('/catalogs?menu=' + name)
}
function transit(name: string, url: string) {
  if (name !== targetComponentName.value) {
    router.push(url)
  }
}
</script>

<template>
  <el-container>
    <el-header height="48px">
      <CommonHeader current-menu="catalogs" />
    </el-header>
    <el-container>
      <el-aside width="280px">
        <div id="sidebar">
          <div class="local-menu-header">Catalogs Menu</div>
          <div class="menu-wrapper">
            <div :class="getMenuItemClass('categoryList')" @click="transitMenu('categoryList')">
              {{ CatalogsMenuItems['categoryList'] }}
            </div>
          </div>
          <div class="menu-wrapper">
            <div :class="getMenuItemClass('solutionList')" @click="transitMenu('solutionList')">
              {{ CatalogsMenuItems['solutionList'] }}
            </div>
          </div>
          <div class="menu-wrapper">
            <div :class="getMenuItemClass('productList')" @click="transitMenu('productList')">
              {{ CatalogsMenuItems['productList'] }}
            </div>
          </div>
          <div class="menu-wrapper">
            <div
              :class="getMenuItemClass('usefulMaterialList')"
              @click="transitMenu('usefulMaterialList')"
            >
              {{ CatalogsMenuItems['usefulMaterialList'] }}
            </div>
          </div>
          <div class="menu-wrapper">
            <div :class="getMenuItemClass('labelList')" @click="transitMenu('labelList')">
              {{ CatalogsMenuItems['labelList'] }}
            </div>
          </div>
        </div>
      </el-aside>
      <el-main>
        <div class="ix-breadcrumb-navi">
          <div
            class="ix-breadcrumb-navi-item"
            v-for="(navi, i) in breadcrumbData"
            :key="i"
            @click="transit(navi.name, navi.url)"
          >
            <House v-if="i == 0" style="width: 16px; height: 16px; margin-right: 4px" />
            <ArrowRightBold v-else style="width: 16px; height: 16px; margin-right: 4px" />
            {{ navi.label }}
          </div>
        </div>
        <component :is="targetComponentName" v-bind="{ resourceId }" />
      </el-main>
    </el-container>
  </el-container>
</template>
<script lang="ts">
export default {
  name: 'CatalogsHomesView',
  components: {
    CategoryList,
    CategoryDetail,
    SolutionList,
    SolutionDetail,
    ProductList,
    ProductDetail,
    LabelList,
    UsefulMaterialList,
    Worksheet
  }
}
</script>
<style scoped>
.el-container {
  background-color: v-bind('colors.bg.gray01');
  height: 100%;
}
.el-header {
  padding: 0;
}
.el-main {
  padding: 18px 0 8px 20px;
}
.el-aside {
  padding: 16px 0;
}
#sidebar {
  height: 100%;
  border-right: 2px solid v-bind('colors.border.base');
}
.local-menu-header {
  height: 24px;
  line-height: 24px;
  padding-left: 16px;
  font-size: 14px;
  font-weight: bold;
  color: v-bind('colors.text.lighter');
}
.menu-wrapper {
  margin-top: 8px;
  padding: 0 8px;
}
.menu-item {
  height: 36px;
  line-height: 36px;
  padding-left: 16px;
  font-size: 14px;
  font-weight: bold;
  color: v-bind('colors.text.base');
  cursor: pointer;
  margin-bottom: 2px;
}
.menu-item:hover {
  background-color: v-bind('colors.bg.black');
  color: v-bind('colors.text.white');
}
.menu-item.current {
  background-color: v-bind('colors.bg.black');
  color: v-bind('colors.text.white');
}
</style>
