import { defineStore } from 'pinia'
import type { PaginationStats } from '@/types/paginationStats.type'
import type { GetCsRequestQuery, CsRequest, CsRequestStatusQuery } from '@/types/csRequest.type'
import type { _AxiosError } from './api'
import axios from './api'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import type { CreateCsRequestPayload } from '@/types/csRequest.type'

interface State {
  current_cs_request: CsRequest | null
  cs_requests: CsRequest[]
  cs_requests_stats: PaginationStats | null
}

export const useCsRequestStore = defineStore('csRequestStore', {
  state: (): State => ({
    current_cs_request: null,
    cs_requests: [],
    cs_requests_stats: null
  }),
  getters: {},
  actions: {
    clear_current_cs_request() {
      this.current_cs_request = null
    },
    clear_cs_requests() {
      this.$patch((state) => {
        state.cs_requests = []
        state.cs_requests_stats = null
      })
    },
    get_cs_requests(json: GetCsRequestQuery) {
      axios
        .get('/cs-requests', { params: json })
        .then((res) => {
          this.$patch((state) => {
            state.cs_requests = res.data.items
            state.cs_requests_stats = res.data.meta
          })
        })
        .catch((err: _AxiosError) => {
          console.log(err)
        })
    },
    get_cs_request_detail(id: number) {
      axios
        .get('/cs-requests/' + id)
        .then((res: { data: CsRequest }) => {
          this.current_cs_request = res.data
        })
        .catch((err: _AxiosError) => {
          _ElMessage({ type: 'error', message: 'お問い合わせの取得に失敗しました' })
          console.log(err)
        })
    },
    create_cs_request(json: CreateCsRequestPayload) {
      return new Promise<CsRequest>((resolve, reject) => {
        axios
          .post('/cs-requests', json)
          .then((res: { data: CsRequest }) => {
            _ElMessage({ type: 'success', message: '問い合わせを行いました' })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            _ElMessage({ type: 'error', message: '問い合わせに失敗しました' })
            reject(err)
          })
      })
    },
    close_cs_request(id: number, status: CsRequestStatusQuery) {
      return new Promise<CsRequest>((resolve, reject) => {
        axios
          .post('/cs-requests/' + id + '/close')
          .then((res: { data: CsRequest }) => {
            _ElMessage({ type: 'success', message: '問い合わせをクローズしました' })
            this.$patch((state) => {
              if (state.current_cs_request && state.current_cs_request.id == id) {
                state.current_cs_request = res.data
              }
              const index = state.cs_requests.findIndex((e) => e.id == id)
              if (index !== -1) {
                switch (status) {
                  case 'open':
                    state.cs_requests.splice(index, 1)
                    break
                  case 'all':
                    state.cs_requests[index] = res.data
                    break
                }
              } else if (status === 'closed') {
                state.cs_requests.push(res.data)
              }
            })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            _ElMessage({ type: 'error', message: '問い合わせのクローズに失敗しました' })
            console.log(err)
            reject(err)
          })
      })
    }
  }
})
