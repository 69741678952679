export type SolutionAttribute = {
  id: number
  solutionId: number
  title: string
  sortNumber: number
  note: string
  createdAt: Date
  updatedAt: Date
}

export type SolutionAttributeDetail = SolutionAttribute & {
  images: SolutionAttributeImage[]
  pptx?: SolutionAttributePptx
}

export type SolutionAttributeImage = {
  id: number
  solutionAttributeId: number
  originalFileName: string
  uuid: string
  resizeStatus: ImageResizeStatus
  sortNumber: number
  createdAt: Date
  updatedAt: Date
}

export const SolutionFileExtensionForDownload = {
  pptx: 'pptx',
  pdf: 'pdf',
  xlsx: 'xlsx',
  docx: 'docx'
} as const
export type SolutionFileExtensionForDownload = keyof typeof SolutionFileExtensionForDownload

export type SolutionAttributePptx = {
  id: number
  solutionAttributeId: number
  originalFileName: string
  fileExtension: SolutionFileExtensionForDownload
  uuid: string
  createdAt: Date
  updatedAt: Date
}

export const ImageResizeStatus = {
  not_processed: 'not_processed',
  success: 'success',
  error: 'error'
} as const
export type ImageResizeStatus = keyof typeof ImageResizeStatus

// payload
export type CreateSolutionAttributePayload = {
  solutionId: number
  title: string
  // noteは投げない
  sortNumber: number
}
export type UpdateSolutionAttributePayload = CreateSolutionAttributePayload & {
  id: number
  note: string
}
