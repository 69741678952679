<script setup lang="ts">
import { _ElMessage } from '@/utils/element-plus-wrapper'
import { useRouter } from 'vue-router'
import CommonWorksheet from '../CommonWorksheet.vue'
import { useWorksheetStore } from '@/stores/worksheetStore'
import { computed } from 'vue'

const props = defineProps<{
  resourceId: string
}>()
const router = useRouter()
const solutionId = (() => {
  if (props.resourceId == '' || isNaN(Number(props.resourceId))) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    router.push('/catalogs?menu=solutionList')
    return -1
  } else {
    return Number(props.resourceId)
  }
})()
const worksheetStore = useWorksheetStore()
if (solutionId !== -1) {
  worksheetStore.get_worksheet({ solutionId })
}
const solutionData = computed(() => worksheetStore.worksheet?.solution)
</script>

<template>
  <div v-if="solutionId === -1 || !solutionData">不正なリソースIDです</div>
  <CommonWorksheet
    v-else
    :solutionId="solutionId"
    :canEdit="true"
    :isImportantColumnsOnly="false"
    :title="solutionData.title"
  />
</template>
<style scoped></style>
