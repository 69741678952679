<script setup lang="ts">
import { colorPalette as colors } from '@/utils/enums'
import CaseItemDetail from '../components/cases/CaseItemDetail.vue'
import CommonHeader from '../components/CommonHeader.vue'
import { computed, ref } from 'vue'
import { extractIdFromHash } from '@/utils/hash.helper'
import CaseItemList from '@/components/cases/CaseItemList.vue'
import CsRequestList from '@/components/cases/CsRequestList.vue'
import CsRequestDetail from '@/components/cases/CsRequestDetail.vue'
import { CsRequestStatusQuery } from '@/types/csRequest.type'

const props = defineProps<{
  caseIdHash?: string
  csRequestIdHash?: string
}>()

const caseId = computed(() => {
  return (() => {
    try {
      return props.caseIdHash ? extractIdFromHash(props.caseIdHash) : -1
    } catch (e) {
      return -1
    }
  })()
})
const csRequestId = computed(() => {
  return (() => {
    try {
      return props.csRequestIdHash ? extractIdFromHash(props.csRequestIdHash) : -1
    } catch (e) {
      return -1
    }
  })()
})

// tab
type Tab = 'cases' | 'csRequest'
const selectTab = ref<Tab | null>(null)
const activeTab = computed(() => {
  if (selectTab.value) {
    return selectTab.value
  }
  if (caseId.value !== -1) {
    return 'cases'
  }
  if (csRequestId.value !== -1) {
    return 'csRequest'
  }
  return 'cases'
})
function switchTab(name: Tab) {
  selectTab.value = name
}

// status
const csRequestStatus = ref<CsRequestStatusQuery>(CsRequestStatusQuery.open)
function switchStatus(status: CsRequestStatusQuery) {
  csRequestStatus.value = status
}
</script>
<template>
  <el-container>
    <el-header height="48px">
      <CommonHeader current-menu="cases" />
    </el-header>
    <el-container>
      <!-- template -->
      <el-aside width="40%">
        <div id="sidebar">
          <el-tabs v-model="activeTab" @tab-change="switchTab">
            <el-tab-pane label="見積もり依頼 / 相談" name="cases" />
            <el-tab-pane label="お問い合わせ" name="csRequest" />
          </el-tabs>
          <template v-if="activeTab === 'cases'">
            <CaseItemList :caseId="caseId" />
          </template>
          <template v-else-if="activeTab === 'csRequest'">
            <CsRequestList :csRequestId="csRequestId" @switch-status="switchStatus" />
          </template>
        </div>
      </el-aside>
      <el-main>
        <template v-if="activeTab === 'cases'">
          <div v-if="caseId === -1" class="placeholder">見積もり依頼 / 相談を選択してください</div>
          <CaseItemDetail v-else :caseId="caseId" />
        </template>
        <template v-else-if="activeTab === 'csRequest'">
          <div v-if="csRequestId === -1" class="placeholder">お問い合わせを選択してください</div>
          <CsRequestDetail v-else :csRequestId="csRequestId" :status="csRequestStatus" />
        </template>
      </el-main>
    </el-container>
  </el-container>
</template>
<style scoped>
.el-container {
  background-color: v-bind('colors.bg.gray01');
  height: 100%;
}
.el-header {
  padding: 0;
}
.el-main {
  padding: 0 16px 8px 16px;
  height: 100%;
}
.placeholder {
  padding-top: 12px;
}
/* sidebar */
#sidebar {
  height: 100%;
  border-right: 2px solid v-bind('colors.border.base');
  padding-left: 16px;
}
</style>
