export const Constants = {
  // BASE_URL: 'https://conversation-demo-api.onrender.com/',
  BASE_URL: import.meta.env.VITE_BASE_URL,
  ENV_NAME: import.meta.env.VITE_ENV_NAME,
  VITE_SALT_FOR_HASHIDS: import.meta.env.VITE_SALT_FOR_HASHIDS,
  VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN
} as const
export type Constants = keyof typeof Constants

export const colorPalette: Record<string, Record<string, string>> = {
  service: {
    black: '#000000',
    secondary: '#212121',
    darkBlue: '#13244F',
    accent: '#FC6200',
    accentPale: '#E6B799'
  },
  bg: {
    white: '#FFFFFF',
    gray01: '#F8F8F8',
    gray02: '#FAFAF6',
    gray05: '#404040',
    gray08: '#212121',
    black: '#000000'
  },
  text: {
    base: '#333333',
    lighter: '#787878',
    lighter2: '#a0a0a0',
    black: '#000000',
    white: '#FFFFFF',
    disabled: '#9E9E9E'
  },
  border: {
    divider: '#EDEFF3', // on: bg.base
    base: '#E8E8E8',
    white: 'rgba(255,255,255,0.25)'
  },
  shadow: {
    base: 'rgba(255,255,255, 0.1)',
    lighter: 'rgba(96,115,153,0.1)',
    card: '0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14)',
    bottomSheet:
      '0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14)'
  },
  utility: {
    green: '#1C616E', // success, done
    greenLighter: '#49808B',
    greenBg: '#E8EFF0',
    yellow: '#D69E70', // caution
    yellowBg: '#F6EBE2',
    red: '#D46F72', // danger, warning, failure
    redBg: '#FAF0F0'
  }
}

export const AdminOnlySettingsMenuItems = {
  userGroups: 'ユーザグループ管理',
  users: 'ユーザ管理',
  aiThreads: 'AI chat履歴一覧'
} as const
export type AdminOnlySettingsMenuItems = keyof typeof AdminOnlySettingsMenuItems

export const SettingsMenuItems = {
  myAccount: 'ユーザアカウント',
  ...AdminOnlySettingsMenuItems
} as const
export type SettingsMenuItems = keyof typeof SettingsMenuItems

export const CatalogsMenuItems = {
  categoryList: '業種・部門管理',
  solutionList: 'ソリューション管理',
  productList: '商材管理',
  labelList: '比較表項目管理',
  usefulMaterialList: 'お役立ち資料管理'
} as const
export type CatalogsMenuItems = keyof typeof CatalogsMenuItems

export const PageTypes = {
  worksheet: '比較表管理'
} as const
export type PageTypes = keyof typeof PageTypes
