import { defineStore } from 'pinia'
import type { _AxiosError } from './api'
import axios from './api'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import type {
  CreateTopCategoryPayload,
  TopCategory,
  TopCategoryDetail
} from '@/types/topCategory.type'
import type {
  CreateTobeItemPayload,
  TobeItemDetail,
  UpdateTobeItemPayload
} from '@/types/tobeItem.type'
import type {
  CreateObjectivePayload,
  ObjectiveDetail,
  SortObjectivesPayload,
  UpdateObjectivePayload
} from '@/types/objective.type'
import type { TobeImageDetail, UpdateTobeImagePayload } from '@/types/tobeImage.type'
import { extractFilename } from '@/utils/file.helper'
import { Constants } from '@/utils/enums'
import type { AsisFileExtensionForDownload, AsisImageDetail } from '@/types/asisImage.type'

interface State {
  current_top_category: TopCategoryDetail | null
  top_categories: TopCategory[]
}

export const useCategoryStore = defineStore('categoryStore', {
  state: (): State => ({
    current_top_category: null,
    top_categories: []
  }),
  getters: {
    get_asis_image_url:
      () => (topCategoryId: number, fileId: number, fileExtension: 'pptx' | 'png') => {
        return `${Constants['BASE_URL']}/categories/${topCategoryId}/asisImage/download?fileId=${fileId}&asisTobeExtension=${fileExtension}`
      },
    get_tobe_image_url:
      () => (tobeImageId: number, fileId: number, fileExtension: 'pptx' | 'png') => {
        return `${Constants['BASE_URL']}/categories/tobeImages/${tobeImageId}/download?fileId=${fileId}&asisTobeExtension=${fileExtension}`
      },
    current_asis_image(state) {
      return state.current_top_category?.asisImage || null
    },
    current_objective_candidates(state) {
      const objectives = [...(state.current_top_category?.objectives || [])]
      return objectives.sort((a, b) => a.sortNumber - b.sortNumber)
    },
    current_tobe_images_candidates(state) {
      return state.current_top_category?.tobeImages || []
    }
  },
  actions: {
    // TopCategory
    clear_current_top_category() {
      this.current_top_category = null
    },
    set_current_top_category(data: TopCategoryDetail) {
      this.current_top_category = data
    },
    get_top_categories() {
      axios
        .get('/categories')
        .then((res) => {
          this.top_categories = res.data
        })
        .catch((err: _AxiosError) => {
          console.log(err)
        })
    },
    get_top_category_detail(id: number, skipSave = false) {
      return new Promise<TopCategoryDetail>((resolve, reject) => {
        axios
          .get(`/categories/${id}`)
          .then((res) => {
            if (!skipSave) {
              this.current_top_category = res.data
            }
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    create_top_category(json: CreateTopCategoryPayload) {
      return new Promise<TopCategoryDetail>((resolve, reject) => {
        axios
          .post('/categories', json)
          .then((res) => {
            this.top_categories.push(res.data)
            _ElMessage({ type: 'success', message: '保存しました' })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            _ElMessage({ type: 'error', message: '保存に失敗しました' })
            reject(err)
          })
      })
    },
    update_top_category(json: Partial<CreateTopCategoryPayload> & { id: number }) {
      const { id, ...payload } = json
      return new Promise<TopCategoryDetail>((resolve, reject) => {
        axios
          .put(`/categories/${id}`, payload)
          .then((res: { data: TopCategoryDetail }) => {
            const index = this.top_categories.findIndex((t) => t.id === id)
            if (index !== -1) {
              this.top_categories[index] = res.data
            }
            if (this.current_top_category?.id === id) {
              this.current_top_category = res.data
            }
            _ElMessage({ type: 'success', message: '更新しました' })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            _ElMessage({ type: 'error', message: '更新に失敗しました' })
            reject(err)
          })
      })
    },
    delete_top_category(id: number) {
      return new Promise<void>((resolve, reject) => {
        axios
          .delete(`/categories/${id}`)
          .then(() => {
            this.top_categories = this.top_categories.filter((t) => t.id !== id)
            _ElMessage({ type: 'success', message: '削除しました' })
            resolve()
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            _ElMessage({ type: 'error', message: '削除に失敗しました' })
            reject(err)
          })
      })
    },
    // AsisImage
    upload_asis_image(topCategoryId: number, file: File) {
      const formData = new FormData()
      formData.append('file', file)
      return new Promise<void>((resolve, reject) => {
        axios
          .post(`/categories/${topCategoryId}/asisImage/upload`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then((res: { data: AsisImageDetail }) => {
            this.$patch((state) => {
              if (state.current_top_category?.id === topCategoryId) {
                state.current_top_category.asisImage = res.data
              }
            })
            _ElMessage({ type: 'success', message: 'アップロードしました' })
            resolve()
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            _ElMessage({ type: 'error', message: 'アップロードに失敗しました' })
            reject(err)
          })
      })
    },
    download_asis_image(
      topCategoryId: number,
      fileId: number,
      fileExtension: 'png' | AsisFileExtensionForDownload
    ) {
      return new Promise<{ data: Blob; filename: string }>((resolve, reject) => {
        axios
          .get(`/categories/${topCategoryId}/asisImage/download`, {
            params: { fileId, asisTobeExtension: fileExtension },
            responseType: 'blob'
          })
          .then((res) => {
            const filename = extractFilename(res, `download.${fileExtension}`)
            resolve({ data: res.data, filename })
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            _ElMessage({ type: 'error', message: 'ダウンロードに失敗しました' })
            reject(err)
          })
      })
    },
    // Objective
    create_objective(json: CreateObjectivePayload) {
      return new Promise<ObjectiveDetail>((resolve, reject) => {
        axios
          .post('/categories/objectives', json)
          .then((res: { data: ObjectiveDetail }) => {
            this.$patch((state) => {
              if (state.current_top_category?.id === json.topCategoryId) {
                state.current_top_category.objectives.push(res.data)
              }
            })
            _ElMessage({ type: 'success', message: '主目的を保存しました' })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            _ElMessage({ type: 'error', message: '主目的の保存に失敗しました' })
            reject(err)
          })
      })
    },
    update_objective(json: UpdateObjectivePayload) {
      const { id, ...dto } = json
      return new Promise<ObjectiveDetail>((resolve, reject) => {
        axios
          .put(`/categories/objectives/${id}`, dto)
          .then((res: { data: ObjectiveDetail }) => {
            this.$patch((state) => {
              if (state.current_top_category?.id === res.data.topCategoryId) {
                const index = state.current_top_category.objectives.findIndex((t) => t.id === id)
                if (index !== -1) {
                  state.current_top_category.objectives[index] = res.data
                }
              }
            })
            _ElMessage({ type: 'success', message: '主目的を更新しました' })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            _ElMessage({ type: 'error', message: '主目的の更新に失敗しました' })
            reject(err)
          })
      })
    },
    sort_objectives(payload: SortObjectivesPayload) {
      return new Promise<void>((resolve, reject) => {
        axios
          .post('/categories/objectives/sort', payload)
          .then((res: { data: TopCategoryDetail }) => {
            this.current_top_category = res.data
            _ElMessage({ type: 'success', message: '並び替えました' })
            resolve()
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            _ElMessage({ type: 'error', message: '並び替えに失敗しました' })
            reject(err)
          })
      })
    },
    add_tobe_image_to_objective(objectiveId: number, tobeImageId: number) {
      axios
        .post(`/categories/objectives/${objectiveId}/addTobeImage`, { tobeImageId })
        .then((res: { data: ObjectiveDetail }) => {
          this.$patch((state) => {
            if (state.current_top_category) {
              const index = state.current_top_category.objectives.findIndex(
                (o) => o.id === objectiveId
              )
              if (index !== -1) {
                state.current_top_category.objectives[index] = res.data
                // tobeImageも更新する
                const tobeImageIndex = state.current_top_category.tobeImages.findIndex(
                  (t) => t.id === tobeImageId
                )
                if (!state.current_top_category.tobeImages[tobeImageIndex].objectives) {
                  state.current_top_category.tobeImages[tobeImageIndex].objectives = []
                }
                state.current_top_category.tobeImages[tobeImageIndex].objectives.push(res.data)
                for (const t of state.current_top_category.tobeImages) {
                  if (t.id !== tobeImageId) {
                    t.objectives = t.objectives.filter((o) => o.id !== objectiveId)
                  }
                }
              }
            }
          })
        })
        .catch((err: _AxiosError) => {
          console.log(err)
          _ElMessage({ type: 'error', message: '設定に失敗しました' })
        })
    },
    remove_tobe_image_from_objective(objectiveId: number, tobeImageId: number) {
      axios
        .post(`/categories/objectives/${objectiveId}/removeTobeImage`, { tobeImageId })
        .then((res: { data: ObjectiveDetail }) => {
          this.$patch((state) => {
            if (state.current_top_category) {
              const index = state.current_top_category.objectives.findIndex(
                (o) => o.id === objectiveId
              )
              if (index !== -1) {
                state.current_top_category.objectives[index] = res.data
                // tobeImageも更新する
                const tobeImageIndex = state.current_top_category.tobeImages.findIndex(
                  (t) => t.id === tobeImageId
                )
                state.current_top_category.tobeImages[tobeImageIndex].objectives =
                  state.current_top_category.tobeImages[tobeImageIndex].objectives.filter(
                    (o) => o.id !== objectiveId
                  )
              }
            }
          })
        })
        .catch((err: _AxiosError) => {
          console.log(err)
          _ElMessage({ type: 'error', message: '設定に失敗しました' })
        })
    },
    delete_objective(id: number) {
      return new Promise<void>((resolve, reject) => {
        axios
          .delete(`/categories/objectives/${id}`)
          .then(() => {
            this.$patch((state) => {
              if (state.current_top_category) {
                state.current_top_category.objectives =
                  state.current_top_category.objectives.filter((o) => o.id !== id)
              }
            })
            _ElMessage({ type: 'success', message: '主目的を削除しました' })
            resolve()
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            _ElMessage({ type: 'error', message: '主目的の削除に失敗しました' })
            reject(err)
          })
      })
    },
    // TobeImage
    create_tobe_image(topCategoryId: number) {
      return new Promise<TobeImageDetail>((resolve, reject) => {
        axios
          .post(`/categories/tobeImages`, { topCategoryId })
          .then((res: { data: TobeImageDetail }) => {
            this.$patch((state) => {
              if (state.current_top_category?.id === topCategoryId) {
                state.current_top_category.tobeImages.push(res.data)
              }
            })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            _ElMessage({ type: 'error', message: '処理に失敗しました' })
            reject(err)
          })
      })
    },
    update_tobe_image(json: UpdateTobeImagePayload) {
      const { id, ...dto } = json
      return new Promise<TobeImageDetail>((resolve, reject) => {
        axios
          .put(`/categories/tobeImages/${id}`, dto)
          .then((res: { data: TobeImageDetail }) => {
            this.$patch((state) => {
              if (state.current_top_category?.id === res.data.topCategoryId) {
                const index = state.current_top_category.tobeImages.findIndex((t) => t.id === id)
                if (index !== -1) {
                  state.current_top_category.tobeImages[index] = res.data
                }
              }
            })
            _ElMessage({ type: 'success', message: 'ToBe画像を保存しました' })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            _ElMessage({ type: 'error', message: 'ToBe画像の保存に失敗しました' })
            reject(err)
          })
      })
    },
    delete_tobe_image(id: number, showMessage: boolean = true) {
      return new Promise<void>((resolve, reject) => {
        axios
          .delete(`/categories/tobeImages/${id}`)
          .then(() => {
            this.$patch((state) => {
              if (state.current_top_category) {
                state.current_top_category.tobeImages =
                  state.current_top_category.tobeImages.filter((t) => t.id !== id)
              }
            })
            if (showMessage) {
              _ElMessage({ type: 'success', message: 'ToBe画像を削除しました' })
            }
            resolve()
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            _ElMessage({ type: 'error', message: 'ToBe画像の削除に失敗しました' })
            reject(err)
          })
      })
    },
    upload_tobe_image(id: number, file: File) {
      const formData = new FormData()
      formData.append('file', file)
      return new Promise<void>((resolve, reject) => {
        axios
          .post(`/categories/tobeImages/${id}/upload`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then((res: { data: TobeImageDetail }) => {
            this.$patch((state) => {
              if (state.current_top_category?.id === res.data.topCategoryId) {
                const index = state.current_top_category.tobeImages.findIndex((t) => t.id === id)
                if (index !== -1) {
                  state.current_top_category.tobeImages[index] = res.data
                }
              }
            })
            _ElMessage({ type: 'success', message: 'アップロードしました' })
            resolve()
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            _ElMessage({ type: 'error', message: 'アップロードに失敗しました' })
            reject(err)
          })
      })
    },
    download_tobe_image(
      tobeImageId: number,
      fileId: number,
      fileExtension: 'png' | AsisFileExtensionForDownload
    ) {
      return new Promise<{ data: Blob; filename: string }>((resolve, reject) => {
        axios
          .get(`/categories/tobeImages/${tobeImageId}/download`, {
            params: { fileId, asisTobeExtension: fileExtension },
            responseType: 'blob'
          })
          .then((res) => {
            const filename = extractFilename(res, `download.${fileExtension}`)
            resolve({ data: res.data, filename })
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            _ElMessage({ type: 'error', message: 'ダウンロードに失敗しました' })
            reject(err)
          })
      })
    },
    // TobeItem
    create_tobe_item(json: CreateTobeItemPayload) {
      return new Promise<TobeItemDetail>((resolve, reject) => {
        axios
          .post('/categories/tobeItems', json)
          .then((res: { data: TobeItemDetail }) => {
            this.$patch((state) => {
              if (state.current_top_category?.id === res.data.tobeImage.topCategoryId) {
                const index = state.current_top_category.tobeImages.findIndex(
                  (t) => t.id === json.tobeImageId
                )
                state.current_top_category.tobeImages[index].tobeItems.push(res.data)
              }
            })
            _ElMessage({ type: 'success', message: 'ToBeアイテムを保存しました' })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            _ElMessage({ type: 'error', message: 'ToBeアイテムの保存に失敗しました' })
            reject(err)
          })
      })
    },
    update_tobe_item(json: UpdateTobeItemPayload) {
      const { id, ...dto } = json
      return new Promise<TobeItemDetail>((resolve, reject) => {
        axios
          .put(`/categories/tobeItems/${id}`, dto)
          .then((res: { data: TobeItemDetail }) => {
            this.$patch((state) => {
              if (state.current_top_category?.id === res.data.tobeImage.topCategoryId) {
                const index = state.current_top_category.tobeImages.findIndex(
                  (t) => t.id === res.data.tobeImageId
                )
                if (index !== -1) {
                  const i = state.current_top_category.tobeImages[index].tobeItems.findIndex(
                    (t) => t.id === id
                  )
                  state.current_top_category.tobeImages[index].tobeItems[i] = res.data
                }
              }
            })
            _ElMessage({ type: 'success', message: 'ToBeアイテムを更新しました' })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            _ElMessage({ type: 'error', message: 'ToBeアイテムの更新に失敗しました' })
            reject(err)
          })
      })
    },
    delete_tobe_item(topCategoryId: number, tobeImageId: number, tobeItemId: number) {
      return new Promise<void>((resolve, reject) => {
        axios
          .delete(`/categories/tobeItems/${tobeItemId}`)
          .then(() => {
            this.$patch((state) => {
              if (state.current_top_category?.id === topCategoryId) {
                const index = state.current_top_category.tobeImages.findIndex(
                  (t) => t.id === tobeImageId
                )
                if (index !== -1) {
                  state.current_top_category.tobeImages[index].tobeItems =
                    state.current_top_category.tobeImages[index].tobeItems.filter(
                      (t) => t.id !== tobeItemId
                    )
                }
              }
            })
            _ElMessage({ type: 'success', message: 'ToBeアイテムを削除しました' })
            resolve()
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            _ElMessage({ type: 'error', message: 'ToBeアイテムの削除に失敗しました' })
            reject(err)
          })
      })
    },
    add_solution_to_tobe_item(tobeItemId: number, solutionId: number) {
      axios
        .post(`/categories/tobeItems/${tobeItemId}/addSolution`, { solutionId })
        .then((res: { data: TobeItemDetail }) => {
          this.$patch((state) => {
            if (state.current_top_category?.id === res.data.tobeImage.topCategoryId) {
              const index = state.current_top_category.tobeImages.findIndex(
                (t) => t.id === res.data.tobeImageId
              )
              if (index !== -1) {
                const i = state.current_top_category.tobeImages[index].tobeItems.findIndex(
                  (t) => t.id === tobeItemId
                )
                state.current_top_category.tobeImages[index].tobeItems[i] = res.data
              }
            }
          })
          _ElMessage({ type: 'success', message: '追加しました' })
        })
        .catch((err: _AxiosError) => {
          console.log(err)
          _ElMessage({ type: 'error', message: '追加に失敗しました' })
        })
    },
    remove_solution_from_tobe_item(tobeItemId: number, solutionId: number) {
      axios
        .post(`/categories/tobeItems/${tobeItemId}/removeSolution`, { solutionId })
        .then((res: { data: TobeItemDetail }) => {
          this.$patch((state) => {
            if (state.current_top_category?.id === res.data.tobeImage.topCategoryId) {
              const index = state.current_top_category.tobeImages.findIndex(
                (t) => t.id === res.data.tobeImageId
              )
              if (index !== -1) {
                const i = state.current_top_category.tobeImages[index].tobeItems.findIndex(
                  (t) => t.id === tobeItemId
                )
                state.current_top_category.tobeImages[index].tobeItems[i] = res.data
              }
            }
          })
          _ElMessage({ type: 'success', message: '削除しました' })
        })
        .catch((err: _AxiosError) => {
          console.log(err)
          _ElMessage({ type: 'error', message: '削除に失敗しました' })
        })
    },
    sort_tobe_items(tobeImageId: number, tobeItemIdsInOrder: number[]) {
      return new Promise<void>((resolve, reject) => {
        axios
          .post('/categories/tobeItems/sortTobeItems/', {
            tobeImageId,
            tobeItemIdsInOrder
          })
          .then((res: { data: TobeImageDetail }) => {
            this.$patch((state) => {
              if (state.current_top_category?.id === res.data.topCategoryId) {
                const index = state.current_top_category.tobeImages.findIndex(
                  (t) => t.id === res.data.id
                )
                if (index !== -1) {
                  state.current_top_category.tobeImages[index].tobeItems = res.data.tobeItems
                }
              }
            })
            _ElMessage({ type: 'success', message: '並び替えました' })
            resolve()
          })
          .catch((err: _AxiosError) => {
            console.error(err)
            _ElMessage({ type: 'error', message: '並び替えに失敗しました' })
            reject(err)
          })
      })
    }
  }
})
