export const passwordRules =
  '英字・数字・記号のうち最低2つ以上を組み合わせて、8~24文字で設定してください'
export function passwordValidator(txt: string): boolean {
  let result = false
  const patternNum = /[0-9]/i
  const patternAlphabet = /[a-zA-Z]/i
  const patternSymbol = /[!"#$%&'()*+\-.,/:;<=>?@[\\\]^_`{|}~]/i
  const MIN_LEN = 8
  const MAX_LEN = 24
  if (txt.length >= MIN_LEN && txt.length <= MAX_LEN) {
    let tmpResult = false
    let allowedCharsOnly = true
    let validPoint = 0 // 組み合わせ数

    txt.split('').forEach((char) => {
      if (!patternNum.test(char) && !patternAlphabet.test(char) && !patternSymbol.test(char)) {
        allowedCharsOnly = false
        return
      }
    })
    tmpResult = patternNum.test(txt)
    if (tmpResult) {
      validPoint++
    }
    tmpResult = patternAlphabet.test(txt)
    if (tmpResult) {
      validPoint++
    }
    tmpResult = patternSymbol.test(txt)
    if (tmpResult) {
      validPoint++
    }

    // result
    if (validPoint >= 2 && allowedCharsOnly) {
      result = true
    }
  }
  return result
}
