import type { ImageResizeStatus } from './solutionAttribute.type'
import type { TopCategory } from './topCategory.type'

export type AsisImage = {
  id: number
  topCategoryId: number
  createdAt: Date
  updatedAt: Date
}

export type AsisImageDetail = AsisImage & {
  topCategory: TopCategory
  asisPptx?: AsisPptx
  asisPng?: AsisPng
}

export const AsisFileExtensionForDownload = {
  pptx: 'pptx',
  pdf: 'pdf',
  xlsx: 'xlsx',
  docx: 'docx'
} as const
export type AsisFileExtensionForDownload = keyof typeof AsisFileExtensionForDownload

export type AsisPptx = {
  id: number
  asisImageId: number
  originalFileName: string
  fileExtension: AsisFileExtensionForDownload
  uuid: string
  createdAt: Date
  updatedAt: Date
}

export type AsisPng = {
  id: number
  asisImageId: number
  originalFileName: string
  uuid: string
  resizeStatus: ImageResizeStatus
  createdAt: Date
  updatedAt: Date
}
