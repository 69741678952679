<script setup lang="ts">
import { computed, ref } from 'vue'
import { colorPalette as colors } from '@/utils/enums'
import { _ElConfirm, _ElMessage } from '@/utils/element-plus-wrapper'
import { useRouter } from 'vue-router'
import { useWorksheetStore } from '@/stores/worksheetStore'
import type {
  UpdateWorksheetColumnLabelPayload,
  WorksheetColumnLabel
} from '@/types/worksheet.type'
import { useSolutionStore } from '@/stores/solutionStore'
import { useUserStore } from '@/stores/userStore'

const router = useRouter()
const worksheetStore = useWorksheetStore()
const solutionStore = useSolutionStore()
const userStore = useUserStore()
worksheetStore.get_all_labels()
solutionStore.get_solutions({})
const labels = computed(() => worksheetStore.labels)
const solutions = computed(() => solutionStore.solutions)
const isSubmitting = ref(false)
const isAdmin = computed(() => userStore.get_current_user.role === 'admin')

function getSolutionNames(ids: number[]) {
  return ids.map((id) => {
    const solution = solutions.value.find((s) => s.id === id)
    return {
      id: id,
      name: solution ? solution.title : '?'
    }
  })
}

// create
const isCreateMode = ref(false)
const labelCreateInput = ref('')
function openCreateDialog() {
  labelCreateInput.value = ''
  isCreateMode.value = true
}
function submitCreate() {
  if (!labelCreateInput.value.length) {
    _ElMessage({ type: 'error', message: 'ラベル名を入力してください' })
    return
  }
  isSubmitting.value = true
  worksheetStore
    .create_worksheet_column_label(labelCreateInput.value)
    .then(() => {
      labelCreateInput.value = ''
      _ElMessage({ type: 'success', message: '保存しました。続けて登録できます。' })
      // 連続登録できるようにDialogは閉じない
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}

// update
const isUpdateMode = ref(false)
const labelUpdateData = ref<UpdateWorksheetColumnLabelPayload>({
  id: -1,
  name: ''
})
function openUpdateDialog(label: WorksheetColumnLabel) {
  labelUpdateData.value = { id: label.id, name: label.name }
  isUpdateMode.value = true
}
function submitUpdate() {
  if (labelUpdateData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  if (!labelUpdateData.value.name.length) {
    _ElMessage({ type: 'error', message: 'ラベル名を入力してください' })
    return
  }
  isSubmitting.value = true
  worksheetStore
    .update_worksheet_column_label(labelUpdateData.value)
    .then(() => {
      labelUpdateData.value = { id: -1, name: '' }
      isUpdateMode.value = false
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}
function confirmDeleteLabel() {
  if (labelUpdateData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  const target = labels.value.find((x) => x.id === labelUpdateData.value.id)
  if (!target) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  if (target.worksheetColumns.length > 0) {
    _ElMessage({ type: 'error', message: 'このラベルは比較表で使用中です' })
    return
  }
  _ElConfirm('この処理は取り消せません。実行しますか？', `ラベルの削除`, {
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル'
  }).then(() => {
    worksheetStore
      .delete_worksheet_column_label(labelUpdateData.value.id)
      .then(() => {
        labelUpdateData.value = { id: -1, name: '' }
        isUpdateMode.value = false
      })
      .catch(() => {})
  })
}

// transit
function openWorksheet(solutionId: number) {
  router.push('/catalogs?menu=solutionList&pageType=worksheet&resourceId=' + solutionId)
}
</script>

<template>
  <div id="label-list">
    <!-- dialog -->
    <template v-if="true">
      <el-dialog
        :close-on-press-escape="false"
        v-model="isCreateMode"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header>比較表項目の新規登録</template>
        <el-form label-position="left" label-width="120px" @submit.prevent>
          <el-form-item label="ラベル">
            <el-input v-model="labelCreateInput" />
          </el-form-item>
        </el-form>
        <template #footer>
          <span>
            <el-button link @click="isCreateMode = false">閉じる</el-button>
            <el-button :loading="isSubmitting" @click="submitCreate">保存する</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog
        :close-on-press-escape="false"
        v-model="isUpdateMode"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header>比較表項目の編集</template>
        <el-form :model="labelUpdateData" label-position="left" label-width="120px" @submit.prevent>
          <el-form-item label="ラベル">
            <el-input v-model="labelUpdateData.name" />
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="el-dialog-footer_both_side">
            <span>
              <el-button link class="danger" @click="confirmDeleteLabel()">削除する</el-button>
            </span>
            <span>
              <el-button link @click="isUpdateMode = false">閉じる</el-button>
              <el-button :loading="isSubmitting" @click="submitUpdate">保存する</el-button>
            </span>
          </div>
        </template>
      </el-dialog>
    </template>
    <!-- template -->
    <div id="label-list">
      <div class="subheader">
        <div class="subheader-title">比較表項目</div>
        <div v-if="isAdmin" class="subheader-action">
          <el-button @click="openCreateDialog()">新規登録</el-button>
        </div>
      </div>
      <table class="ix-table">
        <tr
          class="ix-table-row clickable"
          v-for="(row, i) in labels"
          :key="row.id"
          @click="isAdmin ? openUpdateDialog(row) : undefined"
        >
          <th class="ix-table-column th">#{{ i + 1 }}</th>
          <td class="ix-table-column">{{ row.name }}</td>
          <td class="ix-table-column end">
            <el-tag
              v-for="solution in getSolutionNames(row.worksheetColumns.map((x) => x.solutionId))"
              :key="solution.id"
              type="info"
              @click="openWorksheet(solution.id)"
              >{{ solution.name }}</el-tag
            >
          </td>
        </tr>
        <tr v-if="!labels.length" class="row-placeholder">
          データ未登録です
        </tr>
      </table>
    </div>
  </div>
</template>
<style scoped>
.th {
  text-align: left;
}
#label-list {
  padding-right: 24px;
  height: 100%;
  color: v-bind('colors.text.base');
}
.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.subheader-title {
  font-size: 16px;
  font-weight: bold;
  height: 32px;
  line-height: 32px;
}
.ix-table-row {
  gap: 20px;
  padding-left: 16px;
}
.row-placeholder {
  padding-left: 16px;
  font-size: 12px;
  color: v-bind('colors.text.disabled');
}
.el-tag + .el-tag {
  margin-left: 4px;
}
</style>
