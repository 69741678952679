<script setup lang="ts">
import { colorPalette as colors } from '@/utils/enums'
import { useProjectStore } from '@/stores/projectStore'
import { computed, onMounted, ref, watch } from 'vue'
import { useCategoryStore } from '@/stores/categoryStore'
import { useSolutionStore } from '@/stores/solutionStore'
import DOMPurify from 'dompurify'
import type { SolutionOption, SolutionRoughEstimate } from '@/types/solution.type'
import CommonWorksheet from '../CommonWorksheet.vue'
import CaseItemChat from './CaseItemChat.vue'
import AiChat from './AiChat.vue'
import { useRouter } from 'vue-router'
import { getHashedId } from '@/utils/hash.helper'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import type { ProjectItemProductPayload } from '@/types/project.type'
import { downloadFile } from '@/utils/file.helper'
import {
  CaseItemTypeLabel,
  type CreateCaseItemPayload,
  CaseItemType,
  type CaseItemMessageDetail
} from '@/types/caseItem.type'
import { useCaseItemStore } from '@/stores/caseItemStore'
import { useUserStore } from '@/stores/userStore'
import { UserRole } from '@/types/user.type'
import { Memo } from '@element-plus/icons-vue'
import { parseMessage } from '@/utils/text.helper'
import getLatestCaseItemMessage from '@/utils/get-latest-caseItem-message'
import { useWorksheetStore } from '@/stores/worksheetStore'

const props = defineProps<{
  projectId: number
  projectItemId: number
  caseItemId?: number
}>()
// stores
const router = useRouter()
const projectStore = useProjectStore()
const categoryStore = useCategoryStore()
const solutionStore = useSolutionStore()
const worksheetStore = useWorksheetStore()
const caseItemStore = useCaseItemStore()

// データ取得前にリフレッシュ
projectStore.clear_current_project_item()
worksheetStore.clear_worksheet()
categoryStore.clear_current_top_category()
solutionStore.clear_current_solution()
solutionStore.clear_solution_options()

// データ取得やその他定義
projectStore.get_project_item_detail(props.projectId, props.projectItemId)
const projectItemData = computed(() => projectStore.current_project_item)
const productIds = computed(() =>
  projectItemData.value ? projectItemData.value.products.map((p) => p.id) : []
)
solutionStore.get_solution_options()
const attributes = computed(() => solutionStore.attributes)
const roughEstimates = computed(() => solutionStore.roughEstimates)
const solutionOptions = computed(() => projectStore.current_solution_options)
const solutionOptionCandidates = computed(() => solutionStore.solution_options)
const commonWorksheet = ref<InstanceType<typeof CommonWorksheet> | null>(null)

function init() {
  if (!projectItemData.value) {
    setTimeout(() => {
      init()
    }, 500)
    return
  }
  if (projectStore.current_project_item) {
    solutionStore.get_solution_detail(projectStore.current_project_item.solutionId)
  } else {
    throw new Error('invalid route')
  }
}
onMounted(() => {
  init()
})
watch(
  () => projectItemData.value,
  () => {
    init()
  }
)
watch(
  () => props.caseItemId,
  (v) => {
    if (v && v > 0) {
      openCaseItemChat(v)
    } else {
      clearCurrentCaseItem()
    }
  }
)

const downloadingId = ref(-1)

// attributes
function getImageUrl(attributeId: number, imageId: number) {
  if (!projectItemData.value) {
    return undefined
  }
  return solutionStore.get_image_url(projectItemData.value.solutionId, attributeId, imageId)
}
function textFormatter(txt: string) {
  return txt.replace(/\n/g, '<br>')
}
async function downloadAttributeFile(attributeId: number) {
  if (downloadingId.value !== -1 || !projectItemData.value) {
    return
  }
  try {
    downloadingId.value = attributeId
    const { data, filename } = await solutionStore.download_attribute_pptx(
      projectItemData.value.solutionId,
      attributeId
    )
    downloadFile(data, filename)
  } catch (error) {
    console.error('ファイルダウンロードエラー:', error)
  } finally {
    downloadingId.value = -1
  }
}

// estimates
const estimateDialogData = ref<SolutionRoughEstimate | null>(null)

function openEstimateDialog(estimate: SolutionRoughEstimate) {
  estimateDialogData.value = estimate
}
function closeEstimateDialog() {
  estimateDialogData.value = null
}

// options
const isOptionsEditMode = ref(false)
function submitOption(option: SolutionOption) {
  if (solutionOptions.value.some((so) => so.id === option.id)) {
    projectStore.remove_solution_option(props.projectId, props.projectItemId, {
      solutionOptionId: option.id
    })
  } else {
    projectStore.add_solution_option(props.projectId, props.projectItemId, {
      solutionOptionId: option.id
    })
  }
}

// caseItems

const caseItems = computed(() => {
  if (!projectItemData.value) {
    return []
  }
  return [...projectItemData.value.caseItems].sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  )
})
function openCaseItemChat(itemId: number) {
  if (isAiChatMode.value) {
    isAiChatMode.value = false
  }
  router.push(
    `/projects?id=${getHashedId(props.projectId)}&itemId=${getHashedId(props.projectItemId)}&cId=${getHashedId(itemId)}`
  )
}
function clearCurrentCaseItem() {
  router.push(
    `/projects?id=${getHashedId(props.projectId)}&itemId=${getHashedId(props.projectItemId)}`
  )
}

// create caseItem
type DialogCreateData = CreateCaseItemPayload & {
  attachedFiles: File[]
}
const defaultDialogCreateDataWithoutFiles: CreateCaseItemPayload = {
  projectItemId: -1,
  caseItemType: 'consult',
  text: ''
}
const dialogCreateData = ref<DialogCreateData>({
  ...defaultDialogCreateDataWithoutFiles,
  attachedFiles: []
})
const isCreateCaseItemMode = ref(false)
const isSendingMessage = ref(false)

function openCreateCaseItemDialog() {
  if (props.projectItemId === -1) {
    _ElMessage({
      message: '案件アイテムが見つかりませんでした',
      type: 'error'
    })
    return
  }
  dialogCreateData.value.projectItemId = props.projectItemId
  isCreateCaseItemMode.value = true
}
function resetCreateCaseItemDialog() {
  dialogCreateData.value = { ...defaultDialogCreateDataWithoutFiles, attachedFiles: [] }
  isCreateCaseItemMode.value = false
}
async function handleCreateCaseItem() {
  if (!dialogCreateData.value.text) {
    _ElMessage({
      message: 'メッセージを入力してください',
      type: 'error'
    })
    return
  }
  if (isSendingMessage.value) {
    return
  }
  isSendingMessage.value = true
  const { attachedFiles, ...payload } = dialogCreateData.value
  const res = await caseItemStore.create_case_item(payload).catch(() => {
    _ElMessage({
      message: 'メッセージの送信に失敗しました',
      type: 'error'
    })
  })

  if (!res) {
    isSendingMessage.value = false
    // テキスト送信のエラー時はメッセージをクリアせずreturn
    return
  }
  const hasFiles = attachedFiles.length > 0

  if (!hasFiles) {
    isSendingMessage.value = false
    resetCreateCaseItemDialog()
    openCaseItemChat(res.id)
    _ElMessage({
      message: 'メッセージを送信しました',
      type: 'success'
    })
    return
  }

  await Promise.allSettled(
    attachedFiles.map((file) => {
      return caseItemStore.add_attachment(res.id, res.messages[0].id, file)
    })
  ).then((results) => {
    const failedFiles = results
      .filter((result) => result.status === 'rejected')
      .map((_result, index) => attachedFiles[index].name)

    if (failedFiles.length === 0) {
      _ElMessage({
        message: 'メッセージを送信しました',
        type: 'success'
      })
    } else {
      const failedFilesMessage = failedFiles.join(', ')
      _ElMessage({
        message: `ファイルのアップロードに失敗しました: ${failedFilesMessage}`,
        type: 'error'
      })
    }
  })

  // 添付ファイル送信時はメッセージ送信は成功しているので、エラーの有無にかかわらずメッセージや添付ファイルなど全てリセットする
  isSendingMessage.value = false
  resetCreateCaseItemDialog()
  openCaseItemChat(res.id)
}
function parseLatestMessageHtml(messages: CaseItemMessageDetail[]) {
  return (
    getLatestCaseItemMessage(messages)?.text ||
    `<span style='color: ${colors.text.lighter};'>メッセージが削除されました。</span>`
  )
}
function latestMessageData(messages: CaseItemMessageDetail[]) {
  return getLatestCaseItemMessage(messages)?.date || ''
}

// attachedFiles
const fileInput = ref<HTMLInputElement | null>(null)
// pptx, pdf, docx, xlsxのみ受け入れる
const acceptedFileExtensions =
  'application/vnd.openxmlformats-officedocument.presentationml.presentation,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
function triggerFileInput() {
  fileInput.value?.click()
}
function handleFileChange(event: Event) {
  const input = event.target as HTMLInputElement
  if (input.files) {
    dialogCreateData.value.attachedFiles.push(...Array.from(input.files))
    input.value = ''
  }
}
function handleFileDrop(event: DragEvent) {
  event.preventDefault()
  if (event.dataTransfer?.files) {
    dialogCreateData.value.attachedFiles.push(...Array.from(event.dataTransfer.files))
  }
}
function removeFile(index: number) {
  dialogCreateData.value.attachedFiles.splice(index, 1)
  if (fileInput.value) {
    fileInput.value.value = ''
  }
}

// AI chatbot
const isAiChatMode = ref(false)
function openAiChatbot() {
  if (props.caseItemId) {
    clearCurrentCaseItem()
  }
  isAiChatMode.value = true
}

// emit
function openWorksheetDetail() {
  router.push(
    `/projects?id=${getHashedId(props.projectId)}&itemId=${getHashedId(props.projectItemId)}&pageType=worksheet`
  )
}
function toggleSelectedProduct(productId: number) {
  if (!commonWorksheet.value) {
    return
  }
  if (!projectItemData.value) {
    _ElMessage({ type: 'error', message: 'データが取得できませんでした' })
    return
  }
  const payload: ProjectItemProductPayload = {
    projectId: props.projectId,
    projectItemId: props.projectItemId,
    productId
  }
  commonWorksheet.value.makeLoading(true)
  if (projectItemData.value.products.some((p) => p.id === productId)) {
    projectStore.remove_project_item_product(payload)
  } else {
    projectStore.add_project_item_product(payload)
  }
  commonWorksheet.value.makeLoading(false)
}

// auth
const userStore = useUserStore()
const isAllowedToEdit = computed(() => {
  if (!projectItemData.value || !userStore.current_user) {
    return false
  }
  if (userStore.current_user.role === UserRole.admin) {
    return true
  }

  return projectItemData.value?.project.userId === userStore.current_user.id
})
</script>

<template>
  <!-- option dialog -->
  <el-dialog
    :close-on-press-escape="false"
    v-model="isOptionsEditMode"
    top="10vh"
    width="50%"
    class="ix-dialog"
  >
    <template #header>オプションを選択してください</template>
    <el-form
      :model="solutionOptionCandidates"
      label-position="left"
      label-width="120px"
      @submit.prevent
    >
      <div class="target-columns-wrapper">
        <div v-for="(option, i) in solutionOptionCandidates" :key="option.id" class="target-column">
          <el-checkbox
            :checked="solutionOptions.some((so) => so.id === option.id)"
            :key="option.id"
            @change="submitOption(option)"
          >
            {{ i + 1 }}. {{ option.title }}
          </el-checkbox>
        </div>
      </div>
    </el-form>
    <template #footer>
      <span>
        <el-button link @click="isOptionsEditMode = false">閉じる</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    :close-on-press-escape="false"
    v-model="isCreateCaseItemMode"
    top="10vh"
    width="50%"
    @close="() => (isCreateCaseItemMode = false)"
    class="ix-dialog"
  >
    <template #header>相談 / 見積もりする</template>
    <div @drop.prevent="handleFileDrop" @dragover.prevent>
      <el-form
        label-position="top"
        :model="dialogCreateData"
        label-width="100px"
        :rules="{
          text: [{ required: true, message: 'メッセージを入力してください', trigger: 'blur' }]
        }"
      >
        <el-form-item label="種別" label-width="120px">
          <el-radio-group v-model="dialogCreateData.caseItemType">
            <el-radio :value="CaseItemType.consult">相談</el-radio>
            <el-radio :value="CaseItemType.estimate">見積もり</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="添付ファイル" label-width="120px">
          <div class="file-input-container">
            <div class="file-display-section">
              <el-tag
                v-for="(file, index) in dialogCreateData.attachedFiles"
                :key="index"
                closable
                type="info"
                @close="removeFile(index)"
              >
                {{ file.name }}
              </el-tag>
            </div>
            <div class="file-input-section">
              <el-button @click="triggerFileInput"
                >ファイルを選択<input
                  type="file"
                  ref="fileInput"
                  @change="handleFileChange"
                  :accept="acceptedFileExtensions"
                  :multiple="true"
                  hidden
              /></el-button>
              <div class="file-upload-tip">pptx, pdf, docx, xlsxのみアップロード可能</div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="内容" label-width="120px">
          <textarea
            v-model="dialogCreateData.text"
            placeholder="メッセージを入力してください"
            class="dialog-textarea"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span>
        <el-button @click="isCreateCaseItemMode = false">キャンセル</el-button>
        <el-button :loading="isSendingMessage" @click="handleCreateCaseItem">送信</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    :close-on-press-escape="false"
    :model-value="!!estimateDialogData"
    v-if="estimateDialogData"
    top="10vh"
    width="50%"
    @close="closeEstimateDialog()"
    class="ix-dialog"
  >
    <template #header>{{ estimateDialogData.title }}</template>
    <div class="estimate-dialog-container">
      <div class="estimate-dialog-note" v-html="parseMessage(estimateDialogData.note)" />
    </div>
    <template #footer>
      <span>
        <el-button @click="closeEstimateDialog">閉じる</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- main view -->
  <div class="project-item-view">
    <div v-if="!projectItemData" class="loading">Loading...</div>
    <template v-else>
      <div class="project-item-header">
        <div class="subheader">
          <div class="subheader-title">{{ projectItemData.title }}</div>
          <div class="subheader-action" v-if="isAllowedToEdit">
            <el-button @click="openAiChatbot()">AIに相談する (β)</el-button>
            <el-button @click="openCreateCaseItemDialog()">相談 / 見積もりする</el-button>
          </div>
        </div>
      </div>
      <div v-if="caseItems.length" class="case-items-wrapper">
        <div class="case-items">
          <div
            v-for="item in caseItems"
            :key="item.id"
            class="case-item-row"
            :class="{ closed: item.caseItemStatus == 'closed' }"
            @click="openCaseItemChat(item.id)"
          >
            <div class="case-item-type">{{ CaseItemTypeLabel[item.caseItemType] }}</div>
            <div class="case-item-latest-message-date">
              {{ latestMessageData(item.messages) }}
            </div>
            <div
              class="case-item-latest-message-text"
              v-html="parseLatestMessageHtml(item.messages)"
            />
          </div>
        </div>
      </div>

      <div class="project-knowledge-wrapper">
        <div class="solution-attributes">
          <div class="subheader">選定ポイント</div>
          <div v-for="attr in attributes" :key="attr.id" class="attribute-item-wrapper">
            <div class="subheader">
              <div class="attribute-item-title">{{ attr.title }}</div>
              <el-button
                v-if="attr.pptx"
                @click="downloadAttributeFile(attr.id)"
                :loading="downloadingId !== -1"
              >
                資料ダウンロード
              </el-button>
            </div>
            <div v-if="attr.images.length" class="attribute-item-images-wrapper">
              <div v-for="image in attr.images" :key="image.id" class="attribute-item-image">
                <img :src="getImageUrl(attr.id, image.id)" crossOrigin="use-credentials" />
              </div>
            </div>
            <div
              class="attribute-item-note"
              v-html="DOMPurify.sanitize(textFormatter(attr.note))"
            />
          </div>
        </div>
        <div class="estimates">
          <div class="subheader">概算見積もり事例</div>
          <div v-if="!roughEstimates.length" class="estimates-placeholder">No Data.</div>
          <div
            v-for="estimate in roughEstimates"
            :key="estimate.id"
            class="estimate-item"
            @click="openEstimateDialog(estimate)"
          >
            <el-icon class="estimate-icon" size="30">
              <Memo />
            </el-icon>
            <div class="estimate-info">
              <div class="estimate-item-title">{{ estimate.title }}</div>
              <div class="estimate-item-note">{{ estimate.note }}</div>
            </div>
          </div>
        </div>
      </div>
      <el-divider />
      <div class="products">
        <CommonWorksheet
          ref="commonWorksheet"
          :solutionId="projectItemData.solutionId"
          :title="'簡易比較表'"
          :canEdit="false"
          :isImportantColumnsOnly="true"
          :targetProductIds="productIds.length ? productIds : undefined"
          @open-worksheet-detail="openWorksheetDetail"
          @toggle-selected-product="toggleSelectedProduct"
        />
      </div>
      <el-divider />
      <div class="solution-options">
        <div class="subheader">
          <div class="subheader-title">オプション</div>
          <div class="subheader-action" v-if="isAllowedToEdit">
            <el-button @click="isOptionsEditMode = true">編集</el-button>
          </div>
        </div>
        <div class="ix-table">
          <div v-for="(option, i) in solutionOptions" :key="option.id" class="ix-table-row">
            <div class="ix-table-column th">#{{ i + 1 }}</div>
            <div class="ix-table-column">{{ option.title }}</div>
          </div>
        </div>
        <div v-if="!solutionOptions.length" class="solution-options-placeholder">登録なし</div>
      </div>
      <template v-if="props.caseItemId && props.caseItemId > 0">
        <CaseItemChat :caseItemId="props.caseItemId" @close="clearCurrentCaseItem()" />
      </template>
      <template v-if="isAiChatMode">
        <AiChat :projectItemId="props.projectItemId" @close="isAiChatMode = false" />
      </template>
    </template>
  </div>
</template>
<style scoped>
.project-item-view {
  padding: 0 0 240px 0;
  height: 100%;
  overflow-y: scroll;
}
.loading {
  padding: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: v-bind('colors.text.disabled');
}
.project-item-header {
  margin-bottom: 8px;
  .subheader {
    display: flex;
    justify-content: space-between;
    height: 32px;
    line-height: 32px;
  }
  .subheader-title {
    font-size: 20px;
    font-weight: bold;
  }
}
/* caseItems */
.case-items-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  background-color: v-bind('colors.utility.yellowBg');
}
.case-items {
  width: calc(100% - 80px);
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.case-item-row {
  width: 100%;
  height: 24px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  cursor: pointer;
  &.closed {
    opacity: 0.5;
  }
  .case-item-type {
    width: 120px;
    flex-shrink: 0;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    background-color: v-bind('colors.utility.yellow');
    color: v-bind('colors.text.white');
  }
  .case-item-latest-message-date {
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .case-item-latest-message-text {
    padding-left: 8px;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.file-input-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.file-display-section {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 4px;
}
.file-input-section {
  display: flex;
  gap: 8px;
}
.file-upload-tip {
  font-size: 12px;
}
.dialog-textarea {
  width: 100%;
  height: 200px;
  border-radius: 4px;
  padding: 8px;
}

/* attribute */
.project-knowledge-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-top: 16px;
}
.solution-attributes {
  width: calc(100% - 280px - 24px);
  .subheader {
    height: 32px;
    line-height: 32px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }
}

.attribute-item-wrapper {
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  background-color: v-bind('colors.bg.gray02');
  gap: 8px;
  padding: 4px 0 24px 8px;
  border-left: 2px solid v-bind('colors.service.darkBlue');
  .subheader {
    display: flex;
    justify-content: space-between;
    height: 32px;
    line-height: 32px;
    .attribute-item-title {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .attribute-item-images-wrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
    overflow-x: auto;
    padding-bottom: 12px;
    .attribute-item-image {
      width: 45%;
      min-width: 500px;
      flex-shrink: 0;
      min-height: 35vh;
      border: 1px solid v-bind('colors.border.base');
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .attribute-item-note {
    font-size: 14px;
    color: v-bind('colors.text.base');
  }
  .attribute-item-pptx-wrapper {
    display: flex;
    gap: 8px;
    .attribute-pptx-header-title {
      font-size: 16px;
      font-weight: bold;
    }
    .attribute-item-pptx {
      font-size: 14px;
      font-weight: bold;
      color: v-bind('colors.text.base');
    }
  }
}
/* estimates */
.estimates {
  width: 280px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .subheader {
    height: 32px;
    line-height: 32px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }
}
.estimate-item {
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 8px 4px;
  border: 1px solid v-bind('colors.border.base');
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  .estimate-icon {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
  }
  .estimate-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-top: 4px;
    padding-right: 4px;
    .estimate-item-title {
      font-size: 14px;
      min-height: 20px;
      height: auto;
      line-height: 20px;
      font-weight: bold;
      color: v-bind('colors.text.base');
    }
    .estimate-item-note {
      font-size: 12px;
      line-height: 16px;
      color: v-bind('colors.text.lighter');
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
/* products */
.products {
  margin-top: 24px;
  margin-bottom: 40px;
}
/* options */
.solution-options {
  margin-top: 24px;
  .subheader {
    display: flex;
    justify-content: space-between;
    height: 32px;
    line-height: 32px;
  }
  .subheader-title {
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
