<script setup lang="ts">
import { computed, ref } from 'vue'
import { useUserStore } from '@/stores/userStore'
import { colorPalette as colors, Constants } from '@/utils/enums'
import {
  type CreateUserPayload,
  type GetUsersQuery,
  type UpdateUserPayload,
  UserRole
} from '@/types/user.type'
import type { GetUserGroupsQuery } from '@/types/user.type'
import { _ElConfirm, _ElMessage } from '@/utils/element-plus-wrapper'
import { Check } from '@element-plus/icons-vue'

const userStore = useUserStore()
const isStaging = Constants['ENV_NAME'] == 'stg'
const isSubmitting = ref(false)

const getUserGroupsQuery = ref<GetUserGroupsQuery>({ page: undefined, limit: undefined })
userStore.get_user_groups(getUserGroupsQuery.value)
const groups = computed(() => {
  return userStore.user_groups
})

const query = ref<GetUsersQuery>({ userGroupId: undefined, page: 1, limit: 50 })
userStore.get_users(query.value)
const users = computed(() => {
  return userStore.users
})

const currentPage = computed({
  get: () => query.value.page,
  set: (val) => (query.value.page = val)
})
function changePage(v: number): void {
  query.value.page = v
  userStore.get_users(query.value)
}
const totalPages = computed(() => {
  let num = 1
  if (userStore.users_stats) {
    num = userStore.users_stats.totalPages
  }
  return num
})

//search
function search() {
  if (query.value.userGroupId === -1) {
    query.value.userGroupId = undefined
  }
  userStore.get_users(query.value)
}

// create
const isCreateMode = ref(false)
const dialogCreateData = ref<CreateUserPayload>({
  name: '',
  email: '',
  userGroupId: -1,
  role: UserRole.normal
})
function openCreateDialog() {
  if (groups.value.length === 0) {
    _ElMessage({ type: 'error', message: 'ユーザグループが存在しないためユーザを発行できません' })
    return
  }
  dialogCreateData.value = {
    name: '',
    email: '',
    userGroupId: query.value.userGroupId || groups.value[0].id,
    role: UserRole.normal
  }
  isCreateMode.value = true
}
function submitCreate() {
  if (!dialogCreateData.value.name.length) {
    _ElMessage({ type: 'error', message: '名前を入力してください' })
    return
  }
  if (!dialogCreateData.value.email.length) {
    _ElMessage({ type: 'error', message: 'メールアドレスを入力してください' })
    return
  }
  if (dialogCreateData.value.userGroupId === -1) {
    _ElMessage({ type: 'error', message: 'ユーザグループを選択してください' })
    return
  }

  isSubmitting.value = true

  userStore
    .create_user(
      dialogCreateData.value,
      // フィルタが空の時にはview更新(skipSave=false)
      !query.value.userGroupId
        ? false
        : // フィルタがある時はフィルタと新しいユーザのuserGroupIdが同じ時view更新(skipSave=false)
          query.value.userGroupId !== dialogCreateData.value.userGroupId
    )
    .then(() => {
      isCreateMode.value = false
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}
// resend email
const resendLoadingId = ref(-1)
function resendEmailVerification(id: number) {
  resendLoadingId.value = id
  userStore
    .resend_email_verification(id)
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      resendLoadingId.value = -1
    })
}
// update
const isUpdateMode = ref(false)
const dialogUpdateData = ref<UpdateUserPayload>({
  id: -1,
  name: ''
})

function openUpdateDialog(user: UpdateUserPayload) {
  dialogUpdateData.value = {
    id: user.id,
    name: user.name
  }
  isUpdateMode.value = true
}

function submitUpdateUser() {
  if (dialogUpdateData.value.id === -1) {
    return
  }
  if (!dialogUpdateData.value.name.length) {
    _ElMessage({ type: 'error', message: '名前を入力してください' })
    return
  }
  isSubmitting.value = true
  userStore
    .update_user_name(dialogUpdateData.value)
    .then(() => {
      isUpdateMode.value = false
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}

// deactivate
function confirmDeactivateUser() {
  if (dialogUpdateData.value.id === -1) {
    return
  }
  const target = dialogUpdateData.value
  _ElConfirm('この処理は取り消せません。実行しますか？', `ユーザーの利用停止`, {
    confirmButtonText: '利用停止する',
    cancelButtonText: 'キャンセル'
  }).then(() => {
    userStore
      .deactivate_user(target.id)
      .then(() => {
        isUpdateMode.value = false
      })
      .catch((e) => {
        console.error(e)
      })
  })
}
</script>

<template>
  <el-dialog
    :close-on-press-escape="false"
    v-model="isCreateMode"
    top="10vh"
    width="50%"
    class="ix-dialog"
  >
    <template #header>ユーザの新規発行</template>
    <el-form :model="dialogCreateData" label-position="left" label-width="120px" @submit.prevent>
      <el-form-item label="名前">
        <el-input class="dialog-text-input" v-model="dialogCreateData.name" />
      </el-form-item>
      <el-form-item label="メールアドレス">
        <el-input class="dialog-text-input" v-model="dialogCreateData.email" />
        <div v-if="isStaging">ユーザを発行する場合は事前にEverforth社にご連絡ください。</div>
      </el-form-item>
      <el-form-item label="ユーザグループ">
        <el-select
          v-model="dialogCreateData.userGroupId"
          value-key="id"
          placeholder="選択してください"
          style="width: 200px"
        >
          <el-option v-for="item in groups" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="権限">
        <el-select
          v-model="dialogCreateData.role"
          placeholder="選択してください"
          style="width: 200px"
        >
          <el-option v-for="item in UserRole" :key="item" :label="item" :value="item" />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span>
        <el-button link @click="isCreateMode = false">閉じる</el-button>
        <el-button :loading="isSubmitting" @click="submitCreate">発行する</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    :close-on-press-escape="false"
    v-model="isUpdateMode"
    top="10vh"
    width="50%"
    class="ix-dialog"
  >
    <template #header>ユーザ名の編集</template>
    <el-form :model="dialogCreateData" label-position="left" label-width="120px" @submit.prevent>
      <el-form-item label="名前">
        <el-input class="dialog-text-input" v-model="dialogUpdateData.name" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="el-dialog-footer_both_side">
        <span>
          <el-button link class="danger" @click="confirmDeactivateUser()">利用停止</el-button>
        </span>
        <span>
          <el-button link @click="isUpdateMode = false">閉じる</el-button>
          <el-button :loading="isSubmitting" @click="submitUpdateUser">保存する</el-button>
        </span>
      </div>
    </template>
  </el-dialog>
  <div id="user-list">
    <div class="subheader">
      <div class="search-container">
        <el-form-item label="ユーザグループ">
          <el-select
            v-model="query.userGroupId"
            value-key="id"
            placeholder="選択してください"
            style="width: 200px"
            @change="search()"
          >
            <el-option :key="-1" label="全て" :value="-1" />
            <el-option v-for="item in groups" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </div>
      <el-button @click="openCreateDialog">新規発行</el-button>
    </div>
    <table class="ix-table">
      <thead>
        <tr class="ix-table-row">
          <th class="first"></th>
          <th class="table-item">ユーザ名</th>
          <th class="table-item">メールアドレス</th>
          <th class="table-item">ユーザグループ</th>
          <th class="table-item small flex-centered">権限</th>
          <th class="table-item small flex-centered">認証</th>
        </tr>
      </thead>
      <tbody>
        <tr
          @click="
            () => {
              if (row.deactivatedAt) {
                return
              }
              openUpdateDialog(row)
            }
          "
          :class="{
            'ix-table-row': true,
            deactivated: row.deactivatedAt
          }"
          v-for="(row, i) in users"
          :key="row.id"
        >
          <th class="first">#{{ i + 1 }}</th>
          <td class="table-item">
            <span v-if="row.deactivatedAt">[停止]&ensp;</span>{{ row.name }}
          </td>
          <td class="table-item">{{ row.email }}</td>
          <td class="table-item user-group">{{ row.userGroup.name }}</td>
          <td class="table-item small flex-centered">{{ row.role }}</td>
          <td class="table-item small resend-button flex-centered">
            <Check
              v-if="row.isEmailVerified"
              style="width: 16px; height: 16px; margin-top: 2px; color: #1c616e"
            />
            <el-button
              :disabled="!!row.deactivatedAt"
              :loading="resendLoadingId === row.id"
              v-else
              link
              @click.stop="() => resendEmailVerification(row.id)"
              >認証メール再送信</el-button
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="pagination-footer">
    <el-pagination
      class="normal"
      :hide-on-single-page="true"
      :page-count="totalPages"
      :current-page="currentPage"
      layout="prev, pager, next"
      @current-change="changePage"
    />
  </div>
</template>
<style scoped>
#user-list {
  height: 100%;
  padding-top: 12px;
  padding-right: 24px;
  color: v-bind('colors.text.base');
}
.ix-table-row {
  gap: 20px;
  padding-left: 16px;
  cursor: pointer;
}
.deactivated {
  cursor: not-allowed;
}
.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dialog-text-input {
  width: 400px;
}
.resend-button {
  display: flex;
  justify-content: end;
}
.ix-table-row {
  display: flex;
  justify-content: space-between;
}
.first {
  width: 50px;
}
.table-item {
  font-weight: bold;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
}
.flex-centered {
  display: flex;
  justify-content: center;
}
.small {
  width: 150px;
}
</style>
