import type { CaseItemDetail } from './caseItem.type'
import type { Objective } from './objective.type'
import type { Product } from './product.type'
import type { Solution, SolutionOption } from './solution.type'
import type { TobeItem } from './tobeItem.type'
import type { TopCategory } from './topCategory.type'
import type { User } from './user.type'

export type Project = {
  id: number
  userId: number
  title: string
  topCategoryId?: number
  tobeItemId?: number
  objectiveId?: number
  status: ProjectStatus
  createdAt: Date
  updatedAt: Date
  confirmedAt?: Date
}

export const ProjectStatus = {
  open: 'open',
  closed: 'closed'
} as const
export type ProjectStatus = keyof typeof ProjectStatus

export type ProjectDetail = Project & {
  user: User
  topCategory?: TopCategory
  tobeItem?: TobeItem
  objective?: Objective
  projectItems: ProjectItem[]
}

export type ProjectItem = {
  id: number
  solutionId: number
  title: string
  projectId: number
  createdAt: Date
  updatedAt: Date
}

export type ProjectItemDetail = ProjectItem & {
  solution: Solution
  project: ProjectDetail
  products: Product[]
  solutionOptions: SolutionOption[]
  caseItems: CaseItemDetail[]
}

export type GetProjectsQuery = {
  page: number
  limit: number
  userIds: number[]
  tobeItemIds: number[]
  topCategoryIds: number[]
  objectiveIds: number[]
  solutionIds: number[]
  ownershipQuery: ProjectOwnershipQuery
  status: ProjectStatusQuery
}

export const ProjectStatusQuery = {
  ...ProjectStatus,
  all: 'all'
} as const
export type ProjectStatusQuery = keyof typeof ProjectStatusQuery

export const ProjectOwnershipQuery = {
  onlySelf: 'onlySelf',
  onlyOthers: 'onlyOthers'
} as const
export type ProjectOwnershipQuery = keyof typeof ProjectOwnershipQuery

export type UpdateProjectPayload = {
  id: number
  title?: string
  status?: ProjectStatus
}

export type ProjectFillPayload = {
  id: number
  topCategoryId?: number
  objectiveId?: number
  tobeItemId?: number
}

export type ProjectOptionPayload = {
  solutionOptionId: number
}

export type ProjectItemProductPayload = {
  projectId: number
  projectItemId: number
  productId: number
}
