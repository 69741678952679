<script setup lang="ts">
import { ref } from 'vue'
import { colorPalette as colors } from '@/utils/enums'
import type { UploadFile } from 'element-plus'
import { downloadFile, validateFile } from '@/utils/file.helper'
import { useUsefulMaterialStore } from '@/stores/usefulMaterialStore'
import {
  type GetUsefulMaterialsQuery,
  type UsefulMaterial,
  type UpdateUsefulMaterialPayload,
  UsefulMaterialStatusLabel,
  UsefulMaterialStatus
} from '@/types/usefulMaterial.type'
import { computed } from 'vue'
import { _ElConfirm, _ElMessage } from '@/utils/element-plus-wrapper'
import { useUserStore } from '@/stores/userStore'

const usefulMaterialStore = useUsefulMaterialStore()
const userStore = useUserStore()

const isAdmin = computed(() => userStore.get_current_user.role === 'admin')

const getUsefulMaterialsQuery = ref<GetUsefulMaterialsQuery>({
  uploaderUserId: undefined,
  status: 'all'
})
usefulMaterialStore.get_useful_materials(getUsefulMaterialsQuery.value)

const usefulMaterials = computed(() => usefulMaterialStore.usefulMaterials)
const isLoading = ref(false)

async function createUsefulMaterial(file: UploadFile) {
  const rawFile = validateFile(file, ['pptx', 'pdf', 'docx', 'xlsx'])
  if (!rawFile) {
    isLoading.value = false
    return
  }
  isLoading.value = true

  usefulMaterialStore
    .create_useful_material(rawFile)
    .catch(() => {})
    .finally(() => {
      isLoading.value = false
    })
}

const dialogUpdateData = ref<UpdateUsefulMaterialPayload>({
  id: -1,
  status: UsefulMaterialStatus.draft,
  fileName: ''
})
const isUpdateMode = ref(false)

function openUpdateDialog(usefulMaterial: UsefulMaterial) {
  dialogUpdateData.value = {
    id: usefulMaterial.id,
    status: usefulMaterial.status,
    fileName: usefulMaterial.fileName
  }
  isUpdateMode.value = true
}
const isSubmitting = ref(false)
function updateUsefulMaterial() {
  if (dialogUpdateData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  if (!dialogUpdateData.value.fileName?.length) {
    _ElMessage({ type: 'error', message: 'ファイル名を入力してください' })
    return
  }
  isSubmitting.value = true
  usefulMaterialStore
    .update_useful_material(dialogUpdateData.value)
    .then(() => (isUpdateMode.value = false))
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}

function confirmDeleteUsefulMaterial() {
  if (dialogUpdateData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  _ElConfirm('この処理は取り消せません。実行しますか？', 'お役立ち資料の削除', {
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル'
  }).then(() => {
    usefulMaterialStore
      .delete_useful_material(dialogUpdateData.value.id)
      .then(() => {
        isUpdateMode.value = false
      })
      .catch(() => {})
  })
}

const downloadingId = ref(-1)
async function downloadUsefulMaterial(id: number, fileExtension: string) {
  try {
    downloadingId.value = id
    const { data, filename } = await usefulMaterialStore.download_useful_material(id, fileExtension)
    downloadFile(data, filename)
  } catch (error) {
    console.error('ファイルダウンロードエラー:', error)
  } finally {
    downloadingId.value = -1
  }
}
</script>

<template>
  <div id="useful-material-list">
    <!-- dialog -->
    <el-dialog
      :close-on-press-escape="false"
      v-model="isUpdateMode"
      top="10vh"
      width="50%"
      class="ix-dialog"
    >
      <template #header>お役立ち資料の編集</template>
      <el-form :model="dialogUpdateData" label-position="left" label-width="120px" @submit.prevent>
        <el-form-item label="ステータス">
          <el-radio-group v-model="dialogUpdateData.status">
            <el-radio :value="UsefulMaterialStatus.draft">下書き</el-radio>
            <el-radio :value="UsefulMaterialStatus.live">公開</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="ファイル名">
          <el-input v-model="dialogUpdateData.fileName" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="el-dialog-footer_both_side">
          <span>
            <el-button link class="danger" @click="confirmDeleteUsefulMaterial">削除する</el-button>
          </span>
          <span>
            <el-button link @click="isUpdateMode = false">閉じる</el-button>
            <el-button :loading="isSubmitting" @click="updateUsefulMaterial">保存する</el-button>
          </span>
        </div>
      </template>
    </el-dialog>
    <div class="subheader">
      <div class="subheader-title">お役立ち資料</div>
      <div v-if="isAdmin" class="subheader-action">
        <el-upload
          class="upload"
          v-loading.fullscreen="isLoading"
          action=""
          :auto-upload="false"
          :show-file-list="false"
          :on-change="createUsefulMaterial"
        >
          <template #default>
            <el-button>新規登録</el-button>
          </template>
        </el-upload>
      </div>
    </div>
    <table class="ix-table">
      <tr
        class="ix-table-row clickable"
        v-for="(row, i) in usefulMaterials"
        :key="row.id"
        @click="isAdmin ? openUpdateDialog(row) : undefined"
      >
        <th class="ix-table-column th">#{{ i + 1 }}</th>
        <td class="ix-table-column file-name">{{ `${row.fileName}.${row.fileExtension}` }}</td>
        <td class="ix-table-column">{{ UsefulMaterialStatusLabel[row.status] }}</td>
        <el-button
          :loading="downloadingId === row.id"
          @click.stop="() => downloadUsefulMaterial(row.id, row.fileExtension)"
          >ダウンロード</el-button
        >
      </tr>
      <tr v-if="!usefulMaterials.length" class="row-placeholder">
        データ未登録です
      </tr>
    </table>
  </div>
</template>
<style scoped>
#useful-material-list {
  padding-right: 24px;
  height: 100%;
  color: v-bind('colors.text.base');
}
.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.subheader-title {
  font-size: 16px;
  font-weight: bold;
  height: 32px;
  line-height: 32px;
}
.file-name {
  width: 200px;
}
.th {
  text-align: left;
}
</style>
