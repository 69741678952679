/*
 * element-plusのMessage系componentをIX用style設定付きで呼び出すためのwrapper
 */
import type { NotificationParamsTyped } from 'element-plus'
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus'
export function _ElMessage(options: { type: string; message: string }): void {
  const op: any = {
    appendTo: '#app',
    customClass: 'ix-message',
    // duration: 0, // for dev
    ...options
  }
  ElMessage(op)
}

export const _ElConfirm = (message: string, title: string, options: Record<string, unknown>) => {
  const op = {
    appendTo: '#app',
    customClass: 'ix-confirm',
    closeOnPressEscape: false,
    ...options
  }
  return ElMessageBox.confirm(message, title, op)
}

export const _ElNotification = (
  type: 'success' | 'warning' | 'info' | 'error' = 'info',
  message: string,
  title: string,
  options?: Record<string, unknown>
) => {
  const op: NotificationParamsTyped = {
    appendTo: '#app',
    customClass: 'ix-notification',
    type,
    position: 'bottom-right',
    showClose: false,
    duration: 0,
    message,
    title,
    ...options
  }
  return ElNotification(op)
}
