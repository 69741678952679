<script setup lang="ts">
import { colorPalette as colors } from '@/utils/enums'
import { computed, ref, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useCaseItemStore } from '@/stores/caseItemStore'
import {
  type CaseItemMessageDetail,
  CaseItemStatusQuery,
  CaseItemTypeQuery,
  type GetCaseItemsQuery
} from '@/types/caseItem.type'
import { useUserStore } from '@/stores/userStore'
import { UserRole } from '@/types/user.type'
import { getHashedId } from '@/utils/hash.helper'

const props = withDefaults(
  defineProps<{
    caseId: number
  }>(),
  {
    caseId: -1
  }
)

// init
const { caseId } = toRefs(props)
const router = useRouter()
const caseItemStore = useCaseItemStore()
const userStore = useUserStore()
userStore.get_users({
  userGroupId: 1,
  roles: [UserRole.admin, UserRole.supervisor]
})
const filteredUsers = computed(() => {
  return userStore.users.filter(
    (user) => user.role === UserRole.supervisor || user.role === UserRole.admin
  )
})
const caseItemsQuery = ref<GetCaseItemsQuery>({
  page: 1,
  limit: 10,
  onlyUnreplied: false,
  caseItemStatus: CaseItemStatusQuery.all,
  caseItemType: CaseItemTypeQuery.all,
  assigneeId: undefined
})
caseItemStore.clear_case_items()
caseItemStore.get_case_items(caseItemsQuery.value)

const caseItems = computed(() => caseItemStore.case_items)
function getLatestMessage(messages: CaseItemMessageDetail[]) {
  if (!messages.length) {
    throw new Error('caseItem with no messages.')
  }
  return [...messages]
    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
    .reverse()[0]
}
function openCase(id: number) {
  router.push(`/cases?id=${getHashedId(id)}`)
}

function onChangeQuery() {
  caseItemStore.get_case_items(caseItemsQuery.value)
}

// pagination
const totalPages = computed(() => {
  let num = 1
  if (caseItemStore.case_items_stats) {
    num = caseItemStore.case_items_stats.totalPages
  }
  return num
})
const currentPage = computed({
  get: () => caseItemsQuery.value.page,
  set: (val) => (caseItemsQuery.value.page = val)
})
function changePage(v: number) {
  caseItemsQuery.value.page = v
  caseItemStore.get_case_items(caseItemsQuery.value)
}
</script>
<template>
  <div class="filter">
    <div class="filter-item-wrapper">
      <label for="onlyUnreplied">未返信のみ</label>
      <el-switch
        id="onlyUnreplied"
        v-model="caseItemsQuery.onlyUnreplied"
        @change="onChangeQuery"
      />
    </div>
    <div class="filter-item-wrapper">
      <label for="assigneeId">担当者</label>
      <el-select
        id="assigneeId"
        v-model="caseItemsQuery.assigneeId"
        placeholder="選択する"
        filterable
        @change="onChangeQuery"
      >
        <el-option
          v-for="item in [{ id: null, name: '未選択' }, ...filteredUsers]"
          :key="item.id || 'unselected'"
          :label="item.name"
          :value="item.id || -1"
        />
      </el-select>
    </div>
    <div class="filter-item-wrapper">
      <label for="caseItemStatus">ステータス</label>
      <el-select
        id="caseItemStatus"
        v-model="caseItemsQuery.caseItemStatus"
        placeholder="選択する"
        @change="onChangeQuery"
      >
        <el-option
          v-for="item in Object.keys(CaseItemStatusQuery).filter(
            (e) => e !== CaseItemStatusQuery.open
          )"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>
    </div>
    <div class="filter-item-wrapper">
      <label for="caseItemType">タイプ</label>
      <el-select
        id="caseItemType"
        v-model="caseItemsQuery.caseItemType"
        placeholder="選択する"
        @change="onChangeQuery"
      >
        <el-option
          v-for="item in Object.keys(CaseItemTypeQuery)"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>
    </div>
  </div>
  <div
    class="case-item"
    :class="{ active: c.id === caseId }"
    v-for="c in caseItems"
    :key="c.id"
    @click="openCase(c.id)"
  >
    <div class="flex-container">
      <span class="subtext flex-container">{{ c.caseItemStatus }}</span>
      <div class="flex-container">
        <span class="case-item-name horizontal-text-truncate"
          >&nbsp;{{ c.projectItem.project.title }}&nbsp;
        </span>
        <span class="case-item-name horizontal-text-truncate"
          >-&nbsp;{{ c.projectItem.title }}&nbsp;</span
        >
      </div>
      <span class="subtext">
        担当者:&nbsp;
        <template v-if="c.assigneeUser">
          <template v-if="c.assigneeUser.name">
            {{ c.assigneeUser.name }}
          </template>
          <template v-else>不明なユーザ</template>
        </template>
        <template v-else>なし</template>
      </span>
    </div>
    <div class="subtext flex-container">
      <template v-if="c.messages.length">
        最後のメッセージ:
        <span class="message horizontal-text-truncate">
          &nbsp;{{ getLatestMessage(c.messages).text }}&nbsp;
        </span>
        by
        <template v-if="c.messages[c.messages.length - 1].author">
          {{ getLatestMessage(c.messages).author.name }}
        </template>
        <template v-else>不明なユーザ</template>
      </template>
      <template v-else> メッセージがありません </template>
    </div>
  </div>
  <div v-if="!caseItems.length" class="empty-cases">見積もり依頼, 相談がありません</div>
  <div class="pagination-footer">
    <el-pagination
      class="pagination"
      layout="prev, pager, next"
      :hide-on-single-page="true"
      :page-count="totalPages"
      :current-page="currentPage"
      @current-change="changePage"
    />
  </div>
</template>
<style scoped>
.filter-item-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
.el-select {
  width: 100px;
}
.local-menu-header {
  display: flex;
  justify-content: space-between;
  height: 24px;
  line-height: 24px;
  margin-bottom: 4px;
}
.local-menu-title {
  font-size: 14px;
  font-weight: bold;
  color: v-bind('colors.text.lighter');
}
.case-item {
  cursor: pointer;
  font-weight: bold;
  color: v-bind('colors.text.base');
  padding: 5px 0;
  &:hover,
  &.active {
    color: v-bind('colors.text.white');
    background-color: v-bind('colors.service.darkBlue');
  }
}
.subtext {
  font-size: 12px;
}
.empty-cases {
  padding: 8px;
  font-size: 14px;
  color: v-bind('colors.text.disabled');
}
.filter {
  display: inline-flex;
  padding: 10px;
  border: 1px solid v-bind('colors.border.base');
  margin-bottom: 20px;
  justify-content: space-around;
}
.flex-container {
  display: flex;
}
.message {
  display: block;
  max-width: 250px;
}
.horizontal-text-truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.case-item-name {
  font-weight: bold;
  max-width: 200px;
}
</style>
