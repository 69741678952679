export const AnnouncementStatus = {
  draft: 'draft',
  published: 'published'
} as const
export type AnnouncementStatus = keyof typeof AnnouncementStatus

export type AnnouncementType = {
  id: number
  title: string
  content: string
  status: AnnouncementStatus
  createdAt: Date
  updatedAt: Date
}

export type CreateAnnouncementPayload = Pick<AnnouncementType, 'title' | 'content' | 'status'>
export type UpdateAnnouncementPayload = Pick<
  AnnouncementType,
  'id' | 'title' | 'content' | 'status'
>

export type GetAnnouncementsQuery = {
  page: number
  limit: number
}
