<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { colorPalette as colors } from '@/utils/enums'
import { useCaseItemStore } from '@/stores/caseItemStore'
import { CaseItemTypeLabel, type CaseItemMessageDetail } from '@/types/caseItem.type'
import { CircleCloseFilled } from '@element-plus/icons-vue'
import ChatInput from '@/components/ChatInput.vue'
import { parseMessage } from '@/utils/text.helper'
import { useUserStore } from '@/stores/userStore'
import { _ElConfirm, _ElMessage } from '@/utils/element-plus-wrapper'
import { UserRole } from '@/types/user.type'
import { MoreFilled } from '@element-plus/icons-vue'
import { datetimeFormatter } from '@/utils/date.formatter'
import { downloadFile } from '@/utils/file.helper'

const props = defineProps<{
  caseItemId: number
}>()
const emit = defineEmits<{
  (e: 'close'): void
}>()

const caseItemStore = useCaseItemStore()
const userStore = useUserStore()
function init() {
  caseItemStore.clear_current_case_item()
  caseItemStore
    .get_case_item_detail(props.caseItemId)
    .then(() => scrollToBottomMessage())
    .catch(() => {})
}
init()

watch(
  () => props.caseItemId,
  () => init()
)

const caseItemData = computed(() => caseItemStore.current_case_item)

const isDownloading = ref(false)
async function downloadMessageAttachment(
  caseItemId: number,
  caseItemMessageId: number,
  attachmentId: number,
  fileExtension: string
) {
  try {
    isDownloading.value = true
    const { data, filename } = await caseItemStore.download_attachment(
      caseItemId,
      caseItemMessageId,
      attachmentId,
      fileExtension
    )
    downloadFile(data, filename)
  } catch (e) {
    console.error(e)
    _ElMessage({
      type: 'error',
      message: 'ファイルのダウンロードに失敗しました'
    })
  } finally {
    isDownloading.value = false
  }
}
function close() {
  caseItemStore.clear_current_case_item()
  emit('close')
}
function onClickMessageDelete(messageId: number) {
  _ElConfirm('この処理は取り消せません。実行しますか？', 'メッセージの削除', {
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル'
  })
    .then(() => {
      if (!caseItemData.value) {
        _ElMessage({
          type: 'error',
          message: 'メッセージの削除に失敗しました'
        })
        return
      }
      caseItemStore.delete_message(caseItemData.value.id, messageId)
    })
    .catch((e) => {
      console.error(e)
    })
}
function onClickAttachmentClose(messageId: number, attachmentId: number) {
  _ElConfirm('この処理は取り消せません。実行しますか？', '添付ファイルの削除', {
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル'
  })
    .then(() => {
      if (!caseItemData.value) {
        _ElMessage({
          type: 'error',
          message: '添付ファイルの削除に失敗しました'
        })
        return
      }
      caseItemStore.delete_attachment(caseItemData.value.id, messageId, attachmentId)
    })
    .catch((e) => {
      console.error(e)
    })
}
function canDeleteMessageAndAttachment(message: CaseItemMessageDetail) {
  if (!userStore.current_user) {
    return false
  }
  if (userStore.current_user.role === UserRole.admin) {
    return true
  }
  return isCurrentUserTheAuthor(message)
}
function isCurrentUserTheAuthor(message: CaseItemMessageDetail) {
  if (!userStore.current_user) {
    return false
  }
  return message.author.id === userStore.current_user.id
}

// chatinput
const chatInput = ref<InstanceType<typeof ChatInput> | null>(null)
async function handleSend(
  chatInputValue: string,
  attachedFiles: File[],
  notifyCaseItemAuthor: boolean
) {
  if (!chatInput.value) {
    return
  }
  const { caseItemId } = props
  const createCaseItemMessagePayload = {
    text: chatInputValue,
    notifyCaseItemAuthor: notifyCaseItemAuthor
  }

  const hasFiles = attachedFiles.length > 0
  chatInput.value.makeLoading(true)
  const res = await caseItemStore
    .send_message(caseItemId, createCaseItemMessagePayload)
    .catch(() => {
      _ElMessage({
        message: 'メッセージの送信に失敗しました',
        type: 'error'
      })
    })

  if (!res) {
    // テキスト送信のエラー時はメッセージをクリアせずreturn
    chatInput.value.makeLoading(false)
    return
  }

  if (!hasFiles) {
    chatInput.value.makeLoading(false)
    clearInputAndScroll()
    _ElMessage({
      message: 'メッセージを送信しました',
      type: 'success'
    })
    return
  }

  await Promise.allSettled(
    attachedFiles.map((file) => {
      return caseItemStore.add_attachment(caseItemId, res.id, file)
    })
  ).then((results) => {
    const failedFiles = results
      .filter((result) => result.status === 'rejected')
      .map((_result, index) => attachedFiles[index].name)

    if (failedFiles.length === 0) {
      _ElMessage({
        message: 'メッセージを送信しました',
        type: 'success'
      })
    } else {
      const failedFilesMessage = failedFiles.join(', ')
      _ElMessage({
        message: `ファイルのアップロードに失敗しました: ${failedFilesMessage}`,
        type: 'error'
      })
    }
  })

  chatInput.value.makeLoading(false)
  clearInputAndScroll()
}
function clearInputAndScroll() {
  if (chatInput.value) {
    chatInput.value.clearInput()
    chatInput.value.clearFiles()
    scrollToBottomMessage()
  }
}
function scrollToBottomMessage() {
  // 1番下までscrollする
  const wrapperDivs = document.querySelectorAll('.case-chat-body-message-container')
  if (wrapperDivs.length) {
    wrapperDivs[0].scrollTop = wrapperDivs[0].scrollHeight
  }
}
</script>
<template>
  <div class="case-chat">
    <div v-if="!caseItemData" class="loading"><div class="loading-text">Loading...</div></div>
    <template v-else>
      <div class="case-chat-header">
        <div class="case-chat-header-title">
          {{ CaseItemTypeLabel[caseItemData.caseItemType] }}
          <span v-if="caseItemData.caseItemStatus == 'closed'" class="status-closed">[Closed]</span>
        </div>
        <div>
          <CircleCloseFilled style="width: 1em; height: 1em; cursor: pointer" @click="close()" />
        </div>
      </div>
      <div class="case-chat-body">
        <div class="messages-container">
          <div
            v-for="m in caseItemData.messages"
            :key="m.id"
            class="message-item"
            :class="isCurrentUserTheAuthor(m) ? 'mine' : 'others'"
          >
            <div class="message-header">
              <div class="author-name">
                <template v-if="isCurrentUserTheAuthor(m)"> あなた </template>
                <template v-else-if="m.author">
                  {{ `【${m.author.userGroup.name}】${m.author.name}` }}
                </template>
                <template v-else> 不明なユーザ </template>
              </div>
              <el-popover
                placement="top-start"
                :width="150"
                trigger="hover"
                content="メッセージの削除"
                v-if="canDeleteMessageAndAttachment(m)"
              >
                <template #reference>
                  <el-button
                    link
                    :icon="MoreFilled"
                    style="font-size: 16px"
                    @click="() => onClickMessageDelete(m.id)"
                  />
                </template>
              </el-popover>
            </div>
            <div
              class="message-text"
              :class="isCurrentUserTheAuthor(m) ? 'mine' : 'others'"
              v-html="parseMessage(m.text)"
            />
            <div v-if="m.caseItemMessageAttachments.length" class="message-files-container">
              <div v-for="attachment in m.caseItemMessageAttachments" :key="attachment.id">
                <el-tag
                  type="info"
                  :closable="canDeleteMessageAndAttachment(m)"
                  @close="() => onClickAttachmentClose(m.id, attachment.id)"
                >
                  <el-button
                    :loading="isDownloading"
                    link
                    type="primary"
                    @click="
                      downloadMessageAttachment(
                        caseItemData.id,
                        m.id,
                        attachment.id,
                        attachment.fileExtension
                      )
                    "
                  >
                    <div class="file-name">
                      <svg
                        fill="currentColor"
                        width="16px"
                        height="16px"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.1,12.4l-6.2,6.2c-1.7,1.7-4.4,1.7-6,0c-1.7-1.7-1.7-4.4,0-6l8-8c1-0.9,2.5-0.9,3.5,0c1,1,1,2.6,0,3.5l-6.9,6.9c-0.3,0.3-0.8,0.3-1.1,0c0,0,0,0,0,0c-0.3-0.3-0.3-0.8,0-1.1l5.1-5.1c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L8,12.6c-1.1,1.1-1.1,2.8,0,3.9c1.1,1,2.8,1,3.9,0l6.9-6.9c1.8-1.8,1.8-4.6,0-6.4c-1.8-1.8-4.6-1.8-6.4,0l-8,8c-1.2,1.2-1.8,2.8-1.8,4.4c0,3.5,2.8,6.2,6.3,6.2c1.7,0,3.2-0.7,4.4-1.8l6.2-6.2c0.4-0.4,0.4-1,0-1.4S18.5,12,18.1,12.4z"
                        />
                      </svg>
                      {{ attachment.fileName || 'ファイル名なし' }}.{{
                        attachment.fileExtension || '拡張子なし'
                      }}
                    </div>
                  </el-button>
                </el-tag>
              </div>
            </div>
            <div class="created-at">
              {{ datetimeFormatter(m.createdAt) }}
            </div>
          </div>
        </div>
        <div v-if="caseItemData.caseItemStatus == 'closed'" class="case-chat-input closed">
          以降、メッセージ送信はできません。
        </div>
        <div v-else class="case-chat-input">
          <ChatInput
            ref="chatInput"
            :enable-files-option="true"
            :enableNotifyOption="false"
            @send="handleSend"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<style scoped>
.case-chat {
  position: fixed;
  bottom: 12px;
  right: 12px;
  height: 50%;
  width: 360px;
  background-color: v-bind('colors.bg.white');
  z-index: 1000;
  box-shadow: v-bind('colors.shadow.card');
  border-radius: 8px;
}
.case-chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  line-height: 32px;
  padding-left: 12px;
  padding-right: 8px;
  background-color: v-bind('colors.utility.yellow');
  color: v-bind('colors.text.white');
  .case-chat-header-title {
    font-size: 14px;
    font-weight: bold;
  }
  .status-closed {
    margin-left: 4px;
    font-size: 14px;
    font-weight: bold;
  }
}
.case-chat-body {
  height: calc(100% - 32px);
  padding: 0 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.messages-container {
  overflow-y: scroll;
  padding-top: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.message-item {
  width: 90%;
  display: flex;
  flex-direction: column;
  &.mine {
    align-items: flex-end;
  }
  &.others {
    align-items: flex-start;
  }
}
.message-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 22px;
  line-height: 22px;
  .author-name {
    font-weight: bold;
    font-size: 12px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.message-text {
  margin-top: 2px;
  padding: 8px;
  border: 1px solid v-bind('colors.border.base');
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  white-space: pre-wrap;
  width: 100%;
  &.mine {
    background-color: v-bind('colors.utility.greenBg');
  }
  &.others {
    background-color: v-bind('colors.bg.gray01');
  }
}
.created-at {
  margin-top: 2px;
  font-size: 12px;
  color: v-bind('colors.text.disabled');
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.message-files-container {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  .file-name {
    max-width: 272px;

    display: flex;
    gap: 2px;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: v-bind('colors.text.lighter');
    svg {
      flex-shrink: 0;
    }
  }
  .el-button:focus {
    color: inherit;
    background-color: inherit;
  }
}
.case-chat-input {
  width: 100%;

  &.closed {
    font-size: 14px;
    font-weight: bold;
    color: v-bind('colors.text.disabled');
    cursor: not-allowed;
  }
}

/* loading */
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-text {
  font-size: 14px;
  font-weight: bold;
  color: v-bind('colors.text.secondary');
}
</style>
