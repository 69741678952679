<script setup lang="ts">
import { computed, ref } from 'vue'
import { colorPalette as colors } from '@/utils/enums'
import { useCategoryStore } from '@/stores/categoryStore'
import {
  GroupLabel,
  GroupLabelLabel,
  type CreateTopCategoryPayload
} from '@/types/topCategory.type'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/userStore'

const router = useRouter()
const categoryStore = useCategoryStore()
const userStore = useUserStore()
categoryStore.get_top_categories()
const bizCategories = computed(() =>
  categoryStore.top_categories.filter((e) => e.groupLabel == 'biz')
)
const deptCategories = computed(() =>
  categoryStore.top_categories.filter((e) => e.groupLabel == 'department')
)

const isAdmin = computed(() => userStore.get_current_user.role === 'admin')

// create
let isCreateMode = ref(false)
let dialogCreateData = ref<CreateTopCategoryPayload>({
  name: '',
  groupLabel: GroupLabel.biz,
  shouldSkipSelectObjective: false
})
const isSubmitting = ref(false)
function openCreateDialog(groupLabel: GroupLabel) {
  dialogCreateData.value = {
    name: '',
    groupLabel: groupLabel,
    shouldSkipSelectObjective: groupLabel == 'biz' ? false : true
  }
  isCreateMode.value = true
}
function submitCreate() {
  if (!dialogCreateData.value.name.length) {
    _ElMessage({ type: 'error', message: '名前を入力してください' })
    return
  }
  isSubmitting.value = true
  categoryStore
    .create_top_category(dialogCreateData.value)
    .then(() => {
      isCreateMode.value = false
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}

// transit
function openDetailPage(topCategoryId: number) {
  router.push('/catalogs?menu=categoryList&resourceId=' + topCategoryId)
}
</script>

<template>
  <div id="top-category-list">
    <!-- dialog -->
    <el-dialog
      :close-on-press-escape="false"
      v-model="isCreateMode"
      top="10vh"
      width="50%"
      class="ix-dialog"
    >
      <template #header>{{ GroupLabelLabel[dialogCreateData.groupLabel] }}の新規登録</template>
      <el-form :model="dialogCreateData" label-position="left" label-width="120px" @submit.prevent>
        <el-form-item label="名前">
          <el-input v-model="dialogCreateData.name" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span>
          <el-button link @click="isCreateMode = false">閉じる</el-button>
          <el-button :loading="isSubmitting" @click="submitCreate">保存する</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- template -->
    <div class="subheader">
      <div class="subheader-title">{{ GroupLabelLabel.biz }}</div>
      <div v-if="isAdmin" class="subheader-action">
        <el-button @click="openCreateDialog('biz')">新規登録</el-button>
      </div>
    </div>
    <div class="ix-table">
      <div
        class="ix-table-row clickable"
        v-for="(row, i) in bizCategories"
        :key="row.id"
        @click="openDetailPage(row.id)"
      >
        <div class="ix-table-column th">#{{ i + 1 }}</div>
        <div class="ix-table-column">{{ row.name }}</div>
      </div>
      <div v-if="!bizCategories.length" class="row-placeholder">データ未登録です</div>
    </div>
    <el-divider />
    <div class="subheader">
      <div class="subheader-title">{{ GroupLabelLabel.department }}</div>
      <div v-if="isAdmin" class="subheader-action">
        <el-button @click="openCreateDialog('department')">新規登録</el-button>
      </div>
    </div>
    <div class="ix-table">
      <div
        class="ix-table-row clickable"
        v-for="(row, i) in deptCategories"
        :key="row.id"
        @click="openDetailPage(row.id)"
      >
        <div class="ix-table-column th">#{{ i + 1 }}</div>
        <div class="ix-table-column">{{ row.name }}</div>
      </div>
      <div v-if="!deptCategories.length" class="row-placeholder">データ未登録です</div>
    </div>
  </div>
</template>
<style scoped>
#top-category-list {
  padding-right: 24px;
  height: 100%;
  color: v-bind('colors.text.base');
}
.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.subheader-title {
  font-size: 16px;
  font-weight: bold;
  height: 32px;
  line-height: 32px;
}
.ix-table-row {
  gap: 20px;
  padding-left: 16px;
}
.row-placeholder {
  padding-left: 16px;
  font-size: 12px;
  color: v-bind('colors.text.disabled');
}
</style>
