<script setup lang="ts">
import { computed, ref } from 'vue'
import { useUserStore } from '@/stores/userStore'
import { colorPalette as colors } from '@/utils/enums'
import { Edit } from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'
import { watch } from 'vue'
import { onMounted } from 'vue'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import { passwordRules, passwordValidator } from '@/utils/password_validator'
export type MyAccountEditTarget = 'name' | 'password'

const props = defineProps<{
  edit?: MyAccountEditTarget
}>()
const userStore = useUserStore()
const router = useRouter()

const currentUserTableData = computed(() => {
  const nameLabel =
    userStore.get_current_user.role === 'admin' || userStore.get_current_user.role === 'supervisor'
      ? `${userStore.get_current_user.name} (${userStore.get_current_user.role})`
      : userStore.get_current_user.name

  return [
    {
      keyLabel: 'UserGroup',
      label: userStore.get_current_user.userGroup.name,
      value: userStore.get_current_user.userGroup.name
    },
    {
      keyLabel: 'Name',
      label: nameLabel,
      value: userStore.get_current_user.name
    },
    {
      keyLabel: 'Email',
      label: userStore.get_current_user.email,
      value: userStore.get_current_user.email
    },
    {
      keyLabel: 'Password',
      label: '********',
      value: '********'
    }
  ]
})

// password handling
const isClearedDummyPw = ref(false)
const pwPlaceholder = '**************'

const passwordCaptionClass = computed(() => {
  let str = 'caption'
  if (
    editData.value.password &&
    editData.value.password !== '' &&
    editData.value.password !== pwPlaceholder
  ) {
    if (passwordValidator(editData.value.password)) {
      str += ' success'
    } else {
      str += ' error'
    }
  }
  return str
})
function clearDummyPw() {
  if (!isClearedDummyPw.value) {
    editData.value.password = ''
    isClearedDummyPw.value = true
  }
}
function fillDummyPw() {
  if (editData.value.password == '') {
    editData.value.password = pwPlaceholder
    isClearedDummyPw.value = false
  }
}
const hasChangedPw = computed(() => {
  // 誤ってdummyで更新しないように
  if (isClearedDummyPw.value && editData.value.password !== '') {
    return true
  } else {
    return false
  }
})

// edit
const editData = ref({
  id: -1,
  name: '',
  email: '',
  password: ''
})
function openEditPage(keyLabel: string) {
  const editTarget = (() => {
    switch (keyLabel) {
      case 'Name':
        return 'name'
      case 'Password':
        return 'password'
      default:
        throw new Error('invalid keyLabel')
    }
  })()
  router.push('/settings?menu=myAccount&edit=' + editTarget)
}
onMounted(() => {
  startEdit()
})
watch(
  () => props.edit,
  () => {
    startEdit()
  }
)
function startEdit() {
  if (props.edit == 'name' || props.edit == 'password') {
    const user = userStore.get_current_user
    editData.value = {
      id: user.id,
      name: user.name,
      email: user.email,
      password: pwPlaceholder // dummy
    }
    isClearedDummyPw.value = false
  } else {
    cancelEdit()
  }
}
function cancelEdit() {
  router.push('/settings?menu=myAccount')
}
function submitEdit() {
  if (editData.value.id == -1) {
    return
  }
  if (props.edit == 'name') {
    if (!editData.value.name.length) {
      _ElMessage({ type: 'success', message: 'Nameを入力してください' })
      return
    }
    userStore
      .update_user_name({
        id: editData.value.id,
        name: editData.value.name
      })
      .then(() => {
        cancelEdit()
      })
      .catch(() => {
        //
      })
  } else if (props.edit == 'password') {
    if (hasChangedPw.value) {
      if (!passwordValidator(editData.value.password)) {
        _ElMessage({
          type: 'error',
          message: 'Passwordが規則を満たしていないようです'
        })
        return
      }
      userStore
        .update_user_password({
          id: editData.value.id,
          password: editData.value.password
        })
        .then(() => {
          cancelEdit()
        })
        .catch(() => {
          //
        })
    } else {
      // スルー
      cancelEdit()
    }
  } else {
    return
  }
}
</script>

<template>
  <div id="my-account">
    <el-card v-if="!edit">
      <div class="ix-table">
        <div class="ix-table-row" v-for="row in currentUserTableData" :key="row.keyLabel">
          <div class="ix-table-column th">
            {{ row.keyLabel }}
          </div>
          <div class="ix-table-column">
            {{ row.label }}
            <Edit
              v-if="row.keyLabel == 'Name' || row.keyLabel == 'Password'"
              style="width: 16px; height: 16px; margin-top: 2px; cursor: pointer"
              @click="openEditPage(row.keyLabel)"
            />
          </div>
        </div>
      </div>
    </el-card>
    <div v-else class="edit-area">
      <div class="edit-item">
        <div class="edit-title">Name</div>
        <el-input v-model="editData.name" :disabled="edit !== 'name'" />
      </div>
      <div class="edit-item">
        <div class="edit-title">Email</div>
        <el-input v-model="editData.email" disabled />
      </div>
      <div class="edit-item">
        <div class="edit-title">Password</div>
        <el-input
          v-model="editData.password"
          :disabled="edit !== 'password'"
          show-password
          @focus="clearDummyPw"
          @blur="fillDummyPw"
        />
        <span :class="passwordCaptionClass">{{ passwordRules }}</span>
      </div>
      <div class="actions">
        <el-button link @click="cancelEdit()">キャンセル</el-button>
        <el-button @click="submitEdit()">保存する</el-button>
      </div>
    </div>
  </div>
</template>
<style scoped>
#my-account {
  width: 60%;
  height: 100%;
  padding-top: 12px;
}
.el-card {
  color: v-bind('colors.text.base');
  background-color: v-bind('colors.bg.gray02');
  border: none;
}
.ix-table-column {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edit-item {
  margin-bottom: 24px;
}
.edit-title {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;
  padding-left: 4px;
}
.caption {
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  color: v-bind('colors.text.lighter');
  margin-top: 4px;
  padding-left: 6px;
}
.caption.error {
  color: v-bind('colors.utility.red');
  font-weight: bold;
}
.caption.success {
  color: v-bind('colors.utility.green');
  font-weight: bold;
}
.actions {
  margin-top: 48px;
}
</style>
