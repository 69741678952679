<script setup lang="ts">
import { computed, ref } from 'vue'
import { colorPalette as colors } from '@/utils/enums'
import { useCategoryStore } from '@/stores/categoryStore'
import {
  GroupLabelLabel,
  GroupLabel,
  type UpdateTopCategoryPayload
} from '@/types/topCategory.type'
import { _ElConfirm, _ElMessage } from '@/utils/element-plus-wrapper'
import { useRouter } from 'vue-router'
import type {
  CreateObjectivePayload,
  ObjectiveDetail,
  UpdateObjectivePayload
} from '@/types/objective.type'
import TobeImagesContent from './TobeImagesContent.vue'
import { downloadFile, validateFile } from '@/utils/file.helper'
import type { UploadFile } from 'element-plus'
import { Check, Warning } from '@element-plus/icons-vue'
import { AsisFileExtensionForDownload } from '@/types/asisImage.type'
import { useSortable } from '@vueuse/integrations/useSortable'
import { useUserStore } from '@/stores/userStore'

const props = defineProps<{
  resourceId: string
}>()
const router = useRouter()
const categoryStore = useCategoryStore()
const userStore = useUserStore()
const topCategoryId = (() => {
  if (props.resourceId == '' || isNaN(Number(props.resourceId))) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    router.push('/catalogs?menu=categoryList')
    return -1
  } else {
    return Number(props.resourceId)
  }
})()

const isAdmin = computed(() => userStore.get_current_user.role === 'admin')

if (topCategoryId !== -1) {
  categoryStore.get_top_category_detail(topCategoryId)
}
const topCategoryData = computed(() => categoryStore.current_top_category)
const sortedObjectives = computed(() => {
  if (topCategoryData.value) {
    return [...topCategoryData.value.objectives].sort((a, b) => a.sortNumber - b.sortNumber)
  } else {
    return []
  }
})
const tobeImages = computed(() => {
  if (topCategoryData.value) {
    return topCategoryData.value.tobeImages
  } else {
    return []
  }
})
const isSubmitting = ref(false)

// TopCategory
const isLoading = ref(false)
const isUpdateTopCategoryDialog = ref(false)
const dialogUpdateTopCategoryData = ref<UpdateTopCategoryPayload>({
  id: -1,
  name: '',
  groupLabel: 'biz',
  shouldSkipSelectObjective: false
})
function openUpdateTopCategoryDialog() {
  if (!topCategoryData.value) {
    return
  }
  dialogUpdateTopCategoryData.value = {
    id: topCategoryData.value.id,
    name: topCategoryData.value.name,
    groupLabel: topCategoryData.value.groupLabel,
    shouldSkipSelectObjective: topCategoryData.value.shouldSkipSelectObjective
  }
  isUpdateTopCategoryDialog.value = true
}
function submitUpdateTopCategory() {
  if (dialogUpdateTopCategoryData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  if (!dialogUpdateTopCategoryData.value.name.length) {
    _ElMessage({ type: 'error', message: '名前を入力してください' })
    return
  }
  switch (dialogUpdateTopCategoryData.value.groupLabel) {
    case 'biz':
      dialogUpdateTopCategoryData.value.shouldSkipSelectObjective = false
      break
    case 'department':
      dialogUpdateTopCategoryData.value.shouldSkipSelectObjective = true
      break
  }
  isSubmitting.value = true
  categoryStore
    .update_top_category(dialogUpdateTopCategoryData.value)
    .then(() => {
      isUpdateTopCategoryDialog.value = false
    })
    .catch((error) => {
      console.error(error)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}
function confirmDeleteTopCategory() {
  if (!topCategoryData.value) {
    return
  }
  _ElConfirm(
    'この処理は取り消せません。実行しますか？',
    `${GroupLabelLabel[topCategoryData.value.groupLabel]}の削除`,
    {
      confirmButtonText: '削除する',
      cancelButtonText: 'キャンセル'
    }
  )
    .then(() => {
      categoryStore
        .delete_top_category(topCategoryId)
        .then(() => {
          router.push('/catalogs?menu=categoryList')
        })
        .catch(() => {})
    })
    .catch(() => {})
}

// AsisImage
const isAsisImageDialog = ref(false)
const asisPptx = computed(() => {
  if (topCategoryData.value) {
    return topCategoryData.value.asisImage.asisPptx
      ? topCategoryData.value.asisImage.asisPptx
      : null
  } else {
    return null
  }
})
const asisPng = computed(() => {
  if (topCategoryData.value) {
    return topCategoryData.value.asisImage.asisPng ? topCategoryData.value.asisImage.asisPng : null
  } else {
    return null
  }
})

async function submitAsisPptx(file: UploadFile) {
  if (topCategoryId === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  const rawFile = validateFile(file, Object.keys(AsisFileExtensionForDownload))
  if (!rawFile) {
    isLoading.value = false
    return
  }
  isLoading.value = true

  categoryStore
    .upload_asis_image(topCategoryId, rawFile)
    .catch(() => {})
    .finally(() => {
      isLoading.value = false
    })
}
async function submitAsisPng(file: UploadFile) {
  if (topCategoryId === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  const rawFile = validateFile(file, ['png'])
  if (!rawFile) {
    isLoading.value = false
    return
  }
  isLoading.value = true

  categoryStore.upload_asis_image(topCategoryId, rawFile).finally(() => {
    isLoading.value = false
  })
}
function getAsisImageUrl(topCategoryId: number, fileId: number) {
  return categoryStore.get_asis_image_url(topCategoryId, fileId, 'png')
}
async function downloadCurrentAsisPptx() {
  if (!asisPptx.value || topCategoryId === -1) {
    return
  }
  try {
    const { data, filename } = await categoryStore.download_asis_image(
      topCategoryId,
      asisPptx.value.id,
      asisPptx.value.fileExtension
    )
    console.log(filename)
    downloadFile(data, filename)
  } catch (error) {
    console.error('ファイルダウンロードエラー:', error)
  }
}

// Objective
const isCreateObjectiveDialog = ref(false)
const isUpdateObjectiveDialog = ref(false)
const dialogCreateObjectiveData = ref<CreateObjectivePayload>({
  topCategoryId: topCategoryId,
  title: '',
  sortNumber: 0
})
const dialogTargetTobeImageId = ref({ before: -1, after: -1 })
const dialogUpdateObjectiveData = ref<UpdateObjectivePayload>({
  id: -1,
  title: '',
  sortNumber: 0
})
function openCreateObjectiveDialog() {
  if (sortedObjectives.value.length >= 1 && topCategoryData.value?.shouldSkipSelectObjective) {
    _ElMessage({
      type: 'error',
      message: 'スキップ設定されているため、主目的は1つだけしか登録できません'
    })
    return
  }

  dialogCreateObjectiveData.value = {
    topCategoryId: topCategoryId,
    title: '',
    sortNumber: sortedObjectives.value.length + 1 // 最後尾に入れる
  }
  isCreateObjectiveDialog.value = true
}
function submitCreateObjective() {
  if (dialogCreateObjectiveData.value.topCategoryId === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  if (!dialogCreateObjectiveData.value.title.length) {
    _ElMessage({ type: 'error', message: 'ラベルを入力してください' })
    return
  }
  isSubmitting.value = true
  categoryStore
    .create_objective(dialogCreateObjectiveData.value)
    .then(() => {
      isCreateObjectiveDialog.value = false
    })
    .catch((error) => {
      console.error(error)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}
function openUpdateObjectiveDialog(data: ObjectiveDetail) {
  dialogUpdateObjectiveData.value = {
    id: data.id,
    title: data.title,
    sortNumber: data.sortNumber
  }
  dialogTargetTobeImageId.value = {
    before: data.targetTobeImageId ? data.targetTobeImageId : -1,
    after: data.targetTobeImageId ? data.targetTobeImageId : -1
  }
  isUpdateObjectiveDialog.value = true
}
async function submitUpdateObjective() {
  if (dialogUpdateObjectiveData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  if (!dialogUpdateObjectiveData.value.title.length) {
    _ElMessage({ type: 'error', message: 'ラベルを入力してください' })
    return
  }
  try {
    isSubmitting.value = true
    await categoryStore.update_objective(dialogUpdateObjectiveData.value).then(() => {
      isUpdateObjectiveDialog.value = false
    })

    // TobeImageの設定
    if (dialogTargetTobeImageId.value.before !== dialogTargetTobeImageId.value.after) {
      if (dialogTargetTobeImageId.value.after !== -1) {
        categoryStore.add_tobe_image_to_objective(
          dialogUpdateObjectiveData.value.id,
          dialogTargetTobeImageId.value.after
        )
      } else {
        categoryStore.remove_tobe_image_from_objective(
          dialogUpdateObjectiveData.value.id,
          dialogTargetTobeImageId.value.before
        )
      }
    }
  } catch (e) {
    console.error(e)
  } finally {
    isSubmitting.value = false
  }
}
function confirmDeleteObjective() {
  if (!dialogUpdateObjectiveData.value.id) {
    return
  }
  _ElConfirm('この処理は取り消せません。実行しますか？', `主目的の削除`, {
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル'
  })
    .then(() => {
      categoryStore
        .delete_objective(dialogUpdateObjectiveData.value.id)
        .then(() => {
          isUpdateObjectiveDialog.value = false
        })
        .catch(() => {})
    })
    .catch(() => {})
}
function getTobeImageUrl(tobeImageId: number, fileId: number) {
  return categoryStore.get_tobe_image_url(tobeImageId, fileId, 'png')
}
function selectTargetTobeImage(tobeImageId: number) {
  if (dialogTargetTobeImageId.value.after === tobeImageId) {
    dialogTargetTobeImageId.value.after = -1
  } else {
    dialogTargetTobeImageId.value.after = tobeImageId
  }
}

// sort objectives
const isObjectivesSortMode = ref(false)
const sortObjectivesdiv = ref<HTMLElement | null>(null)
const sortTargetObjectives = ref<ObjectiveDetail[]>([])
function startSortObjectives() {
  if (sortedObjectives.value.length === 0) {
    _ElMessage({ type: 'error', message: '対象の主目的がありません' })
    return
  }
  sortTargetObjectives.value = [...sortedObjectives.value]
  useSortable(sortObjectivesdiv, sortTargetObjectives, { animation: 150 })
  isObjectivesSortMode.value = true
}
function submitSortObjectives() {
  if (topCategoryId === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  if (sortTargetObjectives.value.length === 0) {
    _ElMessage({ type: 'error', message: '対象の主目的がありません' })
    return
  }
  isSubmitting.value = true
  categoryStore
    .sort_objectives({
      topCategoryId,
      objectiveIdsInOrder: sortTargetObjectives.value.map((o) => o.id)
    })
    .then(() => {
      isObjectivesSortMode.value = false
    })
    .catch((error) => {
      console.error(error)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}
</script>

<template>
  <div id="top-category">
    <!-- dialog for TopCategory-->
    <template v-if="true">
      <el-dialog
        :close-on-press-escape="false"
        v-model="isUpdateTopCategoryDialog"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header
          >{{ GroupLabelLabel[dialogUpdateTopCategoryData.groupLabel] }}の編集</template
        >
        <el-form
          :model="dialogUpdateTopCategoryData"
          label-position="left"
          label-width="120px"
          @submit.prevent
        >
          <el-form-item label="分類">
            <el-radio-group v-model="dialogUpdateTopCategoryData.groupLabel">
              <el-radio :value="GroupLabel.biz">{{ GroupLabelLabel['biz'] }}</el-radio>
              <el-radio :value="GroupLabel.department">{{
                GroupLabelLabel['department']
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="ラベル">
            <el-input v-model="dialogUpdateTopCategoryData.name" />
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="el-dialog-footer_both_side">
            <span>
              <el-button link class="danger" @click="confirmDeleteTopCategory()"
                >削除する</el-button
              >
            </span>
            <span>
              <el-button link @click="isUpdateTopCategoryDialog = false">閉じる</el-button>
              <el-button :loading="isSubmitting" @click="submitUpdateTopCategory"
                >保存する</el-button
              >
            </span>
          </div>
        </template>
      </el-dialog>
    </template>

    <!-- dialog for AsisImage -->
    <template v-if="true">
      <el-dialog
        :close-on-press-escape="false"
        v-model="isAsisImageDialog"
        top="10vh"
        width="90%"
        class="ix-dialog"
      >
        <template #header>AsIsイメージの編集</template>
        <div class="asis-image-dialog-content">
          <div class="asis-image-dialog-img-wrapper">
            <div v-if="topCategoryId && asisPng" class="asis-image-enlarged">
              <img
                :src="getAsisImageUrl(topCategoryId, asisPng.id)"
                crossOrigin="use-credentials"
              />
            </div>
            <div v-else class="asis-image placeholder">PNG未登録</div>
          </div>
          <div class="asis-image-dialog-form-wrapper">
            <el-form label-position="top" label-width="120px" @submit.prevent>
              <el-form-item label="Web表示用PNG (推奨アスペクト比16:9)">
                <el-upload
                  class="upload"
                  v-loading.fullscreen="isLoading"
                  action=""
                  :auto-upload="false"
                  :show-file-list="false"
                  :on-change="submitAsisPng"
                >
                  <template #default>
                    <el-button>{{ asisPng ? 'ファイル差し替え' : 'ファイル登録' }}</el-button>
                  </template>
                </el-upload>
              </el-form-item>
              <el-form-item :label="`DL用ファイル（${Object.keys(AsisFileExtensionForDownload)}）`">
                <div
                  v-if="asisPptx"
                  class="asis-pptx-label download"
                  @click="downloadCurrentAsisPptx"
                >
                  {{ decodeURI(asisPptx.originalFileName) }}.{{ asisPptx.fileExtension }}
                </div>
                <div>
                  <el-upload
                    class="upload"
                    v-loading.fullscreen="isLoading"
                    action=""
                    :auto-upload="false"
                    :show-file-list="false"
                    :on-change="submitAsisPptx"
                  >
                    <template #default>
                      <el-button>{{ asisPptx ? 'ファイル差し替え' : 'ファイル登録' }}</el-button>
                    </template>
                  </el-upload>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <template #footer>
          <span>
            <el-button link @click="isAsisImageDialog = false">閉じる</el-button>
          </span>
        </template>
      </el-dialog>
    </template>

    <!-- dialog for Objective-->
    <template v-if="true">
      <el-dialog
        :close-on-press-escape="false"
        v-model="isCreateObjectiveDialog"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header>主目的の新規登録</template>
        <el-form
          :model="dialogCreateObjectiveData"
          label-position="left"
          label-width="120px"
          @submit.prevent
        >
          <el-form-item label="ラベル">
            <el-input v-model="dialogCreateObjectiveData.title" />
          </el-form-item>
        </el-form>
        <template #footer>
          <span>
            <el-button link @click="isCreateObjectiveDialog = false">閉じる</el-button>
            <el-button :loading="isSubmitting" @click="submitCreateObjective">保存する</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog
        :close-on-press-escape="false"
        v-model="isUpdateObjectiveDialog"
        top="10vh"
        width="90%"
        class="ix-dialog"
      >
        <template #header>主目的の編集</template>
        <el-form
          :model="dialogUpdateObjectiveData"
          label-position="left"
          label-width="120px"
          @submit.prevent
        >
          <el-form-item label="ラベル">
            <el-input v-model="dialogUpdateObjectiveData.title" />
          </el-form-item>
          <el-form-item label="ToBeイメージ">
            <div class="tobe-images-wrapper">
              <div
                v-for="tobeImage in tobeImages"
                :key="tobeImage.id"
                :class="
                  dialogTargetTobeImageId.after == tobeImage.id
                    ? 'tobe-image-container current'
                    : 'tobe-image-container'
                "
                @click="selectTargetTobeImage(tobeImage.id)"
              >
                <div v-if="tobeImage.tobePng" class="tobe-image">
                  <img
                    :src="getTobeImageUrl(tobeImage.id, tobeImage.tobePng.id)"
                    crossOrigin="use-credentials"
                  />
                </div>
                <div v-else class="tobe-image placeholder">PNG未登録</div>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="el-dialog-footer_both_side">
            <span>
              <el-button link class="danger" @click="confirmDeleteObjective()">削除する</el-button>
            </span>
            <span>
              <el-button link @click="isUpdateObjectiveDialog = false">閉じる</el-button>
              <el-button :loading="isSubmitting" @click="submitUpdateObjective">保存する</el-button>
            </span>
          </div>
        </template>
      </el-dialog>
    </template>

    <!-- template -->
    <template v-if="!topCategoryData"> Now Loading... </template>
    <template v-else>
      <div class="topheader">
        <div class="topheader-title">
          [{{ GroupLabelLabel[topCategoryData.groupLabel] }}] {{ topCategoryData.name }}
        </div>
        <div v-if="isAdmin" class="topheader-action">
          <el-button @click="openUpdateTopCategoryDialog()">編集する</el-button>
        </div>
      </div>
      <div class="asis-and-objective-wrapper">
        <div class="asis-image-container">
          <div class="subheader">
            <div class="subheader-title">AsIsイメージ</div>
            <div class="subheader-action"></div>
          </div>
          <div class="asis-image-wrapper" @click="isAsisImageDialog = isAdmin">
            <div v-if="asisPng" class="asis-image">
              <img
                :src="getAsisImageUrl(topCategoryData.id, asisPng.id)"
                crossOrigin="use-credentials"
              />
            </div>
            <div v-else class="asis-image placeholder">PNG未登録</div>
          </div>
          <div v-if="asisPptx" class="asis-pptx-label" @click="isAsisImageDialog = isAdmin">
            <Check style="width: 16px; height: 16px; margin-top: 2px" />
            DL用ファイル登録済み
          </div>
          <div v-else class="asis-pptx-label danger" @click="isAsisImageDialog = isAdmin">
            <Warning style="width: 16px; height: 16px; margin-top: 2px" />
            DL用ファイル未登録
          </div>
        </div>
        <div class="objecitives-container">
          <div class="subheader">
            <div class="subheader-title">
              主目的リスト
              <span v-if="topCategoryData.shouldSkipSelectObjective" class="caption">
                （スキップ設定）1つだけ登録してください
              </span>
            </div>
            <div v-if="isAdmin" class="subheader-action">
              <el-button v-show="isObjectivesSortMode" @click="isObjectivesSortMode = false"
                >キャンセル</el-button
              >
              <el-button
                v-show="isObjectivesSortMode"
                :loading="isSubmitting"
                @click="submitSortObjectives()"
                >反映する</el-button
              >
              <el-button v-show="!isObjectivesSortMode" @click="startSortObjectives()"
                >並び替え</el-button
              >
              <el-button v-show="!isObjectivesSortMode" @click="openCreateObjectiveDialog()"
                >新規登録</el-button
              >
            </div>
          </div>
          <div v-show="!isObjectivesSortMode" class="ix-table">
            <div
              class="ix-table-row clickable"
              v-for="(objective, i) in sortedObjectives"
              :key="objective.id"
              @click="isAdmin ? openUpdateObjectiveDialog(objective) : undefined"
            >
              <div class="ix-table-column th">#{{ i + 1 }}</div>
              <div class="ix-table-column">{{ objective.title }}</div>
              <div class="ix-table-column end">
                <Check
                  v-if="objective.targetTobeImageId"
                  style="width: 16px; height: 16px; margin-top: 2px; color: #1c616e"
                />
                <el-popover
                  placement="top-start"
                  :width="200"
                  trigger="hover"
                  content="ToBeイメージが設定されていません"
                >
                  <template #reference>
                    <Warning
                      v-if="!objective.targetTobeImageId"
                      style="width: 16px; height: 16px; margin-top: 2px; color: #d46f72"
                    />
                  </template>
                </el-popover>
              </div>
            </div>
            <div v-if="!sortedObjectives.length" class="row-placeholder">データ未登録です</div>
          </div>
          <div v-show="isObjectivesSortMode" ref="sortObjectivesdiv" class="ix-table">
            <div
              class="ix-table-row sort"
              v-for="(objective, i) in sortTargetObjectives"
              :key="objective.id"
            >
              <div class="ix-table-column th">#{{ i + 1 }}</div>
              <div class="ix-table-column">{{ objective.title }}</div>
              <div class="ix-table-column end"></div>
            </div>
          </div>
        </div>
      </div>
      <el-divider />
      <TobeImagesContent :data="topCategoryData" />
    </template>
  </div>
</template>
<style scoped>
#top-category {
  padding-right: 24px;
  height: 100%;
  color: v-bind('colors.text.base');
}
.topheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.topheader-title {
  font-size: 24px;
  font-weight: bold;
}
.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.subheader-title {
  font-size: 16px;
  font-weight: bold;
  height: 32px;
  line-height: 32px;
}
.caption {
  font-size: 14px;
  font-weight: bold;
  margin-left: 12px;
  color: v-bind('colors.text.disabled');
}
.asis-and-objective-wrapper {
  display: flex;
  justify-content: space-between;
  min-height: 320px;
}
.asis-image-container,
.objecitives-container {
  width: 50%;
}
.asis-image-container {
  padding-right: 24px;
}
.objecitives-container {
  border-left: 2px solid v-bind('colors.border.base');
  padding-left: 24px;
}
.asis-image-wrapper {
  width: 100%;
}
.asis-image {
  width: 100%;
  min-height: 35vh;
  cursor: pointer;
  border: 1px solid v-bind('colors.border.base');
}
.asis-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.asis-image-enlarged {
  width: 100%;
  height: auto;
  border: 1px solid v-bind('colors.border.base');
}
.asis-image-enlarged img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.asis-image.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16 / 9;
  color: v-bind('colors.text.disabled');
  font-weight: bold;
  background-color: v-bind('colors.bg.gray05');
}
.asis-pptx-label {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  color: v-bind('colors.text.base');
}
.asis-pptx-label.danger {
  color: v-bind('colors.utility.red');
}
.asis-pptx-label.download {
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
  margin-right: 10px;
}
.ix-table-row {
  gap: 20px;
  padding-left: 16px;
}
.ix-table-column.th {
  min-width: 80px;
}
.ix-table-column.tags {
  display: flex;
  flex-wrap: wrap;
  max-width: 50%;
}
.sort {
  cursor: grab;
}
.sort:active {
  cursor: grabbing;
}
.row-placeholder {
  padding-left: 16px;
  font-size: 12px;
  color: v-bind('colors.text.disabled');
}
/* asisImage dialog */
.asis-image-dialog-content {
  display: flex;
}
.asis-image-dialog-img-wrapper {
  width: 70%;
}
.asis-image-dialog-img-wrapper .asis-image {
  cursor: inherit;
}
.asis-image-dialog-form-wrapper {
  width: 30%;
  padding-left: 24px;
}
/* objective dialog */
.tobe-images-wrapper {
  display: flex;
  gap: 12px;
  width: 100%;
  flex-wrap: wrap;
}
.tobe-image-container {
  flex-basis: 30%;
  min-width: 30%;
  border: 1px solid v-bind('colors.border.base');
}
.tobe-image-container.current {
  border: 4px solid v-bind('colors.service.accent');
  box-shadow: 0 0 10px v-bind('colors.service.accent');
}

.tobe-image {
  width: 100%;
  min-height: 25vh;
  cursor: pointer;
}
.tobe-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.tobe-image.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16 / 9;
  color: v-bind('colors.text.disabled');
  font-weight: bold;
  background-color: v-bind('colors.bg.gray05');
}
</style>
