import type { PaginationStats } from '@/types/paginationStats.type'
import { defineStore } from 'pinia'
import axios, { type _AxiosError } from './api'
import type {
  CaseItemDetail,
  CaseItemMessage,
  CreateCaseItemMessagePayload,
  CreateCaseItemPayload,
  GetCaseItemsQuery,
  UpdateCaseItemPayload
} from '@/types/caseItem.type'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import { useProjectStore } from './projectStore'
import { extractFilename } from '@/utils/file.helper'

interface State {
  current_case_item: CaseItemDetail | null
  case_items: CaseItemDetail[]
  case_items_stats: PaginationStats | null
}

export const useCaseItemStore = defineStore('caseItemStore', {
  state: (): State => ({
    current_case_item: null,
    case_items: [],
    case_items_stats: null
  }),
  actions: {
    clear_current_case_item() {
      this.current_case_item = null
    },
    clear_case_items() {
      this.$patch((state) => {
        state.case_items = []
        state.case_items_stats = null
      })
    },
    get_case_items(json: GetCaseItemsQuery) {
      axios
        .get(`/caseItems`, { params: json })
        .then((res) => {
          this.$patch((state) => {
            state.case_items = res.data.items
            state.case_items_stats = res.data.meta
          })
        })
        .catch((err: _AxiosError) => {
          console.log(err)
        })
    },
    get_case_item_detail(id: number, skipSave: boolean = false) {
      return new Promise<CaseItemDetail>((resolve, reject) => {
        axios
          .get(`/caseItems/${id}`)
          .then((res) => {
            if (!skipSave) {
              this.current_case_item = res.data
            }
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    create_case_item(payload: CreateCaseItemPayload) {
      return new Promise<CaseItemDetail>((resolve, reject) => {
        axios
          .post(`/caseItems`, payload)
          .then((res) => {
            this.$patch((state) => {
              state.case_items.unshift(res.data)
              state.current_case_item = res.data

              const projectStore = useProjectStore()
              if (projectStore.current_project_item) {
                projectStore.current_project_item.caseItems.unshift(res.data)
              }
            })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    update_case_item(id: number, json: UpdateCaseItemPayload) {
      return new Promise<CaseItemDetail>((resolve, reject) => {
        axios
          .put(`/caseItems/${id}`, json)
          .then((res) => {
            this.$patch((state) => {
              state.current_case_item = res.data
              const index = state.case_items.findIndex((item) => item.id === id)
              if (index !== -1) {
                state.case_items[index] = res.data
              }
            })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    unassign(id: number) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/caseItems/${id}/unassign`)
          .then((res) => {
            this.$patch((state) => {
              state.current_case_item = res.data
            })
            resolve(res)
          })
          .catch((err: _AxiosError) => {
            console.error(err)
            _ElMessage({
              type: 'error',
              message: '担当者の解除に失敗しました'
            })
            reject(err)
          })
      })
    },
    add_attachment(id: number, caseItemMessageId: number, file: File) {
      return new Promise<void>((resolve, reject) => {
        const formData = new FormData()
        formData.append('file', file)

        axios
          .post(`/caseItems/${id}/caseItemMessages/${caseItemMessageId}/attachments`, formData)
          .then((res) => {
            // update state
            this.$patch((state) => {
              // current_case_itemの更新
              if (state.current_case_item) {
                const caseItemMessageIndex = state.current_case_item.messages.findIndex(
                  (message) => message.id === caseItemMessageId
                )
                if (caseItemMessageIndex !== -1) {
                  state.current_case_item.messages[caseItemMessageIndex] = res.data
                }
                updateProjectItemCaseItem(state.current_case_item)
              }
              // case_itemsの更新
              const index = state.case_items.findIndex((item) => item.id === id)
              if (index !== -1) {
                const caseItemMessageIndex = state.case_items[index].messages.findIndex(
                  (message) => message.id === caseItemMessageId
                )
                if (caseItemMessageIndex !== -1) {
                  state.case_items[index].messages[caseItemMessageIndex] = res.data
                }
              }
            })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.error(err)
            reject(err)
          })
      })
    },
    send_message(id: number, createCaseItemMessagePayload: CreateCaseItemMessagePayload) {
      return new Promise<CaseItemMessage>((resolve, reject) => {
        axios
          .post(`/caseItems/${id}/caseItemMessages`, createCaseItemMessagePayload)
          .then((res) => {
            resolve(res.data)
            // update state
            this.$patch((state) => {
              if (state.current_case_item) {
                state.current_case_item.messages.push(res.data)
                updateProjectItemCaseItem(state.current_case_item)
              }
              const index = state.case_items.findIndex((item) => item.id === id)
              if (index !== -1) {
                state.case_items[index].messages.push(res.data)
                // state.case_items[index]を先頭に持っていき最新メッセージのあるcase_itemが上に来るようにする
                state.case_items.unshift(state.case_items.splice(index, 1)[0])
              }
            })
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    delete_message(id: number, caseItemMessageId: number) {
      axios
        .delete(`/caseItems/${id}/caseItemMessages/${caseItemMessageId}`)
        .then(() => {
          _ElMessage({
            type: 'success',
            message: 'メッセージを削除しました'
          })
          // update state
          this.$patch((state) => {
            // current_case_itemの更新
            if (state.current_case_item) {
              state.current_case_item.messages = state.current_case_item.messages.filter(
                (message) => message.id !== caseItemMessageId
              )
              updateProjectItemCaseItem(state.current_case_item)
            }
            // case_itemsの更新
            const index = state.case_items.findIndex((item) => item.id === id)
            if (index !== -1) {
              state.case_items[index].messages = state.case_items[index].messages.filter(
                (message) => message.id !== caseItemMessageId
              )
            }
          })
        })
        .catch((err: _AxiosError) => {
          _ElMessage({
            type: 'error',
            message: 'メッセージの削除に失敗しました'
          })
          console.error(err)
        })
    },
    delete_attachment(id: number, caseItemMessageId: number, attachmentId: number) {
      axios
        .delete(
          `/caseItems/${id}/caseItemMessages/${caseItemMessageId}/attachments/${attachmentId}`
        )
        .then(() => {
          _ElMessage({
            type: 'success',
            message: '添付ファイルを削除しました'
          })
          // update state
          this.$patch((state) => {
            // current_case_itemの更新
            if (state.current_case_item) {
              const messageIndex = state.current_case_item.messages.findIndex(
                (message) => message.id === caseItemMessageId
              )
              if (messageIndex !== -1) {
                state.current_case_item.messages[messageIndex].caseItemMessageAttachments =
                  state.current_case_item.messages[messageIndex].caseItemMessageAttachments.filter(
                    (attachment) => attachment.id !== attachmentId
                  )
              }
              updateProjectItemCaseItem(state.current_case_item)
            }

            // case_itemsの更新
            const index = state.case_items.findIndex((item) => item.id === id)
            if (index !== -1) {
              const messageIndex = state.case_items[index].messages.findIndex(
                (message) => message.id === caseItemMessageId
              )
              if (messageIndex !== -1) {
                state.case_items[index].messages[messageIndex].caseItemMessageAttachments =
                  state.case_items[index].messages[messageIndex].caseItemMessageAttachments.filter(
                    (attachment) => attachment.id !== attachmentId
                  )
              }
            }
          })
        })
        .catch((err: _AxiosError) => {
          _ElMessage({
            type: 'error',
            message: '添付ファイルの削除に失敗しました'
          })
          console.error(err)
        })
    },
    download_attachment(
      id: number,
      caseItemMessageId: number,
      attachmentId: number,
      fileExtension: string
    ) {
      return new Promise<{ data: Blob; filename: string }>((resolve, reject) => {
        axios
          .get(
            `/caseItems/${id}/caseItemMessages/${caseItemMessageId}/attachments/${attachmentId}/download`,
            {
              responseType: 'blob'
            }
          )
          .then((res) => {
            const filename = extractFilename(res, 'download.' + fileExtension)
            resolve({ data: res.data, filename })
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: 'ダウンロードに失敗しました' })
            reject(error)
          })
      })
    }
  }
})

function updateProjectItemCaseItem(caseItem: CaseItemDetail) {
  const projectStore = useProjectStore()
  if (projectStore.current_project_item) {
    projectStore.current_project_item.caseItems = projectStore.current_project_item.caseItems.map(
      (item) => {
        if (item.id === caseItem.id) {
          return caseItem
        }
        return item
      }
    )
  }
}
