import { defineStore } from 'pinia'
import type { _AxiosError } from './api'
import axios from './api'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import type { ChatMessage, Run, Thread, ThreadDetail } from '@/types/assistant.type'
import type { PaginationStats } from '@/types/paginationStats.type'

interface State {
  current_thread: ThreadDetail | null
  threads: ThreadDetail[]
  threads_stats: PaginationStats | null
}
export const useAssistantStore = defineStore('assistantStore', {
  state: (): State => ({
    current_thread: null,
    threads: [],
    threads_stats: null
  }),
  getters: {},
  actions: {
    clear_current_thread() {
      this.$patch((state) => {
        state.current_thread = null
      })
    },
    clear_threads() {
      this.$patch((state) => {
        state.threads = []
        state.threads_stats = null
      })
    },
    get_threads(params: { page: number; limit: number }) {
      axios
        .get('assistants/threads', {
          params
        })
        .then((res) => {
          this.$patch((state) => {
            state.threads = res.data.items
            state.threads_stats = res.data.meta
          })
        })
        .catch((err: _AxiosError) => {
          _ElMessage({ type: 'error', message: 'AI chatの取得に失敗しました' })
          console.error(err)
        })
    },
    get_my_threads(projectItemId?: number) {
      return new Promise<Thread[]>((resolve, reject) => {
        axios
          .get('assistants/threads/me', {
            params: {
              projectItemId,
              page: 1,
              limit: 50
            }
          })
          .then((res) => {
            this.$patch((state) => {
              state.threads = res.data.items
              state.threads_stats = res.data.meta
            })
            resolve(res.data.items)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    create_thread(projectItemId: number) {
      return new Promise<ThreadDetail>((resolve, reject) => {
        axios
          .post('assistants/threads', {
            projectItemId
          })
          .then((res) => {
            this.$patch((state) => {
              state.current_thread = res.data
              state.threads.unshift(res.data)
            })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            _ElMessage({ type: 'error', message: '会話の準備に失敗しました' })
            reject(err)
          })
      })
    },
    get_thread_detail(id: number) {
      return new Promise<ThreadDetail>((resolve, reject) => {
        axios
          .get(`assistants/threads/${id}`)
          .then((res) => {
            this.$patch((state) => {
              state.current_thread = res.data
            })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    get_thread_runs(threadId: number, runIds: number[]) {
      return new Promise<Run[]>((resolve, reject) => {
        axios
          .get(`assistants/threads/${threadId}/runs`, {
            params: {
              runIds
            }
          })
          .then((res) => {
            // state更新なし
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.error(err)
            reject(err)
          })
      })
    },
    send_message(threadId: number, text: string) {
      return new Promise<{ message: ChatMessage; run: Run }>((resolve, reject) => {
        axios
          .post(`assistants/threads/${threadId}/chat-messages/invokeRun`, {
            text
          })
          .then((res) => {
            this.$patch((state) => {
              if (state.current_thread?.id == threadId) {
                state.current_thread.messages.push(res.data.message)
                state.current_thread.runs.push(res.data.run)
              }
            })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            reject(err)
          })
      })
    }
  }
})
