import type { UploadFile, UploadRawFile } from 'element-plus'
import { _ElMessage } from './element-plus-wrapper'
import type { AxiosResponse } from 'axios'

export function validateFile(file: UploadFile, fileExtensions: string[]): UploadRawFile | null {
  // validation
  const idx = file.name.lastIndexOf('.')
  if (idx === -1 || !file.raw) {
    _ElMessage({
      type: 'error',
      message: `不正なファイルです`
    })
    return null
  } else {
    if (!fileExtensions.includes(file.name.slice(idx + 1).toLocaleLowerCase())) {
      _ElMessage({
        type: 'error',
        message: `非対応のファイル形式です。${fileExtensions.join('/')}形式のファイルをアップロードしてください`
      })
      return null
    }
  }
  const maxFileSize = 1_000_001_000 // 100MB
  if (file.size && file.size > maxFileSize) {
    _ElMessage({
      type: 'error',
      message: `100MBを超えるファイルは取り扱えません`
    })
    return null
  }
  return file.raw
}

export function extractFilename(res: AxiosResponse, defaultName = 'download.pptx'): string {
  const contentDisposition = res.headers['content-disposition']
  let filename = defaultName
  if (contentDisposition) {
    const matches = contentDisposition.match(/filename="([^"]+)"/)
    if (matches && matches[1]) {
      filename = decodeURIComponent(matches[1])
    }
  }
  return filename
}

export function downloadFile(data: Blob, filename: string): void {
  const blob = new Blob([data], { type: 'application/octet-stream' })
  const downloadUrl = window.URL.createObjectURL(blob)
  const link = document.createElement('a')

  link.href = downloadUrl
  link.setAttribute('download', decodeURI(filename))
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(downloadUrl)
}
