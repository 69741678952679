import type {
  GetUsefulMaterialsQuery,
  UpdateUsefulMaterialPayload,
  UsefulMaterial,
  UsefulMaterialDetail
} from '@/types/usefulMaterial.type'
import axios from './api'
import { defineStore } from 'pinia'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import { extractFilename } from '@/utils/file.helper'

interface State {
  usefulMaterials: UsefulMaterial[]
}

export const useUsefulMaterialStore = defineStore('usefulMaterialStore', {
  state: (): State => ({
    usefulMaterials: []
  }),
  actions: {
    create_useful_material(file: File) {
      const formData = new FormData()
      formData.append('file', file)
      return new Promise<UsefulMaterialDetail>((resolve, reject) => {
        axios
          .post('/usefulMaterials', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then((res: { data: UsefulMaterialDetail }) => {
            this.usefulMaterials.push(res.data)
            _ElMessage({ type: 'success', message: '保存しました' })
            resolve(res.data)
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '保存に失敗しました' })
            reject(error)
          })
      })
    },
    get_useful_materials(query: GetUsefulMaterialsQuery) {
      axios
        .get('/usefulMaterials', { params: query })
        .then((response) => {
          this.usefulMaterials = response.data
        })
        .catch((error) => {
          console.error(error)
        })
    },
    update_useful_material(json: UpdateUsefulMaterialPayload) {
      const { id, ...dto } = json
      return new Promise<UsefulMaterialDetail>((resolve, reject) => {
        axios
          .put(`/usefulMaterials/${id}`, dto)
          .then((res: { data: UsefulMaterialDetail }) => {
            this.$patch((state) => {
              const index = state.usefulMaterials.findIndex((item) => item.id === id)
              if (index !== -1) {
                state.usefulMaterials[index] = res.data
              }
            })
            _ElMessage({ type: 'success', message: '更新しました' })
            resolve(res.data)
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '更新に失敗しました' })
            reject(error)
          })
      })
    },
    delete_useful_material(id: number) {
      return new Promise<void>((resolve, reject) => {
        axios
          .delete(`/usefulMaterials/${id}`)
          .then(() => {
            this.$patch((state) => {
              const index = state.usefulMaterials.findIndex((item) => item.id === id)
              if (index !== -1) {
                state.usefulMaterials = state.usefulMaterials.filter((item) => item.id !== id)
              }
            })
            _ElMessage({ type: 'success', message: '削除しました' })
            resolve()
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '削除に失敗しました' })
            reject(error)
          })
      })
    },
    download_useful_material(id: number, fileExtension: string) {
      return new Promise<{ data: Blob; filename: string }>((resolve, reject) => {
        axios
          .get(`/usefulMaterials/${id}/download`, { responseType: 'blob' })
          .then((res) => {
            const filename = extractFilename(res, `download.${fileExtension}`)
            resolve({ data: res.data, filename })
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: 'ダウンロードに失敗しました' })
            reject(error)
          })
      })
    }
  }
})
