<script setup lang="ts">
import { colorPalette as colors } from '@/utils/enums'
import { useAssistantStore } from '@/stores/assistantStore'
import { computed, ref } from 'vue'
import type { ChatMessage } from '@/types/assistant.type'
import { datetimeFormatter } from '@/utils/date.formatter'
import { getHashedId } from '@/utils/hash.helper'
import { useRouter } from 'vue-router'
import AiThreadDetail from '@/components/settings/AiThreadDetail.vue'

const assistantStore = useAssistantStore()
const router = useRouter()

const aiThreads = computed(() =>
  assistantStore.threads
    .filter((t) => t.messages?.length > 0)
    .sort((a, b) => {
      return (
        new Date(b.messages[b.messages.length - 1].updatedAt).getTime() -
        new Date(a.messages[a.messages.length - 1].updatedAt).getTime()
      )
    })
)

function onClickThread(threadId: number) {
  router.push(`/settings?menu=aiThreads&threadIdHash=${getHashedId(threadId)}`)
}

function getAllThreads() {
  assistantStore.get_threads(query.value)
}

function getFirstMessage(messages: ChatMessage[]) {
  if (!messages.length) {
    throw new Error('no messages.')
  }
  const m = [...messages].sort(
    (a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
  )[0]

  return {
    date: datetimeFormatter(m.updatedAt),
    text: m.text
  }
}

function getLastMessage(messages: ChatMessage[]) {
  if (!messages.length) {
    throw new Error('no messages.')
  }
  const m = [...messages].sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  )[0]

  return {
    date: datetimeFormatter(m.updatedAt),
    text: m.text
  }
}

// pagination
const query = ref({
  page: 1,
  limit: 10
})
const totalPages = computed(() => {
  let num = 1
  if (assistantStore.threads_stats) {
    num = assistantStore.threads_stats.totalPages
  }
  return num
})
const currentPage = computed({
  get: () => query.value.page,
  set: (val) => (query.value.page = val)
})
function changePage(v: number) {
  query.value.page = v
  getAllThreads()
}

// init
getAllThreads()
</script>

<template>
  <div class="wrapper">
    <div>
      <table class="ix-table list">
        <template v-if="aiThreads.length === 0"> AI chatがありません。</template>
        <tr
          v-bind:key="thread.id"
          v-for="(thread, index) in aiThreads"
          class="ix-table-row clickable"
          @click="onClickThread(thread.id)"
        >
          <td class="ix-table-column first">{{ index + 1 }}.</td>
          <td class="ix-table-column">
            <div class="truncated-message bold">
              {{ getFirstMessage(thread.messages).text }}
            </div>
            <div>最終更新: {{ getLastMessage(thread.messages).date }}</div>
          </td>
        </tr>
      </table>
      <div class="pagination-footer">
        <el-pagination
          class="pagination"
          layout="prev, pager, next"
          :hide-on-single-page="true"
          :page-count="totalPages"
          :current-page="currentPage"
          @current-change="changePage"
        />
      </div>
    </div>
    <div class="details">
      <AiThreadDetail />
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  display: flex;
}
.list {
  width: 30vw;
  min-height: 85vh;
  margin-right: 1vw;
  border-right: 1px solid v-bind('colors.border.base');
}
.details {
  width: 50vw;
}
.clickable {
  cursor: pointer;
}
.truncated-message {
  width: 25vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ix-table-row {
  line-height: 30px;
}
.ix-table-column {
  flex-grow: 0;
}
.first {
  margin-right: 1vw;
}
.bold {
  font-weight: bold;
}
</style>
