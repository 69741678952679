import type { User } from './user.type'

export type CsRequest = {
  id: number
  authorId: number
  author: User
  text: string
  closedAt?: Date
  createdAt: Date
  updatedAt: Date
}

export type GetCsRequestQuery = {
  page: number
  limit: number
  status: CsRequestStatusQuery
}

export type CreateCsRequestPayload = {
  text: string
}

export const CsRequestStatusQuery = {
  all: 'all',
  open: 'open',
  closed: 'closed'
} as const
export type CsRequestStatusQuery = keyof typeof CsRequestStatusQuery
