import type { ProjectItem } from './project.type'
import type { User } from './user.type'

export type Thread = {
  id: number
  authorId: number
  projectItemId: number
  openAiAssistantId?: string
  openAiThreadId?: string
  createdAt: Date
  updatedAt: Date
}

export type ThreadDetail = Thread & {
  author: User
  projectItem: ProjectItem
  messages: ChatMessage[]
  runs: Run[]
}

export type ChatMessage = {
  id: number
  threadId: number
  parentRunId?: number
  userId: number
  openAiMessageId?: string
  role: ChatMessageRole
  text: string
  createdAt: Date
  updatedAt: Date
}

export const ChatMessageRole = {
  user: 'user',
  ai: 'ai'
} as const
export type ChatMessageRole = keyof typeof ChatMessageRole

export type Run = {
  id: number
  invokeMessageId: number
  error: string
  openAiRunId?: string
  status: AssistantsApiRunStatus
  // responseMessages: ChatMessage[]
  createdAt: Date
  updatedAt: Date
}

// https://platform.openai.com/docs/api-reference/runs/object
export const AssistantsApiRunStatus = {
  queued: 'queued',
  in_progress: 'in_progress',
  requires_action: 'requires_action',
  cancelling: 'cancelling',
  cancelled: 'cancelled',
  failed: 'failed',
  completed: 'completed',
  expired: 'expired'
} as const
export type AssistantsApiRunStatus = keyof typeof AssistantsApiRunStatus
export const finishedRunStatuses: AssistantsApiRunStatus[] = [
  AssistantsApiRunStatus.cancelled,
  AssistantsApiRunStatus.failed,
  AssistantsApiRunStatus.completed,
  AssistantsApiRunStatus.expired
]

export const wipRunStatuses: AssistantsApiRunStatus[] = [
  AssistantsApiRunStatus.queued,
  AssistantsApiRunStatus.in_progress,
  AssistantsApiRunStatus.requires_action
]
