import type { Objective } from './objective.type'
import type { ImageResizeStatus } from './solutionAttribute.type'
import type { TobeItemDetail } from './tobeItem.type'
import type { TopCategory } from './topCategory.type'

export type TobeImage = {
  id: number
  topCategoryId: number
  note: string
  objectives: Objective[]
  createdAt: Date
  updatedAt: Date
}

export const TobeFileExtensionForDownload = {
  pptx: 'pptx',
  pdf: 'pdf',
  xlsx: 'xlsx',
  docx: 'docx'
} as const
export type TobeFileExtensionForDownload = keyof typeof TobeFileExtensionForDownload

export type TobePptx = {
  id: number
  tobeImageId: number
  originalFileName: string
  fileExtension: TobeFileExtensionForDownload
  uuid: string
  createdAt: Date
  updatedAt: Date
}

export type TobePng = {
  id: number
  tobeImageId: number
  originalFileName: string
  uuid: string
  resizeStatus: ImageResizeStatus
  createdAt: Date
  updatedAt: Date
}

export type TobeImageDetail = TobeImage & {
  topCategory: TopCategory
  objectives: Objective[]
  tobeItems: TobeItemDetail[]
  tobePptx?: TobePptx
  tobePng?: TobePng
}

// payload
export type UpdateTobeImagePayload = {
  id: number
  note: string
}
