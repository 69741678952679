<script setup lang="ts">
import { computed, ref } from 'vue'
import { colorPalette as colors } from '@/utils/enums'
import { useCategoryStore } from '@/stores/categoryStore'
import type { TopCategoryDetail } from '@/types/topCategory.type'
import type {
  CreateTobeItemPayload,
  TobeItemDetail,
  UpdateTobeItemPayload
} from '@/types/tobeItem.type'
import { Warning } from '@element-plus/icons-vue'
import { useSolutionStore } from '@/stores/solutionStore'
import { _ElConfirm, _ElMessage } from '@/utils/element-plus-wrapper'
import { useRouter } from 'vue-router'
import { useSortable } from '@vueuse/integrations/useSortable'
import type { TobeImageDetail } from '@/types/tobeImage.type'
import { useUserStore } from '@/stores/userStore'

const props = defineProps<{
  topCategory: TopCategoryDetail
  tobeImage: TobeImageDetail
}>()

const categoryStore = useCategoryStore()
const solutionStore = useSolutionStore()
const userStore = useUserStore()
solutionStore.get_solutions({})
const router = useRouter()

const isAdmin = computed(() => userStore.get_current_user.role === 'admin')

const topCategoryData = computed(() => props.topCategory)
const tobeImage = computed(() => props.tobeImage)
const allSolutions = computed(() => solutionStore.solutions)
const isSubmitting = ref(false)

// TobeItem
const isCreateTobeItemDialog = ref(false)
const isUpdateTobeItemDialog = ref(false)
const dialogCreateTobeItemData = ref<CreateTobeItemPayload>({
  tobeImageId: -1,
  identifierLabel: '',
  description: ''
})
const dialogUpdateTobeItemData = ref<UpdateTobeItemPayload>({
  id: -1,
  tobeImageId: -1,
  identifierLabel: '',
  description: ''
})
const currentTobeItemSolutions = computed(() => {
  if (dialogUpdateTobeItemData.value.id === -1) {
    return []
  }
  const d = topCategoryData.value.tobeImages.find(
    (x) => x.id === dialogUpdateTobeItemData.value.tobeImageId
  )
  if (d) {
    const item = d.tobeItems.find((x) => x.id === dialogUpdateTobeItemData.value.id)
    if (item) {
      return item.solutions
    } else {
      return []
    }
  } else {
    return []
  }
})
function openCreateTobeItemDialog(tobeImageId: number) {
  dialogCreateTobeItemData.value = {
    tobeImageId: tobeImageId,
    identifierLabel: '',
    description: ''
  }
  isCreateTobeItemDialog.value = true
}
function submitCreateTobeItem() {
  if (!dialogCreateTobeItemData.value.identifierLabel.length) {
    _ElMessage({ type: 'error', message: '表示番号を入力してください' })
    return
  }
  if (!dialogCreateTobeItemData.value.description.length) {
    _ElMessage({ type: 'error', message: 'ラベルを入力してください' })
    return
  }
  isSubmitting.value = true
  categoryStore
    .create_tobe_item(dialogCreateTobeItemData.value)
    .then(() => {
      isCreateTobeItemDialog.value = false
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}
function openUpdateTobeItemDialog(data: TobeItemDetail) {
  dialogUpdateTobeItemData.value = {
    id: data.id,
    tobeImageId: data.tobeImageId,
    identifierLabel: data.identifierLabel,
    description: data.description
  }
  isUpdateTobeItemDialog.value = true
}
function submitUpdateTobeItem() {
  if (
    dialogUpdateTobeItemData.value.id === -1 ||
    dialogUpdateTobeItemData.value.tobeImageId === -1
  ) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  if (!dialogUpdateTobeItemData.value.identifierLabel.length) {
    _ElMessage({ type: 'error', message: '表示番号を入力してください' })
    return
  }
  if (!dialogUpdateTobeItemData.value.description.length) {
    _ElMessage({ type: 'error', message: 'ラベルを入力してください' })
    return
  }
  isSubmitting.value = true
  categoryStore
    .update_tobe_item(dialogUpdateTobeItemData.value)
    .then(() => {
      isUpdateTobeItemDialog.value = false
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}
function confirmDeleteTobeItem() {
  if (dialogUpdateTobeItemData.value.id == -1 || dialogUpdateTobeItemData.value.tobeImageId == -1) {
    return
  }
  _ElConfirm('この処理は取り消せません。実行しますか？', `ToBeアイテムの削除`, {
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル'
  })
    .then(() => {
      categoryStore
        .delete_tobe_item(
          topCategoryData.value.id,
          dialogUpdateTobeItemData.value.tobeImageId,
          dialogUpdateTobeItemData.value.id
        )
        .then(() => {
          isUpdateTobeItemDialog.value = false
        })
        .catch(() => {})
    })
    .catch(() => {})
}
function addSolution(tobeItemId: number, solutionId: number) {
  categoryStore.add_solution_to_tobe_item(tobeItemId, solutionId)
}
function removeSolution(tobeItemId: number, solutionId: number) {
  categoryStore.remove_solution_from_tobe_item(tobeItemId, solutionId)
}

// transit
function openWorksheet(solutionId: number) {
  router.push(`/catalogs?menu=solutionList&pageType=worksheet&resourceId=${solutionId}`)
}

// sort
const sortedTobeItems = computed(() => {
  return [...tobeImage.value.tobeItems].sort((a, b) => a.sortNumber - b.sortNumber)
})
const isTobeItemSortMode = ref(false)
const sortTobeItemsRef = ref<HTMLElement | null>(null)
const sortTargetTobeItems = ref<TobeItemDetail[]>([])
const isSubmittingSort = ref(false)
function startSortTobeItemsMode() {
  if (sortedTobeItems.value.length === 0) {
    _ElMessage({ type: 'error', message: '対象のTobeアイテムがありません' })
    return
  }
  sortTargetTobeItems.value = [...sortedTobeItems.value]
  useSortable(sortTobeItemsRef, sortTargetTobeItems, { animation: 150 })
  isTobeItemSortMode.value = true
}
function submitSortTobeItems(tobeImageId: number) {
  if (sortTargetTobeItems.value.length === 0) {
    _ElMessage({ type: 'error', message: '対象のTobeアイテムがありません' })
    return
  }
  isSubmittingSort.value = true
  categoryStore
    .sort_tobe_items(
      tobeImageId,
      sortTargetTobeItems.value.map((x) => x.id)
    )
    .then(() => {
      isTobeItemSortMode.value = false
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmittingSort.value = false
    })
}
</script>

<template>
  <!-- dialogs -->
  <template v-if="true">
    <el-dialog
      :close-on-press-escape="false"
      v-model="isCreateTobeItemDialog"
      top="10vh"
      width="50%"
      class="ix-dialog"
    >
      <template #header>ToBeアイテムの新規登録</template>
      <el-form
        :model="dialogCreateTobeItemData"
        label-position="left"
        label-width="120px"
        @submit.prevent
      >
        <el-form-item label="表示番号">
          <el-input v-model="dialogCreateTobeItemData.identifierLabel" />
        </el-form-item>
        <el-form-item label="ラベル">
          <el-input v-model="dialogCreateTobeItemData.description" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span>
          <el-button link @click="isCreateTobeItemDialog = false">閉じる</el-button>
          <el-button :loading="isSubmitting" @click="submitCreateTobeItem">保存する</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      :close-on-press-escape="false"
      v-model="isUpdateTobeItemDialog"
      top="10vh"
      width="50%"
      class="ix-dialog"
    >
      <template #header>ToBeアイテムの編集</template>
      <el-form
        :model="dialogUpdateTobeItemData"
        label-position="left"
        label-width="120px"
        @submit.prevent
      >
        <el-form-item label="表示番号">
          <el-input v-model="dialogUpdateTobeItemData.identifierLabel" />
        </el-form-item>
        <el-form-item label="ラベル">
          <el-input v-model="dialogUpdateTobeItemData.description" />
        </el-form-item>
        <el-form-item label="ソリューション">
          <el-tag
            v-for="s in currentTobeItemSolutions"
            :key="s.id"
            type="info"
            closable
            @close="removeSolution(dialogUpdateTobeItemData.id, s.id)"
          >
            {{ s.title }}
          </el-tag>
          <el-select
            placeholder="追加する"
            @change="addSolution(dialogUpdateTobeItemData.id, $event)"
            style="width: 120px; margin-left: 8px"
          >
            <el-option
              v-for="solution in allSolutions.filter(
                (s) => !currentTobeItemSolutions.map((x) => x.id).includes(s.id)
              )"
              :key="solution.id"
              :label="solution.title"
              :value="solution.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="el-dialog-footer_both_side">
          <span>
            <el-button link class="danger" @click="confirmDeleteTobeItem()">削除する</el-button>
          </span>
          <span>
            <el-button link @click="isUpdateTobeItemDialog = false">閉じる</el-button>
            <el-button :loading="isSubmitting" @click="submitUpdateTobeItem">保存する</el-button>
          </span>
        </div>
      </template>
    </el-dialog>
  </template>
  <!-- template -->
  <div class="subheader">
    <div class="subheader-title">アイテム</div>
    <div v-if="isAdmin" class="subheader-action">
      <template v-if="!isTobeItemSortMode">
        <el-button @click="startSortTobeItemsMode">並び替え</el-button>
        <el-button @click="openCreateTobeItemDialog(tobeImage.id)">新規登録</el-button>
      </template>
      <template v-else>
        <el-button @click="isTobeItemSortMode = false">キャンセル</el-button>
        <el-button @click="submitSortTobeItems(tobeImage.id)" :loading="isSubmittingSort"
          >反映する</el-button
        >
      </template>
    </div>
  </div>
  <template v-if="!tobeImage.tobeItems.length">
    <div class="row-placeholder">データ未登録です</div>
  </template>
  <template v-else>
    <table v-show="!isTobeItemSortMode" class="ix-table">
      <tr
        class="ix-table-row clickable"
        v-for="item in sortedTobeItems"
        :key="item.id"
        @click="isAdmin ? openUpdateTobeItemDialog(item) : undefined"
      >
        <th class="ix-table-column th">#{{ item.identifierLabel }}</th>
        <td class="ix-table-column description">
          {{ item.description }}
        </td>
        <td class="ix-table-column end">
          <el-tag v-for="s in item.solutions" :key="s.id" type="info" @click="openWorksheet(s.id)">
            {{ s.title }}
          </el-tag>
          <el-popover
            placement="top-start"
            :width="200"
            trigger="hover"
            content="ソリューションが設定されていません"
          >
            <template #reference>
              <Warning
                v-if="!item.solutions.length"
                style="width: 16px; height: 16px; margin-top: 2px; color: #d46f72"
              />
            </template>
          </el-popover>
        </td>
      </tr>
    </table>
    <table
      v-show="isTobeItemSortMode"
      ref="sortTobeItemsRef"
      class="ix-table"
      style="padding: 0 16px"
    >
      <tr class="ix-table-row sort" v-for="item in sortTargetTobeItems" :key="item.id">
        <th class="ix-table-column th">#{{ item.identifierLabel }}</th>
        <td class="ix-table-column description">{{ item.description }}</td>
        <td class="ix-table-column end">
          <el-tag v-for="s in item.solutions" :key="s.id" type="info">
            {{ s.title }}
          </el-tag>
          <el-popover
            placement="top-start"
            :width="200"
            trigger="hover"
            content="ソリューションが設定されていません"
          >
            <template #reference>
              <Warning
                v-if="!item.solutions.length"
                style="width: 16px; height: 16px; margin-top: 2px; color: #d46f72"
              />
            </template>
          </el-popover>
        </td>
      </tr>
    </table>
  </template>
</template>

<style scoped>
.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.subheader-title {
  font-size: 16px;
  font-weight: bold;
  height: 32px;
  line-height: 32px;
}
.ix-table-row {
  gap: 20px;
  padding-left: 16px;
}
.ix-table-column.th {
  min-width: 32px;
}
.ix-table-column.description {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.row-placeholder {
  padding-left: 16px;
  font-size: 12px;
  color: v-bind('colors.text.disabled');
}
.el-tag + .el-tag {
  margin-left: 4px;
}
.tobe-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tobe-image-container .subheader {
  padding-left: 8px;
  padding-right: 8px;
}
/* tobeImage dialog */
.tobe-image-dialog-img-wrapper .tobe-image {
  cursor: inherit;
}
.sort {
  cursor: grab;
}
.sort:active {
  cursor: grabbing;
}
</style>
