<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { colorPalette as colors } from '@/utils/enums'
import { useAssistantStore } from '@/stores/assistantStore'
import { CircleCloseFilled } from '@element-plus/icons-vue'
import ChatInput from '@/components/ChatInput.vue'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import { datetimeFormatter } from '@/utils/date.formatter'
import { wipRunStatuses, type ChatMessage, type Run } from '@/types/assistant.type'
import { marked } from '@/utils/text.helper'
import { onUnmounted } from 'vue'
import type { ProjectItem } from '@/types/project.type'

const props = defineProps<{
  projectItemId: number
}>()
const emit = defineEmits<{
  (e: 'close'): void
}>()

const assistantStore = useAssistantStore()
const currentThreadId = ref<number | null>(null)

async function init() {
  assistantStore.clear_current_thread()
  assistantStore.clear_threads()
  clearInputAndScroll()
  const threads = await assistantStore.get_my_threads(props.projectItemId).catch(() => {})
  if (!threads) {
    return
  }
  if (threads.length) {
    currentThreadId.value = threads[0].id
    loadMessages(threads[0].id)
  } else {
    const thread = await assistantStore.create_thread(props.projectItemId).catch(() => {})
    if (!thread) {
      return
    }
    currentThreadId.value = thread.id
    loadMessages(thread.id)
  }
}
function close() {
  assistantStore.clear_current_thread()
  assistantStore.clear_threads()
  emit('close')
}

// messages
const currentThread = computed(() => assistantStore.current_thread)
const isLoading = computed(() => {
  return checkRunStatusTimeoutId.value != null
})
const checkRunStatusTimeoutId = ref<number | null>(null)
onUnmounted(() => {
  clearCheckRunStatus()
})
function clearCheckRunStatus() {
  if (checkRunStatusTimeoutId.value) {
    window.clearTimeout(checkRunStatusTimeoutId.value)
    checkRunStatusTimeoutId.value = null
  }
}
function checkRunStatus(runs: Run[]) {
  if (!currentThread.value) {
    return
  }
  const tId = currentThread.value.id
  assistantStore
    .get_thread_runs(
      tId,
      runs.map((e) => e.id)
    )
    .then((r) => {
      const wipRuns = r.filter((e) => wipRunStatuses.includes(e.status))
      // 進捗があればloadMessagesをかける
      if (wipRuns.length < runs.length) {
        loadMessages(tId)
        if (wipRuns.length == 0) {
          clearInputAndScroll()
        }
      } else {
        clearCheckRunStatus()
        checkRunStatusTimeoutId.value = window.setTimeout(() => {
          checkRunStatus(wipRuns)
        }, 5000)
      }
    })
    .catch(() => {})
}
function loadMessages(threadId: number) {
  assistantStore
    .get_thread_detail(threadId)
    .then((t) => {
      clearCheckRunStatus()
      // 進行中のrunを見つけて監視
      const wipRuns = t.runs.filter((r) => wipRunStatuses.includes(r.status))
      if (wipRuns.length > 0) {
        checkRunStatusTimeoutId.value = window.setTimeout(() => {
          checkRunStatus(wipRuns)
        }, 1000)
      } else {
        clearInputAndScroll()
      }
      scrollToBottomMessage()
    })
    .catch(() => {})
}

// chatinput
const chatInput = ref<InstanceType<typeof ChatInput> | null>(null)
function handleSend(chatInputValue: string) {
  if (!chatInput.value || !currentThreadId.value) {
    return
  }

  chatInput.value.makeLoading(true)
  assistantStore
    .send_message(currentThreadId.value, chatInputValue)
    .then((r) => {
      // runの監視
      clearCheckRunStatus()
      checkRunStatusTimeoutId.value = window.setTimeout(() => {
        checkRunStatus([r.run])
      }, 3000)

      // clear
      clearInputAndScroll()
    })
    .catch(() => {
      _ElMessage({
        message: 'メッセージの送信に失敗しました',
        type: 'error'
      })
    })
    .finally(() => {
      chatInput.value?.makeLoading(false)
    })
}
function clearInputAndScroll() {
  if (chatInput.value) {
    chatInput.value.clearInput()
    chatInput.value.clearFiles()
    scrollToBottomMessage()
  }
}
function scrollToBottomMessage() {
  // 1番下までscrollする
  const wrapperDivs = document.querySelectorAll('.ai-chat-body-message-container')
  if (wrapperDivs.length) {
    wrapperDivs[0].scrollTop = wrapperDivs[0].scrollHeight
  }
}
function parseMessage(message: ChatMessage) {
  let text = message.text

  // Replace \n with two spaces and \n for markdown line breaks
  text = text.replace(/\\n/g, '  \n')
  const response = marked.parse(text)
  return response
}
function instructionMessage(projectItem: ProjectItem) {
  return `「${projectItem.title}」ソリューションについて、AIに質問や相談をすることができます。`
}

// trigger
init()
watch(
  () => props.projectItemId,
  () => init()
)
</script>
<template>
  <div class="ai-chat">
    <div v-if="!currentThread" class="loading"><div class="loading-text">Loading...</div></div>
    <template v-else>
      <div class="ai-chat-header">
        <div class="ai-chat-header-title">AI chat</div>
        <div>
          <CircleCloseFilled style="width: 1em; height: 1em; cursor: pointer" @click="close()" />
        </div>
      </div>
      <div class="ai-chat-body">
        <div class="messages-container">
          <div
            v-for="m in currentThread.messages"
            :key="m.id"
            class="message-item"
            :class="m.role == 'user' ? 'mine' : 'others'"
          >
            <div class="message-header">
              <div class="author-name">
                <template v-if="m.role == 'user'"> あなた </template>
                <template v-else>AI </template>
              </div>
            </div>
            <div
              class="message-text"
              :class="m.role == 'user' ? 'mine' : 'others'"
              v-html="parseMessage(m)"
            />
            <div class="created-at">
              {{ datetimeFormatter(m.createdAt) }}
            </div>
          </div>
          <div v-if="!currentThread.messages.length" class="no-messages">
            {{ instructionMessage(currentThread.projectItem) }}
          </div>
        </div>
        <div v-if="isLoading" class="ai-chat-input closed">AIが返事を書いています...</div>
        <div v-else class="ai-chat-input">
          <ChatInput
            ref="chatInput"
            :enable-files-option="false"
            :enableNotifyOption="false"
            @send="handleSend"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<style scoped>
.ai-chat {
  position: fixed;
  bottom: 12px;
  right: 12px;
  height: 50%;
  width: 360px;
  background-color: v-bind('colors.bg.white');
  z-index: 1000;
  box-shadow: v-bind('colors.shadow.card');
  border-radius: 8px;
}
.ai-chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  line-height: 32px;
  padding-left: 12px;
  padding-right: 8px;
  background-color: v-bind('colors.utility.yellow');
  color: v-bind('colors.text.white');
  .ai-chat-header-title {
    font-size: 14px;
    font-weight: bold;
  }
}
.ai-chat-body {
  position: relative;
  height: calc(100% - 32px);
  padding: 0 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.messages-container {
  overflow-y: scroll;
  padding-top: 8px;
  padding-bottom: 160px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .no-messages {
    padding-top: 12px;
    font-size: 14px;
    line-height: 20px;
    color: v-bind('colors.text.lighter');
  }
}
.message-item {
  width: 90%;
  display: flex;
  flex-direction: column;
  &.mine {
    align-items: flex-end;
  }
  &.others {
    align-items: flex-start;
  }
}
.message-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 22px;
  line-height: 22px;
  .author-name {
    font-weight: bold;
    font-size: 12px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.message-text {
  margin-top: 2px;
  padding: 8px;
  border: 1px solid v-bind('colors.border.base');
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  &.mine {
    background-color: v-bind('colors.utility.greenBg');
  }
  &.others {
    background-color: v-bind('colors.bg.gray01');
  }
}
.created-at {
  margin-top: 2px;
  font-size: 12px;
  color: v-bind('colors.text.disabled');
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.ai-chat-input {
  width: 100%;

  &.closed {
    position: fixed;
    bottom: 12px;
    width: calc(360px - 16px);
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-weight: bold;
    color: v-bind('colors.text.disabled');
    background-color: v-bind('colors.bg.white');
    cursor: not-allowed;
  }
}

/* loading */
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-text {
  font-size: 14px;
  font-weight: bold;
  color: v-bind('colors.text.secondary');
}
</style>
