<script setup lang="ts">
import { getHashedId } from '@/utils/hash.helper'
import { colorPalette as colors } from '../utils/enums'
import { useRouter } from 'vue-router'
import { _ElMessage } from '@/utils/element-plus-wrapper'
export type UrlSchemeName = 'pi' | 'ci' // projectItems | caseItems

// props
const props = defineProps<{
  name: UrlSchemeName
  ids: string // 生id(カンマ区切り)
}>()

// init
const router = useRouter()
const url = (() => {
  const ids = (() => {
    return props.ids
      .split(',')
      .map((s) => {
        if (s == '' || isNaN(Number(s))) {
          return -1
        } else {
          return Number(s)
        }
      })
      .filter((e) => e !== -1)
  })()
  if (ids.length == 0) {
    _ElMessage({ type: 'error', message: '不正なリンクです' })
    router.push('/projects')
    return ''
  }

  switch (props.name) {
    case 'pi':
      if (ids.length !== 2) {
        console.log('unexpected id', ids)
      }
      return `/projects?id=${getHashedId(ids[0])}&itemId=${getHashedId(ids[1])}`
    case 'ci':
      if (ids.length !== 1) {
        console.log('unexpected id', ids)
      }
      return `/cases?id=${getHashedId(ids[0])}`
  }
})()
setTimeout(() => {
  if (url !== '') {
    router.push(url)
  }
}, 1000) // ちょっと待たせる
</script>

<template>
  <div id="container">
    <div
      id="loading"
      v-loading="true"
      element-loading-text="ページを開いています..."
      element-loading-background="transparent"
    >
      <div class="logo">DX plus</div>
    </div>
  </div>
</template>

<style scoped>
#container {
  height: 100%;
  overflow: hidden;
  background: v-bind('colors.bg.black');
  display: flex;
  justify-content: center;
  align-items: center;
}
a {
  text-decoration: none;
}
#loading {
  display: inline-block;
  width: 500px;
  height: 320px;
  box-sizing: border-box;
  padding: 60px 24px 32px;
}
.logo {
  text-align: center;
  color: v-bind('colors.text.white');
  font-size: 36px;
  font-weight: bold;
  line-height: 36px;
}
#loading :deep(.el-loading-spinner .path) {
  stroke: v-bind('colors.text.white');
}
#loading :deep(.el-loading-text) {
  color: v-bind('colors.text.white');
  margin-top: 16px;
}
</style>
