<script setup lang="ts">
import { colorPalette as colors } from '@/utils/enums'
import { useCsRequestStore } from '@/stores/csRequestStore'
import { computed, ref, toRefs, watch } from 'vue'
import { _ElConfirm } from '@/utils/element-plus-wrapper'
import { datetimeFormatter } from '@/utils/date.formatter'
import DOMPurify from 'dompurify'
import { CsRequestStatusQuery } from '@/types/csRequest.type'

const props = withDefaults(
  defineProps<{
    csRequestId: number
    status: CsRequestStatusQuery
  }>(),
  {
    csRequestId: -1,
    status: CsRequestStatusQuery.open
  }
)

const { csRequestId, status } = toRefs(props)
const isSubmitting = ref(false)
const csRequestStore = useCsRequestStore()

const currentCsRequest = computed(() => {
  return csRequestStore.current_cs_request
})

function initialize() {
  csRequestStore.clear_current_cs_request()
  if (csRequestId.value !== -1) {
    csRequestStore.get_cs_request_detail(csRequestId.value)
  }
}

initialize()

watch(csRequestId, async () => {
  initialize()
})

function openCloseCsRequestDialog(id: number) {
  _ElConfirm('この処理は取り消せません。実行しますか？', '問い合わせのクローズ', {
    confirmButtonText: 'クローズする',
    cancelButtonText: 'キャンセル'
  })
    .then(() => {
      isSubmitting.value = true
      csRequestStore.close_cs_request(id, status.value)
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}
function textFormatter(txt: string) {
  return txt.replace(/\n/g, '<br />')
}
</script>
<template>
  <div class="wrapper" v-if="currentCsRequest">
    <div class="header">
      <h3 class="page-title">問い合わせ詳細</h3>
      <el-button
        v-if="currentCsRequest.closedAt === null"
        :loading="isSubmitting"
        @click="openCloseCsRequestDialog(currentCsRequest.id)"
        >クローズ</el-button
      >
    </div>
    <div class="details-container">
      <div class="detail-item">
        お問い合わせ日:
        <span class="detail-item-name">
          {{ datetimeFormatter(currentCsRequest.createdAt) }}
        </span>
      </div>
      <div class="detail-item">
        名前:
        <span class="detail-item-name">
          {{ currentCsRequest.author.name }}
        </span>
      </div>
      <div class="detail-item">
        email:
        <span class="detail-item-name">
          {{ currentCsRequest.author.email }}
        </span>
      </div>
      <div class="detail-item-end">
        ステータス:
        <span class="detail-item-name">
          {{ currentCsRequest.closedAt ? 'Closed' : 'Open' }}
        </span>
      </div>
    </div>
    <div class="text-area">
      <div class="text-title">内容:</div>
      <div class="text-content" v-html="DOMPurify.sanitize(textFormatter(currentCsRequest.text))" />
    </div>
  </div>
  <div v-else>Loading...</div>
</template>
<style scoped>
.wrapper {
  margin: 0;
  height: 100%;
}
.header {
  margin-top: 4px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  .page-title {
    font-size: 18px;
    font-weight: bold;
    height: 32px;
    line-height: 32px;
  }
}

.details-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border: 1px solid v-bind('colors.border.base');
  padding: 10px;
  border-radius: 5px;
}

.detail-item {
  margin-bottom: 10px;
}
.detail-item-name {
  font-weight: bold;
}
.detail-item-end {
  margin-bottom: 0;
}

.text-area {
  display: flex;
  flex-direction: column;
  height: auto;
}
.text-title {
  font-weight: bold;
  margin-bottom: 10px;
}
.text-content {
  border: 1px solid v-bind('colors.border.base');
  height: auto;
  min-height: 300px;
  max-height: 500px;
  overflow-y: auto;
  padding: 10px;
}
</style>
