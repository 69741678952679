export const datetimeFormatter = (date: Date | string, isShort = false) => {
  const dateObj = typeof date == 'string' ? new Date(date) : date
  const hh = ('00' + dateObj.getHours()).slice(-2)
  const min = ('00' + dateObj.getMinutes()).slice(-2)

  if (isShort) {
    return `${dateObj.getMonth() + 1}/${dateObj.getDate()} ${hh}:${min}`
  } else {
    const yyyy = ('0000' + dateObj.getFullYear()).slice(-4)
    const mm = ('00' + (dateObj.getMonth() + 1)).slice(-2)
    const dd = ('00' + dateObj.getDate()).slice(-2)

    return `${yyyy}年${mm}月${dd}日 ${hh}:${min}`
  }
}
