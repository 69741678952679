<script setup lang="ts">
import { computed, ref } from 'vue'
import { colorPalette as colors } from '@/utils/enums'
import { _ElConfirm, _ElMessage } from '@/utils/element-plus-wrapper'
import { useRouter } from 'vue-router'
import { useSolutionStore } from '@/stores/solutionStore'
import {
  SolutionFileExtensionForDownload,
  type SolutionAttributeDetail,
  type UpdateSolutionAttributePayload
} from '@/types/solutionAttribute.type'
import type { UploadFile } from 'element-plus'
import { useSortable } from '@vueuse/integrations/useSortable'
import {
  type CreateSolutionRoughEstimatePayload,
  type SolutionRoughEstimate,
  type UpdateSolutionPayload,
  type UpdateSolutionRoughEstimatePayload
} from '@/types/solution.type'
import { useProductStore } from '@/stores/productStore'
import type { GetProductsQuery } from '@/types/product.type'
import { downloadFile, validateFile } from '@/utils/file.helper'
import { useUserStore } from '@/stores/userStore'

const props = defineProps<{
  resourceId: string
}>()
const router = useRouter()
const solutionStore = useSolutionStore()
const productStore = useProductStore()
const userStore = useUserStore()

const isAdmin = computed(() => userStore.get_current_user.role === 'admin')

const getProductQuery = ref<GetProductsQuery>({
  page: 1,
  limit: 100
})
const solutionId = (() => {
  if (props.resourceId == '' || isNaN(Number(props.resourceId))) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    router.push('/catalogs?menu=solutionList')
    return -1
  } else {
    return Number(props.resourceId)
  }
})()

if (solutionId !== -1) {
  solutionStore.get_solution_detail(solutionId)
  getProductQuery.value.solutionId = solutionId
  productStore.get_products(getProductQuery.value)
}
const solutionData = computed(() => solutionStore.current_solution)
const sortedAttributes = computed(() => {
  if (solutionData.value) {
    const attributes = [...solutionData.value.attributes].sort(
      (a, b) => a.sortNumber - b.sortNumber
    )
    attributes.map((attr) => {
      const images = [...attr.images].sort((a, b) => a.sortNumber - b.sortNumber)
      return {
        ...attr,
        images: images
      }
    })
    return attributes
  } else {
    return []
  }
})
const products = computed(() => productStore.products)
const estimatesData = computed(() =>
  solutionData.value ? solutionData.value.solutionRoughEstimates : []
)
const isSubmitting = ref(false)

// Solution
let isUpdateSolutionDialog = ref(false)
let dialogUpdateSolutionData = ref<UpdateSolutionPayload>({
  id: -1,
  title: ''
})
function openUpdateSolutionDialog() {
  if (!solutionData.value) {
    return
  }
  dialogUpdateSolutionData.value = {
    id: solutionData.value.id,
    title: solutionData.value.title
  }
  isUpdateSolutionDialog.value = true
}
function submitUpdateSolution() {
  if (dialogUpdateSolutionData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  if (!dialogUpdateSolutionData.value.title.length) {
    _ElMessage({ type: 'error', message: 'タイトルを入力してください' })
    return
  }
  isSubmitting.value = true
  solutionStore
    .update_solution(dialogUpdateSolutionData.value)
    .then(() => {
      isUpdateSolutionDialog.value = false
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}
function confirmDeleteSolution() {
  if (!solutionData.value) {
    return
  }
  _ElConfirm('この処理は取り消せません。実行しますか？', `ソリューションの削除`, {
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル'
  })
    .then(() => {
      solutionStore.delete_solution(solutionId).then(() => {
        router.push('/catalogs?menu=solutionList')
      })
    })
    .catch(() => {})
}

// RoughEstimate
const isLoading = ref(false)
const isUpdateEstimateDialog = ref(false)
const isCreateEstimateDialog = ref(false)
const dialogCreateEstimateData = ref<CreateSolutionRoughEstimatePayload>({
  id: -1,
  title: '',
  note: ''
})
const dialogUpdateEstimateData = ref<UpdateSolutionRoughEstimatePayload>({
  id: -1,
  solutionId: solutionId,
  title: '',
  note: ''
})
function openCreateRoughEstimateDialog() {
  if (solutionId === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  dialogCreateEstimateData.value = {
    id: solutionId,
    title: '',
    note: ''
  }
  isCreateEstimateDialog.value = true
}
function closeCreateEstimateDialog() {
  dialogCreateEstimateData.value = {
    id: -1,
    title: '',
    note: ''
  }
  isCreateEstimateDialog.value = false
}
async function submitRoughEstimate() {
  if (dialogCreateEstimateData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  isSubmitting.value = true

  solutionStore
    .create_solution_rough_estimate(dialogCreateEstimateData.value)
    .then(() => {
      closeCreateEstimateDialog()
    })
    .catch(() => {})
    .finally(() => {
      isSubmitting.value = false
    })
}
function openUpdateRoughEstimateDialog(data: SolutionRoughEstimate) {
  dialogUpdateEstimateData.value = {
    id: data.id,
    solutionId: data.solutionId,
    title: data.title,
    note: data.note
  }
  isUpdateEstimateDialog.value = true
}
function submitUpdateRoughEstimate() {
  if (dialogUpdateEstimateData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  if (!dialogUpdateEstimateData.value.title.length) {
    _ElMessage({ type: 'error', message: 'タイトルを入力してください' })
    return
  }
  isSubmitting.value = true
  solutionStore
    .update_solution_rough_estimate(dialogUpdateEstimateData.value)
    .then(() => {
      isUpdateEstimateDialog.value = false
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}
function confirmDeleteRoughEstimate() {
  if (dialogUpdateEstimateData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  _ElConfirm('この処理は取り消せません。実行しますか？', `概算見積もりファイルの削除`, {
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル'
  })
    .then(() => {
      solutionStore
        .delete_solution_rough_estimate(
          dialogUpdateEstimateData.value.id,
          dialogUpdateEstimateData.value.solutionId
        )
        .then(() => {
          isUpdateEstimateDialog.value = false
        })
        .catch(() => {})
    })
    .catch(() => {})
}

// SolutionAttribute
const defaultAttributeTitle = 'No Title'
let isUpdateAttributeDialog = ref(false)
let dialogUpdateAttributeData = ref<UpdateSolutionAttributePayload>({
  id: -1,
  solutionId: solutionId,
  title: '',
  note: '',
  sortNumber: 0
})

function createAttributeAndOpenUpdateDialog() {
  solutionStore
    .create_solution_attribute({
      solutionId: solutionId,
      title: defaultAttributeTitle,
      sortNumber: sortedAttributes.value.length + 1
    })
    .then((r) => {
      openUpdateAttributeDialog(r)
    })
    .catch(() => {})
}
function openUpdateAttributeDialog(data: SolutionAttributeDetail) {
  dialogUpdateAttributeData.value = {
    id: data.id,
    solutionId: solutionId,
    title: data.title,
    note: data.note,
    sortNumber: data.sortNumber
  }
  isUpdateAttributeDialog.value = true
}
async function closeUpdateAttributeDialog() {
  if (
    dialogUpdateAttributeData.value.title === defaultAttributeTitle &&
    dialogUpdateAttributeData.value.note === '' &&
    !currentPptx.value &&
    currentImages.value.length === 0
  ) {
    await solutionStore
      .delete_solution_attribute(solutionId, dialogUpdateAttributeData.value.id, false)
      .catch(() => {})
  }
  isUpdateAttributeDialog.value = false
}
function submitUpdateAttribute() {
  if (dialogUpdateAttributeData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  if (!dialogUpdateAttributeData.value.title.length) {
    _ElMessage({ type: 'error', message: 'タイトルを入力してください' })
    return
  }
  isSubmitting.value = true
  solutionStore
    .update_solution_attribute(dialogUpdateAttributeData.value)
    .then(() => {
      isUpdateAttributeDialog.value = false
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}
function confirmDeleteAttribute() {
  if (dialogUpdateAttributeData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  _ElConfirm('この処理は取り消せません。実行しますか？', '選定ポイントの削除', {
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル'
  })
    .then(() => {
      solutionStore
        .delete_solution_attribute(solutionId, dialogUpdateAttributeData.value.id)
        .then(() => {
          isUpdateAttributeDialog.value = false
        })
        .catch(() => {})
    })
    .catch(() => {})
}

// sort attributes
const isAttributesSortMode = ref(false)
const sortAttributesdiv = ref<HTMLElement | null>(null)
const sortTargetAttributes = ref<SolutionAttributeDetail[]>([])
function startSortAttributesMode() {
  if (sortedAttributes.value.length === 0) {
    _ElMessage({ type: 'error', message: '対象の選定ポイントがありません' })
    return
  }
  sortTargetAttributes.value = [...sortedAttributes.value]
  useSortable(sortAttributesdiv, sortTargetAttributes, { animation: 150 })
  isAttributesSortMode.value = true
}
function submitSortAttributes() {
  if (solutionId === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  if (sortTargetAttributes.value.length === 0) {
    _ElMessage({ type: 'error', message: '対象の選定ポイントがありません' })
    return
  }
  solutionStore
    .sort_solution_attributes(
      solutionId,
      sortTargetAttributes.value.map((x) => x.id)
    )
    .then(() => {
      isAttributesSortMode.value = false
    })
    .catch(() => {})
}

// upload
const currentPptx = computed(() => {
  if (dialogUpdateAttributeData.value.id === -1) {
    return undefined
  }
  const attr = solutionData.value?.attributes.find(
    (a) => a.id === dialogUpdateAttributeData.value.id
  )
  return attr?.pptx ? attr.pptx : undefined
})
const currentImages = computed(() => {
  if (dialogUpdateAttributeData.value.id === -1 || !solutionData.value) {
    return []
  }
  const attr = solutionData.value.attributes.find(
    (a) => a.id === dialogUpdateAttributeData.value.id
  )
  return attr ? [...attr.images].sort((a, b) => a.sortNumber - b.sortNumber) : []
})
async function submitPptx(file: UploadFile) {
  if (dialogUpdateAttributeData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  const rawFile = validateFile(file, Object.keys(SolutionFileExtensionForDownload))
  if (!rawFile) {
    isLoading.value = false
    return
  }
  isLoading.value = true

  solutionStore
    .upload_attribute_pptx(solutionId, dialogUpdateAttributeData.value.id, rawFile)
    .finally(() => {
      isLoading.value = false
    })
    .catch(() => {})
}
async function submitPng(file: UploadFile) {
  if (dialogUpdateAttributeData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  const rawFile = validateFile(file, ['png'])
  if (!rawFile) {
    isLoading.value = false
    return
  }
  solutionStore.upload_attribute_png(solutionId, dialogUpdateAttributeData.value.id, rawFile)
}

// delete file
function deletePptx() {
  if (dialogUpdateAttributeData.value.id === -1 || currentPptx.value === undefined) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  _ElConfirm('この処理は取り消せません。実行しますか？', 'DL用ファイルの削除', {
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル'
  })
    .then(() => {
      solutionStore.delete_attribute_pptx(solutionId, dialogUpdateAttributeData.value.id)
    })
    .catch(() => {})
}
function deletePng(imageId: number) {
  if (dialogUpdateAttributeData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  _ElConfirm('この処理は取り消せません。実行しますか？', 'Web表示用PNGファイルの削除', {
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル'
  })
    .then(() => {
      solutionStore.delete_attribute_png(solutionId, dialogUpdateAttributeData.value.id, imageId)
    })
    .catch(() => {})
}

// download
function getImageUrl(imageId: number) {
  if (dialogUpdateAttributeData.value.id === -1) {
    return ''
  }
  return solutionStore.get_image_url(solutionId, dialogUpdateAttributeData.value.id, imageId)
}
const pptxDownloadingId = ref(-1)
async function downloadPptx(attributeId: number) {
  try {
    pptxDownloadingId.value = attributeId
    const { data, filename } = await solutionStore.download_attribute_pptx(solutionId, attributeId)
    downloadFile(data, filename)
  } catch (error) {
    console.error('ファイルダウンロードエラー:', error)
  } finally {
    pptxDownloadingId.value = -1
  }
}

// sort images
let isImagesSortMode = ref(false)
const sortImagesdiv = ref<HTMLElement | null>(null)
const sortTargetImages = ref<SolutionAttributeDetail['images']>([])
function startSortImagesMode() {
  if (dialogUpdateAttributeData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  if (currentImages.value.length === 0) {
    _ElMessage({ type: 'error', message: '対象画像がありません' })
    return
  }
  sortTargetImages.value = [...currentImages.value]
  useSortable(sortImagesdiv, sortTargetImages, { animation: 150 })
  isImagesSortMode.value = true
}
function submitSortImages() {
  if (dialogUpdateAttributeData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }

  solutionStore
    .sort_attribute_images(
      solutionId,
      dialogUpdateAttributeData.value.id,
      sortTargetImages.value.map((x) => x.id)
    )
    .then(() => {
      isImagesSortMode.value = false
    })
    .catch(() => {})
}

// transit
function openProductDetail(productId: number) {
  router.push(`/catalogs?menu=productList&resourceId=${productId}`)
}
function openWorksheet() {
  router.push(`/catalogs?menu=solutionList&resourceId=${solutionId}&pageType=worksheet`)
}
</script>

<template>
  <div id="top-category">
    <!-- dialog for Solution-->
    <template v-if="true">
      <el-dialog
        :close-on-press-escape="false"
        v-model="isUpdateSolutionDialog"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header>ソリューションの編集</template>
        <el-form
          :model="dialogUpdateSolutionData"
          label-position="left"
          label-width="120px"
          @submit.prevent
        >
          <el-form-item label="タイトル">
            <el-input v-model="dialogUpdateSolutionData.title" />
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="el-dialog-footer_both_side">
            <span>
              <el-button link class="danger" @click="confirmDeleteSolution()">削除する</el-button>
            </span>
            <span>
              <el-button link @click="isUpdateSolutionDialog = false">閉じる</el-button>
              <el-button :loading="isSubmitting" @click="submitUpdateSolution">保存する</el-button>
            </span>
          </div>
        </template>
      </el-dialog>
    </template>
    <!-- dialog for Attribute-->
    <template v-if="true">
      <el-dialog
        :close-on-press-escape="false"
        v-model="isUpdateAttributeDialog"
        top="10vh"
        width="80%"
        class="ix-dialog"
        :close-on-click-modal="false"
      >
        <template #header>選定ポイントの編集</template>
        <el-form
          :model="dialogUpdateAttributeData"
          label-position="left"
          label-width="120px"
          @submit.prevent
        >
          <el-form-item label="タイトル">
            <el-input v-model="dialogUpdateAttributeData.title" />
          </el-form-item>
          <el-form-item label="本文">
            <el-input v-model="dialogUpdateAttributeData.note" type="textarea" :rows="5" />
          </el-form-item>
          <el-form-item label="DL用ファイル">
            <span v-if="currentPptx">
              {{ currentPptx.originalFileName }}.{{ currentPptx.fileExtension }}
              <el-button link class="danger" @click="deletePptx">削除する</el-button>
            </span>
            <el-upload
              v-else
              class="upload"
              v-loading.fullscreen="isLoading"
              action=""
              :auto-upload="false"
              :show-file-list="false"
              :on-change="submitPptx"
            >
              <template #default>
                <el-button>ファイル登録</el-button>
                <div class="note">
                  (対応ファイル: {{ `${Object.keys(SolutionFileExtensionForDownload)}` }})
                </div>
              </template>
            </el-upload>
          </el-form-item>
          <el-form-item label="Web表示用PNG">
            <template v-if="!isImagesSortMode">
              <el-upload
                class="upload"
                v-loading.fullscreen="isLoading"
                action=""
                multiple
                :auto-upload="false"
                :show-file-list="false"
                :on-change="submitPng"
              >
                <template #default>
                  <el-button>ファイル登録</el-button>
                  <div class="note">(推奨アスペクト比16:9)</div>
                </template>
              </el-upload>
              <el-button
                v-if="currentImages.length"
                @click="startSortImagesMode"
                style="margin-left: 8px"
                >並び替え</el-button
              >
              <div class="attribute-images-wrapper">
                <div
                  class="attribute-image"
                  v-for="img in currentImages"
                  :key="img.id"
                  @click="deletePng(img.id)"
                >
                  <img :src="getImageUrl(img.id)" crossOrigin="use-credentials" />
                </div>
              </div>
            </template>
            <div v-show="isImagesSortMode">
              <el-button link @click="isImagesSortMode = false">キャンセル</el-button>
              <el-button @click="submitSortImages">反映する</el-button>
              <div ref="sortImagesdiv" class="attribute-images-wrapper">
                <div class="attribute-image sort" v-for="img in sortTargetImages" :key="img.id">
                  <img :src="getImageUrl(img.id)" crossOrigin="use-credentials" />
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="el-dialog-footer_both_side">
            <span>
              <el-button link class="danger" @click="confirmDeleteAttribute()">削除する</el-button>
            </span>
            <span>
              <el-button link @click="closeUpdateAttributeDialog">閉じる</el-button>
              <el-button :loading="isSubmitting" @click="submitUpdateAttribute">保存する</el-button>
            </span>
          </div>
        </template>
      </el-dialog>
    </template>
    <!-- dialog for RoughEstimate-->
    <template v-if="true">
      <el-dialog
        :close-on-press-escape="false"
        v-model="isCreateEstimateDialog"
        @close="closeCreateEstimateDialog"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header>概算見積もり事例</template>
        <el-form
          :model="dialogCreateEstimateData"
          label-position="left"
          label-width="120px"
          @submit.prevent
        >
          <el-form-item label="タイトル">
            <el-input v-model="dialogCreateEstimateData.title" />
          </el-form-item>
          <el-form-item label="内容">
            <el-input v-model="dialogCreateEstimateData.note" type="textarea" />
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="el-dialog-footer">
            <span>
              <el-button link @click="closeCreateEstimateDialog()">閉じる</el-button>
              <el-button :loading="isSubmitting" @click="submitRoughEstimate()">保存する</el-button>
            </span>
          </div>
        </template>
      </el-dialog>
    </template>
    <template v-if="true">
      <el-dialog
        :close-on-press-escape="false"
        v-model="isUpdateEstimateDialog"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header>概算見積もり事例の編集</template>
        <el-form
          :model="dialogUpdateEstimateData"
          label-position="left"
          label-width="120px"
          @submit.prevent
        >
          <el-form-item label="タイトル">
            <el-input v-model="dialogUpdateEstimateData.title" />
          </el-form-item>
          <el-form-item label="内容">
            <el-input v-model="dialogUpdateEstimateData.note" type="textarea" />
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="el-dialog-footer_both_side">
            <span>
              <el-button link class="danger" @click="confirmDeleteRoughEstimate()"
                >削除する</el-button
              >
            </span>
            <span>
              <el-button link @click="isUpdateEstimateDialog = false">閉じる</el-button>
              <el-button :loading="isSubmitting" @click="submitUpdateRoughEstimate"
                >保存する</el-button
              >
            </span>
          </div>
        </template>
      </el-dialog>
    </template>

    <!-- template -->
    <template v-if="!solutionData"> Now Loading... </template>
    <template v-else>
      <div class="topheader">
        <div class="topheader-title">{{ solutionData.title }}</div>
        <div v-if="isAdmin" class="topheader-action">
          <el-button @click="openUpdateSolutionDialog()">編集する</el-button>
        </div>
      </div>
      <div class="subheader">
        <div class="subheader-title">
          商材リスト<el-button style="margin-left: 12px" @click="openWorksheet()"
            >比較表を管理する</el-button
          >
        </div>
      </div>
      <div class="ix-table">
        <div
          class="ix-table-row clickable"
          v-for="(product, i) in products"
          :key="product.id"
          @click="openProductDetail(product.id)"
        >
          <div class="ix-table-column th">#{{ i + 1 }}</div>
          <div class="ix-table-column">{{ product.name }}</div>
        </div>
        <div v-if="!products.length" class="row-placeholder">データ未登録です</div>
      </div>
      <el-divider />
      <div class="subheader">
        <div class="subheader-title">概算見積もり事例</div>
        <div v-if="isAdmin" class="subheader-action">
          <el-button @click="openCreateRoughEstimateDialog()">新規登録</el-button>
        </div>
      </div>
      <table class="ix-table">
        <tr
          class="ix-table-row clickable"
          v-for="(item, i) in estimatesData"
          :key="item.id"
          @click="isAdmin ? openUpdateRoughEstimateDialog(item) : undefined"
        >
          <th class="th">#{{ i + 1 }}</th>
          <td class="ix-table-column title">{{ item.title }}</td>
          <td class="ix-table-column estimate-note">{{ item.note }}</td>
        </tr>
        <tr v-if="!estimatesData.length" class="row-placeholder">
          データ未登録です
        </tr>
      </table>
      <el-divider />
      <div class="subheader">
        <div class="subheader-title">選定ポイント</div>
        <div v-if="isAdmin" class="subheader-action">
          <el-button v-show="isAttributesSortMode" @click="isAttributesSortMode = false"
            >キャンセル</el-button
          >
          <el-button v-show="isAttributesSortMode" @click="submitSortAttributes()"
            >反映する</el-button
          >
          <el-button v-show="!isAttributesSortMode" @click="startSortAttributesMode()"
            >並び替え</el-button
          >
          <el-button v-show="!isAttributesSortMode" @click="createAttributeAndOpenUpdateDialog()"
            >新規登録</el-button
          >
        </div>
      </div>
      <table v-show="!isAttributesSortMode" class="ix-table">
        <tr
          class="ix-table-row clickable"
          v-for="(attr, i) in sortedAttributes"
          :key="attr.id"
          @click="isAdmin ? openUpdateAttributeDialog(attr) : undefined"
        >
          <th class="th">#{{ i + 1 }}</th>
          <td class="ix-table-column attribute-title">{{ attr.title }}</td>
          <td class="ix-table-column description">{{ attr.note }}</td>
          <td class="ix-table-column">DL用ファイル: {{ attr.pptx ? 'あり' : 'なし' }}</td>
          <td class="ix-table-column">png: {{ attr.images.length }}枚</td>
          <td class="ix-table-column download-button">
            <el-button
              :loading="pptxDownloadingId === attr.id"
              :disabled="!attr.pptx"
              @click.stop="() => downloadPptx(attr.id)"
              >ファイルダウンロード</el-button
            >
          </td>
        </tr>
        <tr v-if="!sortedAttributes.length" class="row-placeholder">
          データ未登録です
        </tr>
      </table>
      <table ref="sortAttributesdiv" v-show="isAttributesSortMode" class="ix-table">
        <tr class="ix-table-row sort" v-for="(attr, i) in sortTargetAttributes" :key="attr.id">
          <th class="th">#{{ i + 1 }}</th>
          <td class="ix-table-column attribute-title">{{ attr.title }}</td>
          <td class="ix-table-column description">{{ attr.note }}</td>
          <td class="ix-table-column">DL用ファイル: {{ attr.pptx ? 'あり' : 'なし' }}</td>
          <td class="ix-table-column">png: {{ attr.images.length }}枚</td>
        </tr>
      </table>
    </template>
  </div>
</template>
<style scoped>
#top-category {
  padding-right: 24px;
  height: 100%;
  color: v-bind('colors.text.base');
}
.topheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.topheader-title {
  font-size: 24px;
  font-weight: bold;
}
.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.subheader-title {
  font-size: 16px;
  font-weight: bold;
  height: 32px;
  line-height: 32px;
}
.ix-table-row {
  gap: 20px;
  padding-left: 16px;
}
.estimate-note {
  width: 500px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.title {
  width: 200px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.th {
  text-align: left;
  color: v-bind('colors.text.lighter');
}
.download-button {
  text-align: right;
}
.attribute-title,
.description {
  width: 20vw;
  flex-grow: 2;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.ix-table-column.tags {
  display: flex;
  flex-wrap: wrap;
  max-width: 50%;
}
.ix-table-column.end {
  text-align: left;
}
.row-placeholder {
  padding-left: 16px;
  font-size: 12px;

  color: v-bind('colors.text.disabled');
}
.attribute-images-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}
.attribute-image {
  width: 240px;
  height: auto;
  cursor: pointer;
  border: 1px solid v-bind('colors.border.base');
}
.sort {
  cursor: grab;
}
.sort:active {
  cursor: grabbing;
}
.attribute-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.caption {
  font-size: 12px;
  font-weight: bold;
  margin-left: 8px;
  color: v-bind('colors.text.lighter');
}
.note {
  margin-left: 10px;
}
</style>
