<script setup lang="ts">
import { computed, ref } from 'vue'
import { useUserStore } from '@/stores/userStore'
import { colorPalette as colors } from '@/utils/enums'
import { _ElConfirm, _ElMessage } from '@/utils/element-plus-wrapper'
import type { UserGroup, UpdateUserGroupPayload, GetUserGroupsQuery } from '@/types/user.type'

const userStore = useUserStore()
const getUserGroupsQuery = ref<GetUserGroupsQuery>({
  page: 1,
  limit: 50
})

userStore.get_user_groups(getUserGroupsQuery.value)
const groups = computed(() => {
  return userStore.user_groups
})
const isSubmitting = ref(false)

// pagination
const currentPage = computed({
  get: () => getUserGroupsQuery.value.page,
  set: (val) => (getUserGroupsQuery.value.page = val)
})
function changePage(v: number): void {
  getUserGroupsQuery.value.page = v
  userStore.get_user_groups(getUserGroupsQuery.value)
}
const totalPages = computed(() => {
  let num = 1
  if (userStore.user_groups_stats) {
    num = userStore.user_groups_stats.totalPages
  }
  return num
})

// create
const isCreateMode = ref(false)
const dialogCreateData = ref({
  name: ''
})
function openCreateDialog() {
  dialogCreateData.value = {
    name: ''
  }
  isCreateMode.value = true
}
function submitCreate() {
  if (!dialogCreateData.value.name.length) {
    _ElMessage({ type: 'error', message: '名前を入力してください' })
    return
  }
  isSubmitting.value = true
  userStore
    .create_user_group(dialogCreateData.value.name)
    .then(() => {
      isCreateMode.value = false
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}
// update
const isUpdateGroupDialog = ref(false)
const dialogUpdateData = ref<UpdateUserGroupPayload>({
  id: -1,
  name: ''
})
function openUpdateDialog(data: UserGroup) {
  dialogUpdateData.value = {
    id: data.id,
    name: data.name
  }
  isUpdateGroupDialog.value = true
}
function submitUpdateGroup() {
  if (dialogUpdateData.value.id !== -1) {
    isSubmitting.value = true
    userStore
      .update_user_group_name(dialogUpdateData.value)
      .then(() => {
        isUpdateGroupDialog.value = false
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => {
        isSubmitting.value = false
      })
  }
}

//delete
function confirmDeleteGroup() {
  if (dialogUpdateData.value.id === -1) {
    return
  }
  const target = dialogUpdateData.value
  _ElConfirm('この処理は取り消せません。実行しますか？', `ユーザグループの削除`, {
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル'
  }).then(() => {
    userStore
      .delete_user_group(target.id)
      .then(() => {
        isUpdateGroupDialog.value = false
      })
      .catch(() => {})
  })
}
</script>

<template>
  <!-- dialog -->
  <template v-if="true">
    <el-dialog
      :close-on-press-escape="false"
      v-model="isCreateMode"
      top="10vh"
      width="50%"
      class="ix-dialog"
    >
      <template #header>ユーザグループの新規登録</template>
      <el-form :model="dialogCreateData" label-position="left" label-width="120px" @submit.prevent>
        <el-form-item label="名前">
          <el-input v-model="dialogCreateData.name" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span>
          <el-button link @click="isCreateMode = false">閉じる</el-button>
          <el-button :loading="isSubmitting" @click="submitCreate">保存する</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      :close-on-press-escape="false"
      v-model="isUpdateGroupDialog"
      top="10vh"
      width="50%"
      class="ix-dialog"
    >
      <template #header>ユーザグループ名の編集</template>
      <el-form :model="dialogUpdateData" label-position="left" label-width="120px" @submit.prevent>
        <el-form-item label="名前">
          <el-input v-model="dialogUpdateData.name" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="el-dialog-footer_both_side">
          <span>
            <el-button link class="danger" @click="confirmDeleteGroup()">削除する</el-button>
          </span>
          <span>
            <el-button link @click="isUpdateGroupDialog = false">閉じる</el-button>
            <el-button :loading="isSubmitting" @click="submitUpdateGroup">保存する</el-button>
          </span>
        </div>
      </template>
    </el-dialog>
  </template>
  <div class="subheader">
    <div class="subheader-action">
      <el-button @click="openCreateDialog()">新規登録</el-button>
    </div>
  </div>
  <div id="user-group-list">
    <div class="ix-table">
      <div
        class="ix-table-row clickable"
        v-for="(row, i) in groups"
        :key="row.id"
        @click="openUpdateDialog(row)"
      >
        <div class="ix-table-column th">#{{ i + 1 }}</div>
        <div class="ix-table-column">{{ row.name }}</div>
      </div>
    </div>
  </div>
  <div class="pagination-footer">
    <el-pagination
      class="normal"
      :hide-on-single-page="true"
      :page-count="totalPages"
      :current-page="currentPage"
      layout="prev, pager, next"
      @current-change="changePage"
    />
  </div>
</template>
<style scoped>
#user-group-list {
  width: 80%;
  height: 100%;
  padding-top: 12px;
  color: v-bind('colors.text.base');
}
.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  float: right;
  padding-right: 24px;
}
.ix-table-row {
  gap: 20px;
  padding-left: 16px;
}
</style>
