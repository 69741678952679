<script setup lang="ts">
import { colorPalette as colors } from '@/utils/enums'
import { computed, watch, toRefs, ref } from 'vue'
import { useCaseItemStore } from '@/stores/caseItemStore'
import { CaseItemTypeLabel } from '@/types/caseItem.type'
import { getHashedId } from '@/utils/hash.helper'
import { useRouter } from 'vue-router'
import {
  type CaseItemMessageDetail,
  CaseItemStatus,
  type UpdateCaseItemPayload
} from '@/types/caseItem.type'
import { useUserStore } from '@/stores/userStore'
import { UserRole } from '@/types/user.type'
import { _ElConfirm, _ElMessage } from '@/utils/element-plus-wrapper'
import ChatInput from '@/components/ChatInput.vue'
import { MoreFilled } from '@element-plus/icons-vue'
import { parseMessage } from '@/utils/text.helper'
import { downloadFile } from '@/utils/file.helper'
import { datetimeFormatter } from '@/utils/date.formatter'

const props = withDefaults(
  defineProps<{
    caseId: number
  }>(),
  {
    caseId: -1
  }
)

// init
const { caseId } = toRefs(props)
const router = useRouter()
const userStore = useUserStore()
userStore.get_users({
  userGroupId: 1,
  roles: [UserRole.admin, UserRole.supervisor]
})
const filteredUsers = computed(() => {
  return userStore.users.filter(
    (user) => user.role === UserRole.supervisor || user.role === UserRole.admin
  )
})
const caseItemStore = useCaseItemStore()
const currentCaseItem = computed(() => caseItemStore.current_case_item)
const isSubmitting = ref(false)
function initialize() {
  caseItemStore.clear_current_case_item()
  if (caseId.value !== -1) {
    caseItemStore.get_case_item_detail(caseId.value).catch(() => {})
  }
}

initialize()

watch(caseId, async () => {
  initialize()
})

// edit
const updateCasePayload = ref<UpdateCaseItemPayload>({
  internalMemo: '',
  assigneeId: undefined,
  caseItemStatus: undefined
})
const isEditMode = ref(false)
function openEditDialog() {
  if (!currentCaseItem.value) {
    return
  }
  updateCasePayload.value = {
    internalMemo: currentCaseItem.value.internalMemo,
    assigneeId: currentCaseItem.value.assigneeId,
    caseItemStatus: currentCaseItem.value.caseItemStatus
  }
  isEditMode.value = true
}
async function updateCaseItem() {
  if (
    updateCasePayload.value.caseItemStatus === 'closed' &&
    !updateCasePayload.value.internalMemo
  ) {
    _ElMessage({
      type: 'error',
      message: 'ステータスをclosedにする場合は内部メモを入力してください'
    })
    return
  }
  isSubmitting.value = true
  if (updateCasePayload.value.assigneeId == -1) {
    try {
      await caseItemStore.unassign(caseId.value)
    } catch (e) {
      isSubmitting.value = false
      return
    }
    delete updateCasePayload.value.assigneeId
  }
  caseItemStore
    .update_case_item(caseId.value, updateCasePayload.value)
    .then(() => {
      isEditMode.value = false
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}

function navigateToProjectDetail(id: number) {
  router.push(`/projects?id=${getHashedId(id)}`)
}

// message
const isDownloading = ref(false)
async function downloadMessageAttachment(
  caseItemId: number,
  caseItemMessageId: number,
  attachmentId: number,
  fileExtension: string
) {
  try {
    isDownloading.value = true
    const { data, filename } = await caseItemStore.download_attachment(
      caseItemId,
      caseItemMessageId,
      attachmentId,
      fileExtension
    )
    downloadFile(data, filename)
  } catch (e) {
    console.error(e)
    _ElMessage({
      type: 'error',
      message: 'ファイルのダウンロードに失敗しました'
    })
  } finally {
    isDownloading.value = false
  }
}
function onClickMessageDelete(messageId: number) {
  _ElConfirm('この処理は取り消せません。実行しますか？', 'メッセージの削除', {
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル'
  })
    .then(() => {
      if (!currentCaseItem.value) {
        _ElMessage({
          type: 'error',
          message: 'メッセージの削除に失敗しました'
        })
        return
      }
      caseItemStore.delete_message(currentCaseItem.value.id, messageId)
    })
    .catch((e) => {
      console.error(e)
    })
}
function onClickAttachmentClose(messageId: number, attachmentId: number) {
  _ElConfirm('この処理は取り消せません。実行しますか？', '添付ファイルの削除', {
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル'
  })
    .then(() => {
      if (!currentCaseItem.value) {
        _ElMessage({
          type: 'error',
          message: '添付ファイルの削除に失敗しました'
        })
        return
      }
      caseItemStore.delete_attachment(currentCaseItem.value.id, messageId, attachmentId)
    })
    .catch((e) => {
      console.error(e)
    })
}
function canDeleteMessageAndAttachment(message: CaseItemMessageDetail) {
  if (!userStore.current_user) {
    return false
  }
  if (userStore.current_user.role === UserRole.admin) {
    return true
  }
  return isCurrentUserTheAuthor(message)
}
function isCurrentUserTheAuthor(message: CaseItemMessageDetail) {
  if (!userStore.current_user) {
    return false
  }
  return message.author.id === userStore.current_user.id
}

// chat input
const chatInput = ref<InstanceType<typeof ChatInput> | null>(null)
async function handleSend(
  chatInputValue: string,
  attachedFiles: File[],
  notifyCaseItemAuthor: boolean
) {
  if (!chatInput.value) {
    return
  }
  const createCaseItemMessagePayload = {
    text: chatInputValue,
    notifyCaseItemAuthor: notifyCaseItemAuthor
  }

  const hasFiles = attachedFiles.length > 0
  chatInput.value.makeLoading(true)
  const res = await caseItemStore
    .send_message(caseId.value, createCaseItemMessagePayload)
    .catch(() => {
      _ElMessage({
        message: 'メッセージの送信に失敗しました',
        type: 'error'
      })
    })

  if (!res) {
    // テキスト送信のエラー時はメッセージをクリアせずreturn
    chatInput.value.makeLoading(false)
    return
  }

  if (!hasFiles) {
    chatInput.value.makeLoading(false)
    clearInputAndScroll()
    _ElMessage({
      message: 'メッセージを送信しました',
      type: 'success'
    })
    return
  }

  await Promise.allSettled(
    attachedFiles.map((file) => {
      return caseItemStore.add_attachment(caseId.value, res.id, file)
    })
  ).then((results) => {
    const failedFiles = results
      .filter((result) => result.status === 'rejected')
      .map((_result, index) => attachedFiles[index].name)

    if (failedFiles.length === 0) {
      _ElMessage({
        message: 'メッセージを送信しました',
        type: 'success'
      })
    } else {
      const failedFilesMessage = failedFiles.join(', ')
      _ElMessage({
        message: `ファイルのアップロードに失敗しました: ${failedFilesMessage}`,
        type: 'error'
      })
    }
  })

  chatInput.value.makeLoading(false)
  clearInputAndScroll()
}
function clearInputAndScroll() {
  if (chatInput.value) {
    chatInput.value.clearInput()
    chatInput.value.clearFiles()
    scrollToBottomMessage()
  }
}
function scrollToBottomMessage() {
  // 1番下までscrollする
  const wrapperDivs = document.querySelectorAll('.el-main')
  if (wrapperDivs.length) {
    wrapperDivs[0].scrollTop = wrapperDivs[0].scrollHeight
  }
}
</script>

<template>
  <template v-if="true">
    <el-dialog
      :close-on-press-escape="false"
      v-model="isEditMode"
      top="10vh"
      width="50%"
      class="ix-dialog"
    >
      <template #header>見積もり依頼 / 相談の編集</template>
      <el-form>
        <el-form-item label="担当者" label-width="120px">
          <el-select placeholder="選択する" filterable v-model="updateCasePayload.assigneeId">
            <el-option
              v-for="item in [{ id: null, name: '未選択' }, ...filteredUsers]"
              :key="item.id || 'unselected'"
              :label="item.name"
              :value="item.id || -1"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="ステータス" label-width="120px">
          <el-select placeholder="選択する" v-model="updateCasePayload.caseItemStatus">
            <el-option
              v-for="item in Object.keys(CaseItemStatus)"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="内部メモ" label-width="120px">
          <el-input v-model="updateCasePayload.internalMemo" type="textarea" style="width: 500px" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span>
          <el-button link @click="isEditMode = false">閉じる</el-button>
          <el-button :loading="isSubmitting" @click="updateCaseItem">保存する</el-button>
        </span>
      </template>
    </el-dialog>
  </template>
  <div v-if="!currentCaseItem">loading...</div>
  <div v-else class="case-item-detail">
    <div class="title">
      <div class="page-title">{{ CaseItemTypeLabel[currentCaseItem.caseItemType] }}の詳細</div>
      <el-button @click="openEditDialog">編集</el-button>
    </div>
    <div class="attributes-wrapper">
      <div class="attribute-row">
        <div>
          <span class="tag-name">ユーザ</span>
          <template v-if="currentCaseItem.projectItem.project.user">
            {{ currentCaseItem.projectItem.project.user.name }}
          </template>
          <template v-else>なし</template>
        </div>
        <div><span class="tag-name">ステータス</span>{{ currentCaseItem.caseItemStatus }}</div>
        <div>
          <span class="tag-name">担当者</span>
          <template v-if="currentCaseItem.assigneeUser">
            {{ currentCaseItem.assigneeUser.name }}
          </template>
          <template v-else>なし</template>
        </div>
      </div>
      <div class="attribute-row vertical">
        <span class="tag-name">内部メモ</span>
        <div class="internal-memo" @click="openEditDialog">
          <div>
            {{ currentCaseItem.internalMemo }}
          </div>
        </div>
      </div>
    </div>
    <div class="section-title">案件基本情報</div>
    <div class="project-detail">
      <div class="project-detail-item">
        <div>
          <span class="project-detail-tag-name">案件名</span>
          <div class="project-detail-item-text">
            <div
              class="clickable vertical-text-truncate"
              @click="navigateToProjectDetail(currentCaseItem.projectItem.projectId)"
            >
              {{ currentCaseItem.projectItem.project.title }}
            </div>
          </div>
        </div>
        <div>
          <span class="project-detail-tag-name">業種</span>
          <div class="project-detail-item-text">
            <template v-if="currentCaseItem.projectItem.project.topCategory">
              {{ currentCaseItem.projectItem.project.topCategory.name }}
            </template>
            <template v-else>不明</template>
          </div>
        </div>
      </div>
      <div class="project-detail-item">
        <div>
          <span class="project-detail-tag-name">主目的</span>
          <div class="project-detail-item-text">
            <template v-if="currentCaseItem.projectItem.project.objective">
              {{ currentCaseItem.projectItem.project.objective.title }}
            </template>
            <template v-else>不明</template>
          </div>
        </div>
        <div>
          <span class="project-detail-tag-name">ソリューション</span>
          <div class="project-detail-item-text">
            {{ currentCaseItem.projectItem.solution.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="section-title">メッセージ</div>
    <div class="message-wrapper">
      <div class="message" v-for="message in currentCaseItem.messages" :key="message.id">
        <div class="message-header">
          <div class="author-name">
            <template v-if="isCurrentUserTheAuthor(message)"> あなた </template>
            <template v-else-if="message.author">
              {{ `【${message.author.userGroup.name}】${message.author.name}` }}
            </template>
            <template v-else> 不明なユーザ </template>
          </div>
          <el-popover
            placement="top-start"
            :width="150"
            trigger="hover"
            content="メッセージの削除"
            v-if="canDeleteMessageAndAttachment(message)"
          >
            <template #reference>
              <el-button
                link
                :icon="MoreFilled"
                style="font-size: 16px"
                @click="() => onClickMessageDelete(message.id)"
              />
            </template>
          </el-popover>
        </div>
        <div
          class="message-text"
          :class="isCurrentUserTheAuthor(message) ? 'mine' : 'others'"
          v-html="parseMessage(message.text)"
        />
        <div v-if="message.caseItemMessageAttachments.length" class="file-display-section">
          <div v-for="attachment in message.caseItemMessageAttachments" :key="attachment.id">
            <el-tag
              type="info"
              :closable="canDeleteMessageAndAttachment(message)"
              @close="() => onClickAttachmentClose(message.id, attachment.id)"
            >
              <el-button
                :loading="isDownloading"
                link
                type="primary"
                @click="
                  downloadMessageAttachment(
                    currentCaseItem.id,
                    message.id,
                    attachment.id,
                    attachment.fileExtension
                  )
                "
              >
                <div class="file-name">
                  <svg
                    fill="currentColor"
                    width="16px"
                    height="16px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.1,12.4l-6.2,6.2c-1.7,1.7-4.4,1.7-6,0c-1.7-1.7-1.7-4.4,0-6l8-8c1-0.9,2.5-0.9,3.5,0c1,1,1,2.6,0,3.5l-6.9,6.9c-0.3,0.3-0.8,0.3-1.1,0c0,0,0,0,0,0c-0.3-0.3-0.3-0.8,0-1.1l5.1-5.1c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L8,12.6c-1.1,1.1-1.1,2.8,0,3.9c1.1,1,2.8,1,3.9,0l6.9-6.9c1.8-1.8,1.8-4.6,0-6.4c-1.8-1.8-4.6-1.8-6.4,0l-8,8c-1.2,1.2-1.8,2.8-1.8,4.4c0,3.5,2.8,6.2,6.3,6.2c1.7,0,3.2-0.7,4.4-1.8l6.2-6.2c0.4-0.4,0.4-1,0-1.4S18.5,12,18.1,12.4z"
                    />
                  </svg>
                  {{ attachment.fileName || 'ファイル名なし' }}.{{
                    attachment.fileExtension || '拡張子なし'
                  }}
                </div>
              </el-button>
            </el-tag>
          </div>
        </div>
        <div class="created-at">
          {{ datetimeFormatter(message.createdAt) }}
        </div>
      </div>
    </div>
    <div class="chat-input-wrapper">
      <ChatInput
        ref="chatInput"
        :enable-files-option="true"
        :enableNotifyOption="true"
        @send="handleSend"
      />
    </div>
  </div>
</template>
<style scoped>
.case-item-detail {
  margin: 0;
  padding-bottom: 66px;
  display: flex;
  flex-direction: column;
}
/* common */
.section-title {
  font-size: 16px;
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 6px;
}
.clickable {
  cursor: pointer;
  text-decoration: underline;
}
/* meta info */
.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  height: 32px;
  line-height: 32px;
  .page-title {
    font-size: 18px;
    font-weight: bold;
  }
}
.attributes-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.attribute-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  .tag-name {
    background-color: v-bind('colors.bg.gray05');
    color: v-bind('colors.text.white');
    display: inline-block;
    margin-right: 6px;
    min-width: 70px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    padding: 4px 8px;
  }
  &.vertical {
    flex-direction: column;
    gap: 0;
    .tag-name {
      width: 70px;
    }
    .internal-memo {
      cursor: pointer;
      height: 75px;
      overflow-y: scroll;
      border: 1px solid v-bind('colors.border.base');
      padding: 8px;
    }
  }
}
/* project */
.project-detail {
  display: flex;
  border: 1px solid v-bind('colors.border.base');
  padding: 8px;
  .project-detail-item {
    width: 50%;
    .project-detail-tag-name {
      margin-right: 10px;
      font-size: 12px;
      color: v-bind('colors.text.lighter');
    }
    .vertical-text-truncate {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .project-detail-item-text {
      margin-left: 10px;
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
}

/* messages */
.message-wrapper {
  border: 1px solid v-bind('colors.border.base');
  height: 100%;
  padding: 14px;
}
.message {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .message-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 22px;
    line-height: 22px;
    .author-name {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .message-text {
    font-size: 14px;
    line-height: 28px;
    font-weight: normal;
    padding: 8px;
    border: 1px solid v-bind('colors.border.base');
    border-radius: 4px;
    white-space: pre-wrap;
    width: 100%;
    &.mine {
      background-color: v-bind('colors.utility.greenBg');
    }
    &.others {
      background-color: v-bind('colors.bg.gray01');
    }
  }
  .created-at {
    margin-top: 2px;
    font-size: 12px;
    color: v-bind('colors.text.disabled');
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
.file-display-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 4px;
  .file-name {
    max-width: 600px;
    display: flex;
    gap: 2px;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    svg {
      color: v-bind('colors.text.lighter');
      flex-shrink: 0;
    }
  }
}
/* chat input */
.chat-input-wrapper {
  position: fixed;
  bottom: 0;
  width: calc(60% - 32px); /* CaseHomeViewのel-asideがwidth: 40%だから */
  background-color: v-bind('colors.bg.gray01');
  &.closed {
    font-size: 14px;
    font-weight: bold;
    color: v-bind('colors.text.disabled');
    cursor: not-allowed;
  }
}
/* dialog */
.el-select {
  width: 280px;
}
</style>
