export const TokenValidationActionType = {
  emailVerification: 'emailVerification',
  forgetPassword: 'forgetPassword'
} as const
export type TokenValidationActionType = keyof typeof TokenValidationActionType

export type TokenValidationPayload = {
  token: string
  actionType: TokenValidationActionType
}

export const ValidationTokenErrorMessage = {
  invalid: 'Token is invalid',
  expired: 'Token is expired',
  email: 'Email should be verified first.'
} as const
export type ValidationTokenErrorMessage = keyof typeof ValidationTokenErrorMessage
