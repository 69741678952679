<script setup lang="ts">
import { colorPalette as colors } from '@/utils/enums'
import { datetimeFormatter } from '@/utils/date.formatter'
import { extractIdFromHash, getHashedId } from '@/utils/hash.helper'
import type { ChatMessage } from '@/types/assistant.type'
import { marked } from '@/utils/text.helper'
import { useRoute } from 'vue-router'
import { computed, watch } from 'vue'
import { useAssistantStore } from '@/stores/assistantStore'

const assistantStore = useAssistantStore()
const route = useRoute()
const threadId = computed(() => {
  return (() => {
    try {
      return typeof route.query.threadIdHash === 'string'
        ? extractIdFromHash(route.query.threadIdHash)
        : -1
    } catch (e) {
      return -1
    }
  })()
})
const currentThread = computed(() => assistantStore.current_thread)
const messages = computed(() => currentThread.value?.messages || [])

function navigateToProjectItemDetail(projectId: number, projectItemId: number) {
  const hashedProjectId = getHashedId(projectId)
  const hashedProjectItemId = getHashedId(projectItemId)
  let w = window.open()
  if (w) {
    w.opener = null
    w.location = `/projects?id=${hashedProjectId}&itemId=${hashedProjectItemId}`
  }
}
function parseMessage(message: ChatMessage) {
  let text = message.text

  // Replace \n with two spaces and \n for markdown line breaks
  text = text.replace(/\\n/g, '  \n')
  return marked.parse(text)
}

function init() {
  if (threadId.value !== -1) {
    assistantStore.get_thread_detail(threadId.value)
  } else {
    assistantStore.clear_current_thread()
  }
}

init()

watch(threadId, () => {
  init()
})
</script>

<template>
  <div>
    <template v-if="!currentThread"> 閲覧したいAI chat履歴を選択してください。 </template>
    <template v-else>
      <div class="local-menu-header">
        {{ currentThread.projectItem.title }}
        <div>
          <el-button
            link
            class="underline"
            @click="
              navigateToProjectItemDetail(
                currentThread.projectItem.projectId,
                currentThread.projectItem.id
              )
            "
          >
            詳細ページ
          </el-button>
        </div>
      </div>
      <div class="messages-container">
        <div
          v-for="m in messages"
          :key="m.id"
          class="message-item"
          :class="m.role == 'user' ? 'mine' : 'others'"
        >
          <div class="message-header">
            <div class="author-name">
              <template v-if="m.role == 'user'"> ユーザ </template>
              <template v-else>AI </template>
            </div>
          </div>
          <div
            class="message-text"
            :class="m.role == 'user' ? 'mine' : 'others'"
            v-html="parseMessage(m)"
          />
          <div class="created-at">
            {{ datetimeFormatter(m.createdAt) }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>
.local-menu-header {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 30px;
}
.underline {
  text-decoration: underline;
}
.messages-container {
  height: 80vh;
  min-height: 300px;
  width: 95%;
  overflow-y: scroll;
  padding-top: 8px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  border: 1px solid v-bind('colors.border.base');
}
.message-item {
  width: 90%;
  display: flex;
  flex-direction: column;
  &.mine {
    align-items: flex-end;
  }
  &.others {
    align-items: flex-start;
  }
}
.message-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 22px;
  line-height: 22px;
  .author-name {
    font-weight: bold;
    font-size: 12px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.message-text {
  margin-top: 2px;
  padding: 8px;
  border: 1px solid v-bind('colors.border.base');
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  &.mine {
    background-color: v-bind('colors.utility.greenBg');
  }
  &.others {
    background-color: v-bind('colors.bg.gray01');
  }
}
</style>
