<script setup lang="ts">
import { useProjectStore } from '@/stores/projectStore'
import { computed } from 'vue'
import CommonWorksheet from '../CommonWorksheet.vue'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import type { ProjectItemProductPayload } from '@/types/project.type'

const props = defineProps<{
  projectId: number
  projectItemId: number
}>()
const projectStore = useProjectStore()
projectStore.get_project_item_detail(props.projectId, props.projectItemId)
const projectItemData = computed(() => projectStore.current_project_item)
const productIds = computed(() =>
  projectItemData.value ? projectItemData.value.products.map((p) => p.id) : []
)

function toggleSelectedProduct(productId: number) {
  if (!projectItemData.value) {
    _ElMessage({ type: 'error', message: 'データが取得できませんでした' })
    return
  }
  const payload: ProjectItemProductPayload = {
    projectId: props.projectId,
    projectItemId: props.projectItemId,
    productId
  }
  if (projectItemData.value.products.some((p) => p.id === productId)) {
    projectStore.remove_project_item_product(payload)
  } else {
    projectStore.add_project_item_product(payload)
  }
}
</script>

<template>
  <div v-if="!projectItemData">Loading...</div>
  <CommonWorksheet
    v-else
    :solutionId="projectItemData.solutionId"
    :canEdit="false"
    :isImportantColumnsOnly="false"
    :title="projectItemData.title"
    :targetProductIds="productIds"
    @toggle-selected-product="toggleSelectedProduct"
  />
</template>
<style scoped></style>
