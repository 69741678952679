import type { ProjectItemDetail } from './project.type'
import type { User } from '@/types/user.type'

export type CaseItem = {
  id: number
  assigneeId?: number
  projectItemId: number
  caseItemStatus: CaseItemStatus
  caseItemType: CaseItemType
  internalMemo: string
  createdAt: Date
  updatedAt: Date
}

export type CaseItemDetail = CaseItem & {
  assigneeUser?: User
  messages: CaseItemMessageDetail[]
  projectItem: ProjectItemDetail
}

export type CaseItemMessage = {
  id: number
  caseItemId: number
  authorId: number
  text: string
  createdAt: Date
  updatedAt: Date
}

export type CaseItemMessageDetail = CaseItemMessage & {
  caseItemMessageAttachments: CaseItemMessageAttachment[]
  author: User
}

export type CaseItemMessageAttachment = {
  id: number
  uuid: string
  caseItemMessageId: number
  fileName: string
  fileExtension: string
}

export const CaseItemStatus = {
  new: 'new',
  working: 'working',
  closed: 'closed'
} as const
export type CaseItemStatus = keyof typeof CaseItemStatus

export const CaseItemType = {
  consult: 'consult',
  estimate: 'estimate'
} as const
export type CaseItemType = keyof typeof CaseItemType

export const CaseItemTypeLabel = {
  consult: '相談',
  estimate: '見積もり依頼'
} as const
export type CaseItemTypeLabel = keyof typeof CaseItemTypeLabel

export const CaseItemStatusQuery = {
  ...CaseItemStatus,
  open: 'open',
  all: 'all'
} as const
export type CaseItemStatusQuery = keyof typeof CaseItemStatusQuery

export const CaseItemTypeQuery = {
  ...CaseItemType,
  all: 'all'
} as const
export type CaseItemTypeQuery = keyof typeof CaseItemTypeQuery

export type CreateCaseItemPayload = {
  projectItemId: number
  caseItemType: CaseItemType
  text: string
}

export type GetCaseItemsQuery = {
  page: number
  limit: number
  onlyUnreplied: boolean
  caseItemStatus: CaseItemStatusQuery
  caseItemType: CaseItemTypeQuery
  assigneeId?: number
}

export type UpdateCaseItemPayload = {
  assigneeId?: number
  caseItemStatus?: CaseItemStatus
  internalMemo?: string
}

export type CreateCaseItemMessagePayload = {
  text: string
  notifyCaseItemAuthor: boolean
}
