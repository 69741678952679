import type { User } from './user.type'

export type UsefulMaterial = {
  id: number
  uploaderUserId: number
  uuid: string
  fileName: string
  fileExtension: string
  status: UsefulMaterialStatus
  createdAt: Date
  updatedAt: Date
}

export type UsefulMaterialDetail = UsefulMaterial & {
  uploaderUser: User
}

export const UsefulMaterialStatus = {
  draft: 'draft',
  live: 'live'
} as const
export type UsefulMaterialStatus = keyof typeof UsefulMaterialStatus

export const UsefulMaterialQueryStatus = {
  ...UsefulMaterialStatus,
  all: 'all'
} as const
export type UsefulMaterialQueryStatus = keyof typeof UsefulMaterialQueryStatus

// payload
export type GetUsefulMaterialsQuery = {
  uploaderUserId?: number
  status?: UsefulMaterialQueryStatus
}

export type UpdateUsefulMaterialPayload = {
  id: number
  fileName: string
  status: UsefulMaterialStatus
}

// UI label
export const UsefulMaterialStatusLabel = {
  draft: '下書き',
  live: '公開'
} as const
export type UsefulMaterialStatusLabel = keyof typeof UsefulMaterialStatusLabel
