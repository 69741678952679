<script setup lang="ts">
import { colorPalette as colors } from '../utils/enums'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import { TokenValidationActionType, ValidationTokenErrorMessage } from '../types/auth.type'
import { useAuthStore } from '../stores/authStore'
// props
const props = defineProps<{
  actionType: TokenValidationActionType
  token: string
}>()

// init
const router = useRouter()
const authStore = useAuthStore()
const isLoading = ref(true)
const errorMessage = ref('')
authStore
  .validateToken(props)
  .then(() => {
    if (props.actionType == TokenValidationActionType.forgetPassword) {
      // 即時redirect
      router.push(`/settings?menu=myAccount&edit=password`)
    }
  })
  .catch((err) => {
    // show message
    switch (err.response?.data?.message) {
      case ValidationTokenErrorMessage.expired:
        errorMessage.value = 'トークンの有効期限が切れています。'
        break
      case ValidationTokenErrorMessage.invalid:
        errorMessage.value = 'トークンが認証できませんでした。'
        break
      case ValidationTokenErrorMessage.email:
        errorMessage.value = 'メールアドレスの認証を先に行なってください。'
        break
      default:
        errorMessage.value = '不明なエラーが発生しました。もう一度お試しください。'
    }
  })
  .finally(() => {
    setTimeout(() => {
      isLoading.value = false
    }, 700)
  })

function goToHome() {
  router.push('/projects')
}
</script>

<template>
  <div id="container">
    <div
      id="loading"
      v-if="isLoading"
      v-loading="isLoading"
      element-loading-text="トークンを認証しています..."
      element-loading-background="transparent"
    >
      <div class="logo">DX plus</div>
    </div>
    <div v-else id="content">
      <div class="logo">DX plus</div>
      <div v-if="errorMessage.length" class="error-message">{{ errorMessage }}</div>
      <template v-else>
        <div class="success-message">メール認証に成功しました</div>
        <div class="action-wrapper">
          <el-button class="normal" @click="goToHome()">Homeへ</el-button>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped>
#container {
  height: 100%;
  overflow: hidden;
  background: v-bind('colors.bg.black');
  display: flex;
  justify-content: center;
  align-items: center;
}
a {
  text-decoration: none;
}
#loading {
  display: inline-block;
  width: 500px;
  height: 320px;
  box-sizing: border-box;
  padding: 60px 24px 32px;
}
.logo {
  text-align: center;
  color: v-bind('colors.text.white');
  font-size: 36px;
  font-weight: bold;
  line-height: 36px;
}
#loading :deep(.el-loading-spinner .path) {
  stroke: v-bind('colors.text.white');
}
#loading :deep(.el-loading-text) {
  color: v-bind('colors.text.white');
  margin-top: 16px;
}
#content {
  width: 500px;
  height: auto;
}
.error-message,
.success-message {
  color: v-bind('colors.text.lighter');
  text-align: center;
  margin-top: 16px;
  font-size: 14px;
  font-weight: bold;
}
.action-wrapper {
  margin-top: 32px;
  text-align: center;
}
</style>
