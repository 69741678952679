<script setup lang="ts">
import { useCsRequestStore } from '@/stores/csRequestStore'
import { CsRequestStatusQuery, type GetCsRequestQuery } from '@/types/csRequest.type'
import { colorPalette as colors } from '@/utils/enums'
import { computed, ref, toRefs } from 'vue'
import { datetimeFormatter } from '@/utils/date.formatter'
import { useRouter } from 'vue-router'
import { getHashedId } from '@/utils/hash.helper'

const emit = defineEmits<{
  (e: 'switchStatus', status: CsRequestStatusQuery): void
}>()
const props = withDefaults(
  defineProps<{
    csRequestId: number
  }>(),
  {
    csRequestId: -1
  }
)

const { csRequestId } = toRefs(props)
const router = useRouter()
const csRequestStore = useCsRequestStore()

const query = ref<GetCsRequestQuery>({ page: 1, limit: 50, status: CsRequestStatusQuery.open })
csRequestStore.get_cs_requests(query.value)
const csRequests = computed(() => {
  return csRequestStore.cs_requests
})

function openCsRequestDetail(id: number) {
  router.push(`/cases?csReqId=${getHashedId(id)}`)
}

const currentPage = computed({
  get: () => query.value.page,
  set: (val) => (query.value.page = val)
})
function changePage(v: number): void {
  query.value.page = v
  csRequestStore.get_cs_requests(query.value)
}
const totalPages = computed(() => {
  let num = 1
  if (csRequestStore.cs_requests_stats) {
    num = csRequestStore.cs_requests_stats.totalPages
  }
  return num
})

function switchStatus(status: CsRequestStatusQuery) {
  csRequestStore.clear_cs_requests()
  query.value.status = status
  csRequestStore.get_cs_requests(query.value)
  emit('switchStatus', status)
}
</script>

<template>
  <el-button-group class="switcher">
    <el-button
      v-for="(status, i) in Object.values(CsRequestStatusQuery)"
      :key="i"
      :class="query.status == status ? 'switch current' : 'switch'"
      @click="switchStatus(status)"
    >
      {{ CsRequestStatusQuery[status] }}
    </el-button>
  </el-button-group>
  <table class="ix-table">
    <tr
      class="ix-table-row"
      :class="{ active: row.id === csRequestId }"
      v-for="(row, i) in csRequests"
      :key="row.id"
      @click="openCsRequestDetail(row.id)"
    >
      <th class="th">#{{ i + 1 }}</th>
      <td class="ix-table-column" style="flex-grow: 1">{{ datetimeFormatter(row.createdAt) }}</td>
      <td class="ix-table-column" style="flex-grow: 1">{{ row.author.name }}</td>
      <td v-if="row.closedAt" class="ix-table-column end" style="flex-grow: 1">対応済</td>
      <td v-else class="ix-table-column end" style="flex-grow: 1">オープン</td>
    </tr>
  </table>
  <div class="pagination-footer">
    <el-pagination
      class="normal"
      :hide-on-single-page="true"
      :page-count="totalPages"
      :current-page="currentPage"
      layout="prev, pager, next"
      @current-change="changePage"
    />
  </div>
</template>
<style scoped>
.cs-request {
  cursor: pointer;
  font-weight: bold;
  color: v-bind('colors.text.base');
  padding: 5px 0;
  &:hover,
  &.active {
    color: v-bind('colors.text.white');
    background-color: v-bind('colors.service.darkBlue');
  }
}
.ix-table-row {
  cursor: pointer;
  font-weight: bold;
  gap: 20px;
  padding: 5px 0;
  color: v-bind('colors.text.base');
  &:hover,
  &.active {
    color: v-bind('colors.text.white');
    background-color: v-bind('colors.service.darkBlue');
  }
}
.switcher {
  margin-bottom: 20px;
}
.switch.current {
  color: v-bind('colors.text.white');
  background-color: v-bind('colors.bg.black');
}
</style>
