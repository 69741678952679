<script setup lang="ts">
import { computed, ref } from 'vue'
import { colorPalette as colors } from '@/utils/enums'
import { useCategoryStore } from '@/stores/categoryStore'
import type { TopCategoryDetail } from '@/types/topCategory.type'
import {
  TobeFileExtensionForDownload,
  type TobeImage,
  type UpdateTobeImagePayload
} from '@/types/tobeImage.type'
import type { UploadFile } from 'element-plus'
import { downloadFile, validateFile } from '@/utils/file.helper'
import { Check, Warning } from '@element-plus/icons-vue'
import { useSolutionStore } from '@/stores/solutionStore'
import { _ElConfirm, _ElMessage } from '@/utils/element-plus-wrapper'
import TobeItemContent from './TobeItemContent.vue'
import { useUserStore } from '@/stores/userStore'

const props = defineProps<{
  data: TopCategoryDetail
}>()

const categoryStore = useCategoryStore()
const solutionStore = useSolutionStore()
const userStore = useUserStore()
solutionStore.get_solutions({})

const isAdmin = computed(() => userStore.get_current_user.role === 'admin')

const isLoading = ref(false)
const topCategoryData = computed(() => props.data)
const isSubmitting = ref(false)

// TobeImage
const isTobeImageDialog = ref(false)
const dialogTobeImageData = ref<UpdateTobeImagePayload & { hasObjectives: boolean }>({
  id: -1,
  note: '',
  hasObjectives: false
})
const currentTobePptx = computed(() => {
  if (topCategoryData.value) {
    if (dialogTobeImageData.value.id === -1) {
      return null
    }
    const d = topCategoryData.value.tobeImages.find((x) => x.id === dialogTobeImageData.value.id)
    return d?.tobePptx ? d.tobePptx : null
  } else {
    return null
  }
})
const currentTobePng = computed(() => {
  if (topCategoryData.value) {
    if (dialogTobeImageData.value.id === -1) {
      return null
    }
    const d = topCategoryData.value.tobeImages.find((x) => x.id === dialogTobeImageData.value.id)
    if (d?.tobePng) {
      return d.tobePng
    } else {
      return null
    }
  } else {
    return null
  }
})
function createAndOpenUpdateTobeImageDialog() {
  categoryStore
    .create_tobe_image(topCategoryData.value.id)
    .then((r) => {
      openUpdateTobeImageDialog(r)
    })
    .catch(() => {})
}
function openUpdateTobeImageDialog(data: TobeImage) {
  dialogTobeImageData.value = {
    id: data.id,
    note: data.note,
    hasObjectives: data.objectives ? data.objectives.length > 0 : false
  }
  isTobeImageDialog.value = true
}
async function closeUpdateTobeImageDialog() {
  const targetId = dialogTobeImageData.value.id
  const shouldAutoDelete =
    !currentTobePng.value &&
    !currentTobePptx.value &&
    dialogTobeImageData.value.note === '' &&
    !dialogTobeImageData.value.hasObjectives

  // clear
  dialogTobeImageData.value = {
    id: -1,
    note: '',
    hasObjectives: false
  }

  // autoDelete
  if (targetId == -1) {
    // already deleted
    return
  }
  if (shouldAutoDelete) {
    await categoryStore.delete_tobe_image(targetId, false).catch(() => {})
  }
}
function submitUpdateTobeImage() {
  if (dialogTobeImageData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  isSubmitting.value = true
  categoryStore
    .update_tobe_image(dialogTobeImageData.value)
    .then(() => {
      isTobeImageDialog.value = false
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}
function confirmDeleteTobeImage() {
  if (dialogTobeImageData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  if (dialogTobeImageData.value.hasObjectives) {
    _ElMessage({
      type: 'error',
      message: 'このToBeイメージと結びついている主目的があるため削除できません'
    })
    return
  }
  _ElConfirm('この処理は取り消せません。実行しますか？', `ToBeイメージの削除`, {
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル'
  })
    .then(() => {
      categoryStore
        .delete_tobe_image(dialogTobeImageData.value.id)
        .then(() => {
          dialogTobeImageData.value.id = -1
          isTobeImageDialog.value = false
        })
        .catch(() => {})
    })
    .catch(() => {})
}

async function submitTobePptx(file: UploadFile) {
  if (dialogTobeImageData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  const rawFile = validateFile(file, Object.keys(TobeFileExtensionForDownload))
  if (!rawFile) {
    isLoading.value = false
    return
  }
  isLoading.value = true

  categoryStore
    .upload_tobe_image(dialogTobeImageData.value.id, rawFile)
    .catch(() => {})
    .finally(() => {
      isLoading.value = false
    })
}
async function submitTobePng(file: UploadFile) {
  if (dialogTobeImageData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  const rawFile = validateFile(file, ['png'])
  if (!rawFile) {
    isLoading.value = false
    return
  }
  isLoading.value = true

  categoryStore
    .upload_tobe_image(dialogTobeImageData.value.id, rawFile)
    .catch(() => {})
    .finally(() => {
      isLoading.value = false
    })
}
function getTobeImageUrl(tobeImageId: number, fileId: number) {
  return categoryStore.get_tobe_image_url(tobeImageId, fileId, 'png')
}
async function downloadCurrentTobePptx() {
  if (!currentTobePptx.value || dialogTobeImageData.value.id === -1) {
    return
  }
  try {
    const { data, filename } = await categoryStore.download_tobe_image(
      dialogTobeImageData.value.id,
      currentTobePptx.value.id,
      currentTobePptx.value.fileExtension
    )
    downloadFile(data, filename)
  } catch (error) {
    console.error('ファイルダウンロードエラー:', error)
  }
}
</script>
<template>
  <div class="tobe-images-content">
    <!-- dialog for TobeImage -->
    <template v-if="true">
      <el-dialog
        :close-on-press-escape="false"
        v-model="isTobeImageDialog"
        top="10vh"
        width="90%"
        class="ix-dialog"
        @close="closeUpdateTobeImageDialog"
      >
        <template #header>ToBeイメージの編集</template>
        <div class="tobe-image-dialog-content">
          <div class="tobe-image-dialog-img-wrapper">
            <div v-if="currentTobePng" class="tobe-image-enlarged">
              <img
                :src="getTobeImageUrl(currentTobePng.tobeImageId, currentTobePng.id)"
                crossOrigin="use-credentials"
              />
            </div>
            <div v-else class="tobe-image placeholder">PNG未登録</div>
          </div>
          <div class="tobe-image-dialog-form-wrapper">
            <el-form
              :model="dialogTobeImageData"
              label-position="top"
              label-width="120px"
              @submit.prevent
            >
              <el-form-item label="Web表示用PNG (推奨アスペクト比16:9)">
                <el-upload
                  class="upload"
                  v-loading.fullscreen="isLoading"
                  action=""
                  :auto-upload="false"
                  :show-file-list="false"
                  :on-change="submitTobePng"
                >
                  <template #default>
                    <el-button>{{
                      currentTobePng ? 'ファイル差し替え' : 'ファイル登録'
                    }}</el-button>
                  </template>
                </el-upload>
              </el-form-item>
              <el-form-item :label="`DL用ファイル（${Object.keys(TobeFileExtensionForDownload)}）`">
                <div
                  v-if="currentTobePptx"
                  class="tobe-pptx-label download"
                  @click="downloadCurrentTobePptx"
                >
                  {{ decodeURI(currentTobePptx.originalFileName) }}.{{
                    currentTobePptx.fileExtension
                  }}
                </div>
                <div>
                  <el-upload
                    class="upload"
                    v-loading.fullscreen="isLoading"
                    action=""
                    :auto-upload="false"
                    :show-file-list="false"
                    :on-change="submitTobePptx"
                  >
                    <template #default>
                      <el-button>{{
                        currentTobePptx ? 'ファイル差し替え' : 'ファイル登録'
                      }}</el-button>
                    </template>
                  </el-upload>
                </div>
              </el-form-item>
              <el-form-item label="備考">
                <el-input v-model="dialogTobeImageData.note" type="textarea" :rows="5" />
              </el-form-item>
            </el-form>
          </div>
        </div>
        <template #footer>
          <div class="el-dialog-footer_both_side">
            <span>
              <el-button link class="danger" @click="confirmDeleteTobeImage()">削除する</el-button>
            </span>
            <span>
              <el-button link @click="isTobeImageDialog = false">閉じる</el-button>
              <el-button :loading="isSubmitting" @click="submitUpdateTobeImage">保存する</el-button>
            </span>
          </div>
        </template>
      </el-dialog>
    </template>
    <div class="subheader">
      <div class="subheader-title">ToBeイメージ</div>
      <div v-if="isAdmin" class="subheader-action">
        <el-button @click="createAndOpenUpdateTobeImageDialog()">新規登録</el-button>
      </div>
    </div>
    <div class="tobe-images-wrapper">
      <div
        v-for="tobeImage in topCategoryData.tobeImages"
        :key="tobeImage.id"
        class="tobe-image-container"
      >
        <div
          v-if="tobeImage.tobePng"
          class="tobe-image"
          @click="isAdmin ? openUpdateTobeImageDialog(tobeImage) : undefined"
        >
          <img
            :src="getTobeImageUrl(tobeImage.id, tobeImage.tobePng.id)"
            crossOrigin="use-credentials"
          />
        </div>
        <div
          v-else
          class="tobe-image placeholder"
          @click="isAdmin ? openUpdateTobeImageDialog(tobeImage) : undefined"
        >
          PNG未登録
        </div>
        <div
          v-if="tobeImage.tobePptx"
          class="tobe-pptx-label"
          @click="isAdmin ? openUpdateTobeImageDialog(tobeImage) : undefined"
        >
          <Check style="width: 16px; height: 16px; margin-top: 2px" />
          DL用ファイル登録済み
        </div>
        <div
          v-else
          class="tobe-pptx-label danger"
          @click="isAdmin ? openUpdateTobeImageDialog(tobeImage) : undefined"
        >
          <Warning style="width: 16px; height: 16px; margin-top: 2px" />
          DL用ファイル未登録
        </div>
        <TobeItemContent :tobe-image="tobeImage" :top-category="topCategoryData" />
      </div>
    </div>
  </div>
</template>
<style scoped>
.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.subheader-title {
  font-size: 16px;
  font-weight: bold;
  height: 32px;
  line-height: 32px;
}
.ix-table-row {
  gap: 20px;
  padding-left: 16px;
}
.ix-table-column.th {
  min-width: 32px;
}
.ix-table-column.description {
  flex-grow: 1;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.row-placeholder {
  padding-left: 16px;
  font-size: 12px;
  color: v-bind('colors.text.disabled');
}
.el-tag + .el-tag {
  margin-left: 4px;
}
.tobe-images-wrapper {
  display: flex;
  gap: 24px;
  width: 100%;
  overflow-x: scroll;
  padding-bottom: 160px;
}
.tobe-image-container {
  flex-basis: 40%;
  min-width: 40%;
}
.tobe-image {
  width: 100%;
  min-height: 30vh;
  cursor: pointer;
  border: 1px solid v-bind('colors.border.base');
}
.tobe-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.tobe-image-enlarged {
  width: 100%;
  height: auto;
}
.tobe-image-enlarged img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tobe-image.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16 / 9;
  color: v-bind('colors.text.disabled');
  font-weight: bold;
  background-color: v-bind('colors.bg.gray05');
}
.tobe-pptx-label {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  color: v-bind('colors.text.base');
}
.tobe-pptx-label.danger {
  color: v-bind('colors.utility.red');
}
.tobe-pptx-label.download {
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
  margin-right: 10px;
}
.tobe-image-container .subheader {
  padding-left: 8px;
  padding-right: 8px;
}
/* tobeImage dialog */
.tobe-image-dialog-content {
  display: flex;
}
.tobe-image-dialog-img-wrapper {
  width: 70%;
}
.tobe-image-dialog-img-wrapper .tobe-image {
  cursor: inherit;
}
.tobe-image-dialog-form-wrapper {
  width: 30%;
  padding-left: 24px;
}
</style>
