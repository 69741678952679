import type { CaseItemMessage } from '@/types/caseItem.type'
import { datetimeFormatter } from '@/utils/date.formatter'

const getLatestCaseItemMessage = (messages: CaseItemMessage[]) => {
  if (!messages.length) {
    return null
  }
  const m = [...messages]
    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
    .reverse()[0]

  return {
    date: datetimeFormatter(m.createdAt),
    text: m.text
  }
}

export default getLatestCaseItemMessage
