import type { AsisImageDetail } from './asisImage.type'
import type { ObjectiveDetail } from './objective.type'
import type { TobeImageDetail } from './tobeImage.type'

export type TopCategory = {
  id: number
  name: string
  groupLabel: GroupLabel
  shouldSkipSelectObjective: boolean
  createdAt: Date
  updatedAt: Date
}

export type TopCategoryDetail = TopCategory & {
  asisImage: AsisImageDetail
  objectives: ObjectiveDetail[]
  tobeImages: TobeImageDetail[]
}

export const GroupLabel = {
  biz: 'biz',
  department: 'department'
} as const
export type GroupLabel = keyof typeof GroupLabel

// dto
export type CreateTopCategoryPayload = {
  name: string
  groupLabel: GroupLabel
  shouldSkipSelectObjective: boolean
}
export type UpdateTopCategoryPayload = {
  id: number
  name: string
  groupLabel: GroupLabel
  shouldSkipSelectObjective: boolean
}

// UI label
export const GroupLabelLabel = {
  biz: '業種',
  department: '部門'
} as const
export type GroupLabelLabel = keyof typeof GroupLabelLabel
