import { defineStore } from 'pinia'
import axios from 'axios'
import {
  type CreateAnnouncementPayload,
  type GetAnnouncementsQuery,
  type AnnouncementType,
  type UpdateAnnouncementPayload
} from '@/types/announcement.type'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import type { PaginationStats } from '@/types/paginationStats.type'

interface State {
  announcements: AnnouncementType[]
  announcement_stats: PaginationStats | null
}

export const useAnnouncementStore = defineStore('announcementStore', {
  state: (): State => ({
    announcements: [],
    announcement_stats: null
  }),
  getters: {},
  actions: {
    create_announcement(json: CreateAnnouncementPayload) {
      return new Promise<AnnouncementType>((resolve, reject) => {
        axios
          .post('/announcement', json)
          .then((res) => {
            _ElMessage({ type: 'success', message: 'お知らせを追加しました' })
            this.$patch((state) => {
              state.announcements.unshift(res.data)
            })
            resolve(res.data)
          })
          .catch((err) => {
            _ElMessage({ type: 'error', message: 'お知らせの追加に失敗しました' })
            console.log(err)
            reject(err)
          })
      })
    },
    get_announcements(json: GetAnnouncementsQuery) {
      axios
        .get('/announcement', { params: json })
        .then((res) => {
          this.$patch((state) => {
            state.announcements = res.data.items
            state.announcement_stats = res.data.meta
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    update_announcement(json: UpdateAnnouncementPayload) {
      const { id, ...payload } = json
      return new Promise<AnnouncementType>((resolve, reject) => {
        axios
          .put(`/announcement/${id}`, payload)
          .then((res) => {
            _ElMessage({ type: 'success', message: 'お知らせを更新しました' })
            this.$patch((state) => {
              const index = state.announcements.findIndex((a) => a.id === id)
              if (index !== -1) {
                state.announcements[index] = res.data
              }
            })
            resolve(res.data)
          })
          .catch((err) => {
            _ElMessage({ type: 'error', message: 'お知らせの更新に失敗しました' })
            console.log(err)
            reject(err)
          })
      })
    },
    delete_announcement(id: number) {
      return new Promise<void>((resolve, reject) => {
        axios
          .delete(`/announcement/${id}`)
          .then(() => {
            _ElMessage({ type: 'success', message: 'お知らせを削除しました' })
            this.$patch((state) => {
              const index = state.announcements.findIndex((a) => a.id === id)
              if (index !== -1) {
                state.announcements.splice(index, 1)
              }
            })
            resolve()
          })
          .catch((err) => {
            _ElMessage({ type: 'error', message: 'お知らせの削除に失敗しました' })
            console.log(err)
            reject(err)
          })
      })
    }
  }
})
