import type { PaginationStats } from '@/types/paginationStats.type'
import type {
  GetProjectsQuery,
  ProjectFillPayload,
  Project,
  ProjectDetail,
  ProjectItemDetail,
  ProjectOptionPayload,
  ProjectItemProductPayload,
  UpdateProjectPayload
} from '@/types/project.type'
import { defineStore } from 'pinia'
import axios, { type _AxiosError } from './api'
import { _ElMessage } from '@/utils/element-plus-wrapper'

interface State {
  current_project: ProjectDetail | null
  current_project_item: ProjectItemDetail | null
  projects: Project[]
  projects_stats: PaginationStats | null
}

export const useProjectStore = defineStore('projecctStore', {
  state: (): State => ({
    current_project: null,
    current_project_item: null,
    projects: [],
    projects_stats: null
  }),
  getters: {
    should_skip_objective(state) {
      return state.current_project?.topCategory?.shouldSkipSelectObjective || false
    },
    current_top_category(state) {
      return state.current_project?.topCategory || null
    },
    current_objective(state) {
      return state.current_project?.objective || null
    },
    current_tobe_item(state) {
      return state.current_project?.tobeItem || null
    },
    current_products(state) {
      return state.current_project_item?.products || []
    },
    current_solution_options(state) {
      return state.current_project_item?.solutionOptions || []
    }
  },
  actions: {
    clear_current_project() {
      this.current_project = null
    },
    clear_current_project_item() {
      this.current_project_item = null
    },
    clear_projects() {
      this.$patch((state) => {
        state.projects = []
        state.projects_stats = null
      })
    },
    get_projects(json: GetProjectsQuery) {
      axios
        .get(`/projects`, { params: json })
        .then((res) => {
          this.$patch((state) => {
            state.projects = res.data.items
            state.projects_stats = res.data.meta
          })
        })
        .catch((err: _AxiosError) => {
          console.log(err)
        })
    },
    get_project_detail(id: number, skipSave: boolean = false) {
      return new Promise<ProjectDetail>((resolve, reject) => {
        axios
          .get(`/projects/${id}`)
          .then((res) => {
            if (!skipSave) {
              this.current_project = res.data
            }
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    create_project(json: { title: string }) {
      return new Promise<ProjectDetail>((resolve, reject) => {
        axios
          .post(`/projects`, json)
          .then((res) => {
            this.$patch((state) => {
              state.current_project = res.data
              state.projects.unshift(res.data)
            })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    fill_project(json: ProjectFillPayload) {
      const { id, ...payload } = json
      return new Promise<ProjectDetail>((resolve, reject) => {
        axios
          .post(`/projects/${id}/fill`, payload)
          .then((res) => {
            this.current_project = res.data
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    confirm_project(id: number) {
      return new Promise<ProjectDetail>((resolve, reject) => {
        axios
          .post(`/projects/${id}/confirm`)
          .then((res) => {
            this.current_project = res.data
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    update_project(json: UpdateProjectPayload) {
      const { id, ...payload } = json
      return new Promise<ProjectDetail>((resolve, reject) => {
        axios
          .put(`/projects/${id}`, payload)
          .then((res) => {
            this.$patch((state) => {
              const index = state.projects.findIndex((p) => p.id === id)
              if (index >= 0) state.projects[index] = res.data
              state.current_project = res.data
            })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    get_project_item_detail(projectId: number, projectItemId: number) {
      axios
        .get(`/projects/${projectId}/projectItems/${projectItemId}`)
        .then((res) => {
          this.current_project_item = res.data
        })
        .catch((err: _AxiosError) => {
          console.log(err)
        })
    },
    add_solution_option(projectId: number, projectItemId: number, json: ProjectOptionPayload) {
      axios
        .post(`/projects/${projectId}/projectItems/${projectItemId}/addSolutionOption`, json)
        .then((res) => {
          this.current_project_item = res.data
          _ElMessage({ type: 'success', message: '追加しました' })
        })
        .catch((err: _AxiosError) => {
          console.log(err)
          _ElMessage({ type: 'error', message: '追加に失敗しました' })
        })
    },
    remove_solution_option(projectId: number, projectItemId: number, json: ProjectOptionPayload) {
      axios
        .post(`/projects/${projectId}/projectItems/${projectItemId}/removeSolutionOption`, json)
        .then((res) => {
          this.current_project_item = res.data
          _ElMessage({ type: 'success', message: '削除しました' })
        })
        .catch((err: _AxiosError) => {
          console.log(err)
          _ElMessage({ type: 'error', message: '削除に失敗しました' })
        })
    },
    add_project_item_product(json: ProjectItemProductPayload) {
      const { projectId, projectItemId, ...payload } = json
      axios
        .post(`/projects/${projectId}/projectItems/${projectItemId}/addProduct`, payload)
        .then((res) => {
          this.current_project_item = res.data
          _ElMessage({ type: 'success', message: '追加しました' })
        })
        .catch((err: _AxiosError) => {
          console.log(err)
          _ElMessage({ type: 'error', message: '追加に失敗しました' })
        })
    },
    remove_project_item_product(json: ProjectItemProductPayload) {
      const { projectId, projectItemId, ...payload } = json
      axios
        .post(`/projects/${projectId}/projectItems/${projectItemId}/removeProduct`, payload)
        .then((res) => {
          this.current_project_item = res.data
          _ElMessage({ type: 'success', message: '削除しました' })
        })
        .catch((err: _AxiosError) => {
          console.log(err)
          _ElMessage({ type: 'error', message: '削除に失敗しました' })
        })
    }
  }
})
