import axios from './api'
import { defineStore } from 'pinia'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import type {
  ProductDetail,
  Product,
  GetProductsQuery,
  AddSolutionPayload,
  UpdateProductPayload
} from '@/types/product.type'
import type { PaginationStats } from '@/types/paginationStats.type'
import { useWorksheetStore } from './worksheetStore'

interface State {
  current_product: ProductDetail | null
  products: ProductDetail[]
  products_stats: PaginationStats | null
}

export const useProductStore = defineStore('productStore', {
  state: (): State => ({
    current_product: null,
    products: [],
    products_stats: null
  }),
  getters: {},
  actions: {
    clear_current_product() {
      this.$patch((state) => {
        state.current_product = null
      })
    },
    clear_products() {
      this.$patch((state) => {
        state.products = []
        state.products_stats = null
      })
    },
    get_products(json: GetProductsQuery) {
      axios
        .get('/products', { params: json })
        .then((res) => {
          this.$patch((state) => {
            state.products = res.data.items
            state.products_stats = res.data.meta
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    get_product_detail(id: number, skipSave = false) {
      axios
        .get(`/products/${id}`)
        .then((res) => {
          if (!skipSave) {
            this.$patch((state) => {
              state.current_product = res.data
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    create_product(name: string) {
      return new Promise<Product>((resolve, reject) => {
        axios
          .post('/products', { name })
          .then((res) => {
            this.$patch((state) => {
              state.products.push(res.data)
            })
            _ElMessage({ type: 'success', message: '登録しました' })
            resolve(res.data)
          })
          .catch((err) => {
            _ElMessage({ type: 'error', message: '登録に失敗しました' })
            reject(err)
          })
      })
    },
    update_product(json: UpdateProductPayload) {
      return new Promise<void>((resolve, reject) => {
        axios
          .put(`/products/${json.id}`, { name: json.name })
          .then(() => {
            this.$patch((state) => {
              if (state.current_product?.id === json.id) {
                state.current_product.name = json.name
              }
              const index = state.products.findIndex((p) => p.id === json.id)
              if (index !== -1) {
                state.products[index].name = json.name
              }
            })
            _ElMessage({ type: 'success', message: '更新しました' })
            resolve()
          })
          .catch((err) => {
            _ElMessage({ type: 'error', message: '更新に失敗しました' })
            reject(err)
          })
      })
    },
    delete_product(id: number) {
      return new Promise<void>((resolve, reject) => {
        axios
          .delete(`/products/${id}`)
          .then(() => {
            this.$patch((state) => {
              state.products = state.products.filter((p) => p.id !== id)
              if (state.current_product?.id === id) {
                state.current_product = null
              }
            })
            _ElMessage({ type: 'success', message: '削除しました' })
            resolve()
          })
          .catch((err) => {
            _ElMessage({ type: 'error', message: '削除に失敗しました' })
            reject(err)
          })
      })
    },
    add_solution(json: AddSolutionPayload) {
      const { productId, ...dto } = json
      return new Promise<void>((resolve, reject) => {
        axios
          .post(`/products/${productId}/addSolution`, dto)
          .then((res: { data: ProductDetail }) => {
            _ElMessage({ type: 'success', message: '追加しました' })
            const workshetStore = useWorksheetStore()
            if (workshetStore.worksheet) {
              workshetStore.worksheet.products.push(res.data)
            }
            resolve()
          })
          .catch((err) => {
            _ElMessage({ type: 'error', message: '追加に失敗しました' })
            reject(err)
          })
      })
    },
    remove_solution(json: AddSolutionPayload) {
      const { productId, ...dto } = json
      axios
        .post(`/products/${productId}/removeSolution`, dto)
        .then((res: { data: ProductDetail }) => {
          _ElMessage({ type: 'success', message: '削除しました' })
          const workshetStore = useWorksheetStore()
          if (workshetStore.worksheet) {
            workshetStore.worksheet.products = workshetStore.worksheet.products.filter(
              (p) => p.id !== res.data.id
            )
          }
        })
        .catch((err) => {
          _ElMessage({ type: 'error', message: '削除に失敗しました' })
          console.log(err)
        })
    }
  }
})
